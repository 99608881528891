import { IPurchaseStateWithCustomerRelationship } from "@smartrr/shared/entities/PurchaseState";
import { IPurchaseStateLineItem } from "@smartrr/shared/entities/PurchaseState/CustomerPurchaseLineItem";
import { useEffect, useState } from "react";

import { updateInvalidVariantModal } from "@vendor-app/app/_state/actionCreators/subscriptionDetails";
import { useVendorPortalVariantToPurchasableMap } from "@vendor-app/app/_state/reducers/purchasables";
import { useSmartrrVendorDispatch } from "@vendor-app/app/_state/typedVendorReduxHooks";

export const useLineItemWithDeletedVariant = (
  customerPurchaseState: IPurchaseStateWithCustomerRelationship | undefined
) => {
  const [lineItemWithDeletedVariant, setLineItemWithDeletedVariant] = useState<IPurchaseStateLineItem | null>(
    null
  );
  const variantToPurchasableMap = useVendorPortalVariantToPurchasableMap();
  const dispatch = useSmartrrVendorDispatch();

  useEffect(() => {
    if (customerPurchaseState && customerPurchaseState.purchaseStateStatus === "ACTIVE") {
      //if variant is deleted open the modal
      if (customerPurchaseState.stLineItems.filter(li => !li.vnt).length) {
        setLineItemWithDeletedVariant(customerPurchaseState.stLineItems.find(item => !item.vnt) ?? null);

        dispatch(updateInvalidVariantModal(true));
        return;
      }
      //if product is deleted (cant find product in variantToPurchasableMap) open the modal
      if (customerPurchaseState.stLineItems.some(li => !variantToPurchasableMap[li.vnt.id])) {
        setLineItemWithDeletedVariant(
          customerPurchaseState.stLineItems.find(li => !variantToPurchasableMap[li.vnt.id]) ?? null
        );
        dispatch(updateInvalidVariantModal(true));
        return;
      }
      //if deleted product/variant was removed/replaced in the subscription
      if (lineItemWithDeletedVariant) {
        setLineItemWithDeletedVariant(null);
      }
    }
  }, [customerPurchaseState?.stLineItems, variantToPurchasableMap, lineItemWithDeletedVariant]);

  return {
    lineItemWithDeletedVariant,
  };
};
