import { Modal, Text } from "@shopify/polaris";
import { ISODateString } from "@smartrr/shared/entities/ISODateString";
import { IPurchaseStateWithCustomerRelationship } from "@smartrr/shared/entities/PurchaseState";
import styled from "styled-components";

import { useNextDelivery } from "@vendor-app/app/AdminRoute/AdminSubscriptionDetailsRoute/libs";
import { createSmartrrModal } from "@vendor-app/utils/createModalStore";
import { useCurrentOrganization } from "@vendor-app/utils/useCurrentOrganization";

export const useDateConfirmationModalStore = createSmartrrModal<{ sendNowDate: Date }>();

export interface DateConfirmationModalProps {
  onConfirm(date: Date): void;
  customerPurchaseState: IPurchaseStateWithCustomerRelationship;
}

const StyledModalSection = styled.div`
  text-align: center;

  .Polaris-Modal-Section section.Polaris-Box {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 40px 20px;
    row-gap: 7px;
  }
`;

export const DateConfirmationModal = ({ onConfirm, customerPurchaseState }: DateConfirmationModalProps) => {
  const { closeModal, isModalOpen, modalPayload } = useDateConfirmationModalStore();
  const customerFirstName = customerPurchaseState.custRel?.firstName ?? "Customer";
  const { nextDelivery } = useNextDelivery(customerPurchaseState);
  const activeOrg = useCurrentOrganization();

  const zonedNextDeliveryDate = ISODateString.fromString(nextDelivery?.date).setZone(activeOrg.billingTimezone);
  const zonedSendNowDate = ISODateString.fromString(modalPayload?.sendNowDate).setZone(activeOrg.billingTimezone);

  const formattedNextDeliveryDate = zonedNextDeliveryDate.toLocaleString({
    day: "2-digit",
    month: "short",
    year: "numeric",
  });

  const formattedSendNowDate = zonedSendNowDate.toLocaleString({
    day: "2-digit",
    month: "short",
    year: "numeric",
  });

  return (
    <Modal
      title={"Confirmation"}
      open={isModalOpen}
      onClose={closeModal}
      secondaryActions={[
        {
          id: "set-next-order__cancel-btn",
          content: "Yes, skip",
          onAction() {
            onConfirm(nextDelivery?.date!);
            closeModal();
          },
        },
        {
          id: "set-next-order__cancel-btn",
          content: "No, don't skip",
          onAction: closeModal,
        },
      ]}
    >
      <StyledModalSection>
        <Modal.Section>
          <Text as="h3" variant="headingMd" fontWeight="bold">
            ✅ Order date set to {formattedSendNowDate}
          </Text>
          <Text as="p" variant="headingSm" fontWeight="regular">
            {customerFirstName}&apos;s next subscription order is scheduled for {formattedNextDeliveryDate}. Would
            you like to skip this upcoming order?
          </Text>
        </Modal.Section>
      </StyledModalSection>
    </Modal>
  );
};
