import { Banner, LegacyCard, List, Text } from "@shopify/polaris";
import { RiskMajor } from "@shopify/polaris-icons";
import { IOrganization, OrgUtils } from "@smartrr/shared/entities/Organization";
import { IPurchaseStateWithCustomerRelationship } from "@smartrr/shared/entities/PurchaseState";
import { RRuleManager } from "@smartrr/shared/entities/Schedule/RRuleManager";
import { ISmartrrSellingPlan } from "@smartrr/shared/entities/SellingPlanGroup";
import { getUpcomingPricingCycle } from "@smartrr/shared/utils/getDiscounts";
import { useVariantToPurchasableMap } from "@smartrr/shared/utils/useVariantToPurchasableMap";
import { useSmartrrVendorSelector } from "@vendor-app/app/_state/typedVendorReduxHooks";
import { ShippingWarningMessage } from "@vendor-app/app/AdminRoute/AdminSubscriptionDetailsRoute/SubscriptionDetails/components/ShippingWarningMessage";
import { cloneDeep } from "lodash";
import React, { useMemo, useState } from "react";

import { NextOrderInfo } from "./NextOrderInfo";
import { SubscriptionItems } from "./SubscriptionItems";
import { SubscriptionTotal } from "./SubscriptionTotal";
import { BannerContainer, getFormattedItems } from "../../libs";
import type { SubscriptionDetailsLineItem } from "@smartrr/shared/interfaces/lineitem/api";
import { useNextDelivery } from "../../libs/hooks/useNextDelivery";
import { useSellingPlans } from "../../libs/providers/SellingPlansProvider";

interface Props {
  customerPurchaseState: IPurchaseStateWithCustomerRelationship;
  organization: IOrganization | null;
  failedBillingReasons: { error: string; id: string }[] | undefined;
  shippingCalculationWarning: string | undefined;
}

export const SubscriptionItemsAndTotal = ({
  customerPurchaseState,
  organization,
  failedBillingReasons,
  shippingCalculationWarning,
}: Props) => {
  const { purchaseStateStatus, schedule, stLineItems, sellingPlanId } = customerPurchaseState;

  const { purchasables } = useSmartrrVendorSelector(state => state.purchasables);
  const variantToPurchasableMap = useVariantToPurchasableMap(purchasables);

  const { nextDelivery } = useNextDelivery(customerPurchaseState);
  const skdIdx = useMemo(
    () =>
      nextDelivery?.indexFromScheduleStart ??
      getUpcomingPricingCycle({ ...customerPurchaseState, organization: organization! }),
    []
  );
  const orderNumber = useMemo(() => schedule.totalOrdersCount || skdIdx, [skdIdx, schedule.totalOrdersCount]);

  const { addOnsConfig, getSellingPlanGroup } = useSellingPlans();
  const sellingPlanGroup = getSellingPlanGroup(sellingPlanId);

  const [updatedLineItems, setUpdatedLineItems] = useState<SubscriptionDetailsLineItem[]>(
    cloneDeep(
      getFormattedItems(
        stLineItems,
        orderNumber,
        variantToPurchasableMap,
        schedule.paymentFrequencyMultiple,
        skdIdx
      )
    )
  );

  const areActionsDisabled = useMemo(
    () => purchaseStateStatus !== "ACTIVE" && purchaseStateStatus !== "PAUSED",
    [purchaseStateStatus]
  );

  const rruleManager = useMemo(
    () =>
      schedule && organization
        ? RRuleManager.fromJson(schedule, OrgUtils.getBillingSchedule(organization))
        : undefined,
    [organization, schedule]
  );

  const sellingPlan: ISmartrrSellingPlan | undefined = useMemo(
    () => sellingPlanGroup?.sellingPlans.find(sp => sp.shopifyId === customerPurchaseState.sellingPlanId)!,
    [sellingPlanGroup?.sellingPlans]
  );

  return (
    <React.Fragment>
      {failedBillingReasons?.length ? (
        <LegacyCard>
          <BannerContainer id="subscription-details__billing-error-banner">
            <Banner icon={RiskMajor} status="warning" title="Failed subscription">
              <Text variant="bodyMd" as="p">
                Billing can&apos;t resume until all errors have been resolved:
              </Text>
              <List spacing="loose">
                {failedBillingReasons
                  ?.filter(bill => bill.error)
                  .map(bill => {
                    return (
                      <List.Item key={bill.id}>
                        <Text variant="bodyMd" as="p" color="subdued">
                          {bill.error}
                        </Text>
                      </List.Item>
                    );
                  })}
              </List>
            </Banner>
          </BannerContainer>
        </LegacyCard>
      ) : null}
      {shippingCalculationWarning ? (
        <LegacyCard>
          <BannerContainer id="subscription-shipping-warning-banner">
            <Banner icon={RiskMajor} status="critical" title="Warning">
              <Text variant="bodyMd" as="p">
                <ShippingWarningMessage
                  type={shippingCalculationWarning}
                  shopifyDomain={organization?.myShopifyDomain}
                />
              </Text>
            </Banner>
          </BannerContainer>
        </LegacyCard>
      ) : (
        <React.Fragment />
      )}
      <LegacyCard>
        <NextOrderInfo
          sellingPlanGroup={sellingPlanGroup}
          customerPurchaseState={customerPurchaseState}
          areActionsDisabled={areActionsDisabled}
          rruleManager={rruleManager}
          organization={organization}
        />
        <SubscriptionItems
          sellingPlanGroup={sellingPlanGroup}
          addonsSellingPlan={addOnsConfig?.sellingPlans[0]}
          sellingPlan={sellingPlan}
          areActionsDisabled={areActionsDisabled}
          updatedLineItems={updatedLineItems}
          setUpdatedLineItems={setUpdatedLineItems}
          customerPurchaseState={customerPurchaseState}
          organization={organization}
        />
      </LegacyCard>
      <SubscriptionTotal
        rruleManager={rruleManager}
        areActionsDisabled={areActionsDisabled}
        updatedLineItems={updatedLineItems}
        customerPurchaseState={customerPurchaseState}
        nextDelivery={nextDelivery}
        sellingPlan={sellingPlan}
        addonsSellingPlan={addOnsConfig?.sellingPlans[0]}
        skdIdx={skdIdx}
        shippingCalculationWarning={shippingCalculationWarning}
      />
    </React.Fragment>
  );
};
