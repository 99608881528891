import { LegacyCard, LegacyStack, Tabs } from "@shopify/polaris";
import { IPurchaseStateWithCustomerRelationship } from "@smartrr/shared/entities/PurchaseState";
import { shopifyGidToNumber } from "@smartrr/shared/utils/ensureShopifyGid";
import { useActiveOrganizationSelector } from "@vendor-app/app/_state/reducers/organizations";
import React, { useState } from "react";
import styled from "styled-components";

import { useUnskip } from "./hooks/useUnskip";
import { OrdersAndEventDataTabsEnum, SubscriptionDetailsTabsEnum } from "../../../libs";
import { OrderHistory } from "../OrdersAndEventData/OrderHistory";
import { TransactionHistory } from "../OrdersAndEventData/TransactionHistory";
import { UpcomingOrders } from "../OrdersAndEventData/UpcomingOrders";
import { SubscriptionEvents } from "../SubscriptionEvents";
import { SubscriptionItemsAndTotal } from "../SubscriptionItemsAndTotal";

const MainTabsContainer = styled.div`
  margin-top: 20px;
`;

export interface SubscriptionTabsProps {
  customerPurchaseState: IPurchaseStateWithCustomerRelationship;

  failedBillingReasons: { error: string; id: string }[] | undefined;

  shippingCalculationWarning: string | undefined;
}

export const SubscriptionTabs = ({
  customerPurchaseState,
  failedBillingReasons,
  shippingCalculationWarning,
}: SubscriptionTabsProps) => {
  const organization = useActiveOrganizationSelector();

  const [selectedMainTab, setSelectedMainTab] = useState(SubscriptionDetailsTabsEnum.SUBSCRIPTION_DETAILS);
  const [selectedOrderAndEventsTab, setSelectedOrderAndEventsTab] = useState(
    OrdersAndEventDataTabsEnum.UPCOMING_ORDERS
  );

  const mainTabs = [
    {
      id: "details",
      content: "Subscription details",
      panelID: "details-content",
    },
    {
      id: "orders",
      content: "Orders and event data",
      panelID: "orders-content",
    },
  ];

  const orderAndEventsTabs = [
    {
      id: "upcoming",
      content: "Upcoming orders",
      panelID: "upcoming-content",
    },

    {
      id: "order-history",
      content: "Order history",
      panelID: "order-history-content",
    },
    {
      id: "transaction-history",
      content: "Transaction history",
      panelID: "transaction-history-content",
    },
  ];

  const customerCurrency = customerPurchaseState.currency;

  const onUnskip = useUnskip(customerPurchaseState);

  return (
    <Tabs fitted tabs={mainTabs} selected={selectedMainTab} onSelect={setSelectedMainTab}>
      <MainTabsContainer>
        {selectedMainTab === SubscriptionDetailsTabsEnum.SUBSCRIPTION_DETAILS ? (
          <SubscriptionItemsAndTotal
            customerPurchaseState={customerPurchaseState}
            organization={organization}
            failedBillingReasons={failedBillingReasons}
            shippingCalculationWarning={shippingCalculationWarning}
          />
        ) : null}
        {selectedMainTab === SubscriptionDetailsTabsEnum.ORDERS_AND_EVENT_HISTORY ? (
          <LegacyStack vertical>
            <LegacyCard>
              <Tabs
                fitted
                tabs={orderAndEventsTabs}
                selected={selectedOrderAndEventsTab}
                onSelect={setSelectedOrderAndEventsTab}
              >
                {selectedOrderAndEventsTab === OrdersAndEventDataTabsEnum.UPCOMING_ORDERS ? (
                  <UpcomingOrders
                    purchaseState={customerPurchaseState}
                    activeOrg={organization}
                    onUnskip={onUnskip}
                  />
                ) : null}
                {selectedOrderAndEventsTab === OrdersAndEventDataTabsEnum.ORDER_HISTORY ? (
                  <OrderHistory
                    subscriptionId={String(shopifyGidToNumber(customerPurchaseState.shopifyId!))}
                    activeOrg={organization}
                    customerCurrency={customerCurrency}
                  />
                ) : null}
                {selectedOrderAndEventsTab === OrdersAndEventDataTabsEnum.TRANSACTION_HISTORY ? (
                  <TransactionHistory
                    activeOrg={organization}
                    subscriptionId={customerPurchaseState.id}
                    subscriptionShopifyId={customerPurchaseState.shopifyId!}
                    customerCurrency={customerCurrency}
                  />
                ) : null}
              </Tabs>
            </LegacyCard>
            <SubscriptionEvents subscriptionId={String(shopifyGidToNumber(customerPurchaseState.shopifyId!))} />
          </LegacyStack>
        ) : null}
      </MainTabsContainer>
    </Tabs>
  );
};
