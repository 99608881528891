import { IPurchaseStateWithCustomerRelationship } from "@smartrr/shared/entities/PurchaseState";
import React from "react";
import { useFilteredAndSortedUpcomingOrders } from "./hooks/useFilteredAndSortedUpcomingOrders";
import { UpcomingOrdersTable } from "./components/Table";
import { UpcomingOrderTableActionsContext } from "./UpcomingOrderTableActionsContext";

interface UpcomingOrderProps {
  purchaseState: IPurchaseStateWithCustomerRelationship;
  onUnskip: (date: Date) => void;
}

export const UpcomingOrdersRework = ({ purchaseState, onUnskip }: UpcomingOrderProps) => {
  useFilteredAndSortedUpcomingOrders(purchaseState);

  return (
    <UpcomingOrderTableActionsContext.Provider
      value={{
        unskip: onUnskip,
      }}
    >
      <UpcomingOrdersTable />
    </UpcomingOrderTableActionsContext.Provider>
  );
};
