import { NO_OP_CALLBACK } from "@smartrr/shared/constants";
import React from "react";

interface UpcomingOrderTableActions {
  unskip: (date: Date) => void;
}

export const UpcomingOrderTableActionsContext = React.createContext<UpcomingOrderTableActions>({
  unskip: NO_OP_CALLBACK,
});
