import { IPurchaseStateWithCustomerRelationship } from "@smartrr/shared/entities/PurchaseState";
import { useGeneratedUpcomingOrders } from "../useGeneratedUpcomingOrders";
import { UpcomingOrdersTableAccess } from "../../store";
import { useEffect } from "react";

export const useFilteredAndSortedUpcomingOrders = (cps: IPurchaseStateWithCustomerRelationship) => {
  const upcomingOrders = useGeneratedUpcomingOrders(cps);
  const actions = UpcomingOrdersTableAccess.useActions();

  useEffect(() => {
    /**
     * @todo Sort and filter data
     */
    const filteredAndSortedUpcomingOrders = upcomingOrders;

    actions.data.update(async () => {
      return {
        data: filteredAndSortedUpcomingOrders.map((order, index) => {
          return {
            ...order,
            id: String(order.indexFromNext),
          };
        }),
        pagination: {
          maxPages: 1,
          pageNumber: 1,
        },
      };
    });
  }, [cps, upcomingOrders, actions]);
};
