import { FeatureEnum } from "@smartrr/shared/entities/AccountPlan";
import { createLazyFileRoute } from "@tanstack/react-router";

import { ErrorBoundary } from "@vendor-app/app/_sharedComponents/ErrorBoundary";
import { AdminSubscriptionDetailsRoute } from "@vendor-app/app/AdminRoute/AdminSubscriptionDetailsRoute";
import { AuthorizedRoute } from "@vendor-app/app/AdminRoute/components/authorization/AuthorizedRoute";

export const Route = createLazyFileRoute("/admin/subscriptions/$subscriptionId")({
  component: Index,
  errorComponent: ({ error, info }) => <ErrorBoundary error={error} info={info} />,
});

function Index() {
  const { subscriptionId } = Route.useParams();

  return AuthorizedRoute(
    FeatureEnum.CORE_SUBSCRIPTIONS,
    <AdminSubscriptionDetailsRoute subscriptionId={subscriptionId} />
  );
}
