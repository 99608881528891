import React, { CSSProperties, ReactNode, useMemo } from "react";

const codeStyleObj = {
  display: "inline",
  backgroundColor: "#eee",
  boxShadow: "0px 1px 1px rgba(0,0,0,.3)",
  borderRadius: 4,
  padding: "0 4px 1px",
};

interface CodeTextProps {
  className?: string;
  style?: CSSProperties;
  children: ReactNode;
}

export function CodeText(props: CodeTextProps): JSX.Element {
  const finalStyle = useMemo(() => ({ ...codeStyleObj, ...props.style }), [props.style]);

  return (
    <pre style={finalStyle} className={props.className}>
      <code>{props.children}</code>
    </pre>
  );
}
