import { ISubscriptionEventWithPurchaseStateInformation } from "@smartrr/shared/entities/SubscriptionEvent";
import { captureException } from "@smartrr/shared/utils/captureException";
import React, { useCallback, useEffect, useState } from "react";

import { Events } from "@vendor-app/app/_sharedComponents/Events";
import { useToast } from "@vendor-app/app/_sharedComponents/Toast/ToastProvider";
import {
  deleteSubscriptionEventsComment,
  getSubscriptionEvents,
  putSubscriptionEventsAddComment,
  putSubscriptionEventsPinComment,
} from "@vendor-app/app/_state/actionCreators/customerPurchaseState";

interface SubscriptionEventsProps {
  subscriptionId: string;
}

export const SubscriptionEvents = ({ subscriptionId }: SubscriptionEventsProps) => {
  const [subscriptionEventsLoading, setSubscriptionEventsLoading] = useState(true);
  const [subscriptionEvents, setSubscriptionEvents] = useState<ISubscriptionEventWithPurchaseStateInformation[]>(
    []
  );

  const { addToast } = useToast();

  useEffect(() => {
    fetchSubscriptionEvents();
  }, [subscriptionId]);

  const fetchSubscriptionEvents = async () => {
    setSubscriptionEventsLoading(true);
    try {
      const res = await getSubscriptionEvents(subscriptionId);
      if (res.type === "success") {
        setSubscriptionEvents(res.body);
      } else {
        addToast("Couldn't get event history for subscription");
      }
    } catch (error) {
      captureException("Couldn't get event history for subscription", error);
      addToast("Couldn't get event history for subscription");
    }

    setSubscriptionEventsLoading(false);
  };

  const addSubscriptionEventsComment = useCallback(
    async (text: string, authorName: string) => {
      setSubscriptionEventsLoading(true);
      try {
        const res = await putSubscriptionEventsAddComment(subscriptionId, text, authorName);
        if (res.type === "success") {
          setSubscriptionEvents(res.body);
          addToast("Comment was added");
        } else {
          addToast("Couldn't add comment to event history for subscription");
        }
      } catch (error) {
        captureException("Couldn't add comment to event history for subscription", error);
        addToast("Couldn't add comment to event history for subscription");
      }
      setSubscriptionEventsLoading(false);
    },
    [subscriptionId]
  );

  const pinSubscriptionEventsComment = useCallback(
    async (eventId: string) => {
      setSubscriptionEventsLoading(true);
      try {
        const res = await putSubscriptionEventsPinComment(subscriptionId, eventId);
        if (res.type === "success") {
          addToast("Comment was pinned");
          setSubscriptionEvents(res.body);
        } else {
          addToast("Couldn't pin comment to event history for subscription");
        }
      } catch (error) {
        captureException("Couldn't pin comment to event history for subscription", error);
        addToast("Couldn't pin comment to event history for subscription");
      }
      setSubscriptionEventsLoading(false);
    },
    [subscriptionId]
  );

  const removeSubscriptionEventsComment = useCallback(
    async (eventId: string) => {
      setSubscriptionEventsLoading(true);
      try {
        const res = await deleteSubscriptionEventsComment(subscriptionId, eventId);
        if (res.type === "success") {
          setSubscriptionEvents(res.body);
          addToast("Comment was removed");
        } else {
          addToast("Couldn't add comment to event history for subscription");
        }
      } catch (error) {
        captureException("Couldn't add comment to event history for subscription", error);
        addToast("Couldn't add comment to event history for subscription");
      }

      setSubscriptionEventsLoading(false);
    },
    [subscriptionId]
  );

  return (
    <Events
      loading={subscriptionEventsLoading}
      subscriptionEvents={subscriptionEvents}
      addComment={addSubscriptionEventsComment}
      pinComment={pinSubscriptionEventsComment}
      removeComment={removeSubscriptionEventsComment}
    />
  );
};
