import { BundleLineItemTypes } from "@smartrr/shared/utils/bundles";
import { validateDataWithZodSchema } from "@smartrr/shared/utils/validateDataWithZodSchema";

import { z } from "zod";

import { LineItemActionValues, SubscriptionDetailsLineItem } from "./api";

const CustomLineItemActionSchema = z.enum(LineItemActionValues);

// Inferred from SubscriptionDetailsLineItem
export namespace LineItemValidation {
  export namespace SDLineItem {
    const schema = z.object({
      action: CustomLineItemActionSchema,
      bundleInfo: z.object({
        id: z.string().nullable().optional(),
        type: z.nativeEnum(BundleLineItemTypes).nullable().optional(),
        lines: z
          .array(
            z.object({
              id: z.string(),
              quantity: z.number(),
            })
          )
          .nullable()
          .optional(),
        custom: z
          .array(
            z.object({
              key: z.string(),
              value: z.string().nullable(),
            })
          )
          .nullable()
          .optional(),
      }),
      finalPrice: z.string(),
      id: z.string(),
      isAddOn: z.boolean(),
      isRedeemed: z.boolean(),
      productName: z.string(),
      quantity: z.number({
        message: "Quantity must be a number",
      }),
      shopifyId: z.string(),
      skdIdx: z.number().nullable().optional(),
      variantName: z.string(),
      vnt: z.record(z.string(), z.any()).nullable(), // TODO: Replace with VariantApi.VariantSchema.schema once I figure out why there's a discrepancy between key names
    });
    export const validateLineItemUpdatesWithZod = (updatedLineItems: SubscriptionDetailsLineItem[]) => {
      return validateDataWithZodSchema(
        z
          .array(
            schema.refine(
              input => (input.quantity >= 1 && updatedLineItems.length > 1) || updatedLineItems.length === 1,
              {
                message: "Quantity must be greater than 0",
              }
            )
          )
          .min(0, {
            message: "Please select at least one item",
          }),
        updatedLineItems
      );
    };
  }
}
