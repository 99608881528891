import { IPurchaseStateWithCustomerRelationship } from "@smartrr/shared/entities/PurchaseState";
import { useMemo } from "react";
import { UpcomingOrdersTableAccess } from "../../store";
import { getCPSRemainingOrders, isCPSPrepaid } from "@smartrr/shared/utils/isPrepaid";
import { SubscriptionContractSubscriptionStatus } from "@smartrr/shared/shopifyGraphQL/api";
import { isNil } from "lodash";

export const useNumberOfUpcomingOrdersToGenerate = (cps: IPurchaseStateWithCustomerRelationship): number => {
  const filter = UpcomingOrdersTableAccess.useSelectedFilter("numberOfFutureOrders");

  return useMemo(() => {
    const defaultValue = Number(filter?.value[0] ?? 0);

    /**
     * Regular subscriptions
     */
    if (!isCPSPrepaid(cps.schedule)) {
      return defaultValue;
    }

    /**
     * Cancelled prepaid
     */
    const activeOrPaused =
      cps.purchaseStateStatus === SubscriptionContractSubscriptionStatus.Active ||
      cps.purchaseStateStatus === SubscriptionContractSubscriptionStatus.Paused;

    if (!activeOrPaused) {
      return getCPSRemainingOrders(cps);
    }

    /**
     * Non-terminating active prepaid
     */
    if (isNil(cps.schedule.maxCycles)) {
      return defaultValue;
    }

    /**
     * Terminating active prepaid
     */
    const maxCycles = cps.schedule.maxCycles;
    const paymentFrequencyMultiple = cps.schedule.paymentFrequencyMultiple;
    const totalOrdersCount = cps.schedule.totalOrdersCount ?? 0;

    return Math.min(defaultValue, maxCycles * paymentFrequencyMultiple - totalOrdersCount);
  }, [cps, filter]);
};
