import { Banner, Modal } from "@shopify/polaris";
import React from "react";

import { updateRemoveAllProductsWarningModal } from "@vendor-app/app/_state/actionCreators/subscriptionDetails";
import { useSmartrrVendorDispatch, useSmartrrVendorSelector } from "@vendor-app/app/_state/typedVendorReduxHooks";

export const RemoveAllProductsModal = ({
  onCancel,
  onConfirm,
}: {
  onCancel: () => void;
  onConfirm: () => void;
}) => {
  const dispatch = useSmartrrVendorDispatch();
  const closeModal = () => dispatch(updateRemoveAllProductsWarningModal(false));
  const showRemoveAllProductsWarning = useSmartrrVendorSelector(
    state => state.subscriptionDetails.showRemoveAllProductsWarning
  );

  return (
    <Modal
      sectioned
      title="Warning"
      open={showRemoveAllProductsWarning}
      onClose={closeModal}
      primaryAction={{
        id: "subscription-details__cancel-remove-all-products-btn",
        content: "Go back",
        onAction() {
          onCancel();
          closeModal();
        },
      }}
      secondaryActions={[
        {
          id: "subscription-details__confirm-remove-all-products-btn",
          content: "Cancel subscription",
          destructive: true,
          onAction() {
            onConfirm();
            closeModal();
          },
        },
      ]}
    >
      <Banner status="critical">
        Removing all items will cancel the entire subscription. Are you sure you wish to continue?
      </Banner>
    </Modal>
  );
};
