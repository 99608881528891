import { Modal } from "@shopify/polaris";
import { CodeText } from "@smartrr/shared/components/CodeText";
import { IPurchaseStateDiscount } from "@smartrr/shared/interfaces/Discount";
import React, { useCallback, useState } from "react";

import { updateRemoveSubscriptionDiscountModal } from "@vendor-app/app/_state/actionCreators/subscriptionDetails";
import { useSmartrrVendorDispatch, useSmartrrVendorSelector } from "@vendor-app/app/_state/typedVendorReduxHooks";

interface Props {
  discount: IPurchaseStateDiscount;
  onRemove(discount: IPurchaseStateDiscount): Promise<void>;
}

export function RemoveSubscriptionDiscountModal({ onRemove, discount }: Props): JSX.Element {
  const showRemoveSubscriptionDiscount = useSmartrrVendorSelector(
    state => state.subscriptionDetails.showRemoveSubscriptionDiscount
  );
  const dispatch = useSmartrrVendorDispatch();
  const closeRemoveSubscriptionDiscountModal = () => dispatch(updateRemoveSubscriptionDiscountModal(false));

  const [isRemoving, setIsRemoving] = useState(false);

  const onRemoveClick = useCallback(async () => {
    setIsRemoving(true);
    await onRemove(discount);
    setIsRemoving(false);
    closeRemoveSubscriptionDiscountModal();
  }, [onRemove, closeRemoveSubscriptionDiscountModal]);

  return (
    <Modal
      onClose={closeRemoveSubscriptionDiscountModal}
      open={showRemoveSubscriptionDiscount}
      title="Confirm discount removal"
      primaryAction={{
        id: "remove-subscription-discount__confirm-btn",
        content: "Remove",
        destructive: true,
        loading: isRemoving,
        onAction: onRemoveClick,
      }}
      secondaryActions={[
        {
          id: "remove-subscription-discount__cancel-btn",
          content: "Cancel",
          onAction: closeRemoveSubscriptionDiscountModal,
        },
      ]}
    >
      <Modal.Section>
        Remove discount code: <CodeText className="remove-subscription-discount__code">{discount.code}</CodeText>?
      </Modal.Section>
    </Modal>
  );
}
