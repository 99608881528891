import { IPurchasable } from "@smartrr/shared/entities/Purchasable";
import { IPurchaseStateLineItem } from "@smartrr/shared/entities/PurchaseState/CustomerPurchaseLineItem";
import { convertNumberForFormatMoney } from "@smartrr/shared/utils/formatMoney";
import { selectPricingPolicyComputedPriceForCycle } from "@smartrr/shared/utils/getDiscounts";

import type { SubscriptionDetailsLineItem } from "@smartrr/shared/interfaces/lineitem/api";

export const getFormattedItems = (
  stLineItems: IPurchaseStateLineItem[],
  orderNumber: number,
  variantToPurchasableMap: {
    [id: string]: IPurchasable;
  },
  numberOfOrders: number,
  subscriptionSkdIdx?: number
): SubscriptionDetailsLineItem[] => {
  return [...stLineItems]
    .filter(li => !li.deletedAt)
    .filter(i => i.skdIdx == null || i.skdIdx === subscriptionSkdIdx)
    .map((lineItem): SubscriptionDetailsLineItem => {
      const {
        isAddOn,
        basePrice,
        shopifyId,
        isRedeemed,
        id,
        pricingPolicy,
        priceCurrency,
        quantity,
        vnt,
        skdIdx,
      } = lineItem;
      const purchasable = vnt?.id ? variantToPurchasableMap[vnt?.id] : undefined;

      return {
        id: `${id}${isAddOn ? "-addon" : ""}`,
        shopifyId,
        quantity,
        isAddOn,
        isRedeemed,
        skdIdx,
        vnt,
        action: "read",
        productName: purchasable?.purchasableName || "Removed product",
        variantName: vnt?.purchasableVariantName ?? "Removed variant",
        finalPrice: String(
          convertNumberForFormatMoney(
            pricingPolicy
              ? selectPricingPolicyComputedPriceForCycle(pricingPolicy, orderNumber) / numberOfOrders
              : basePrice,
            priceCurrency
          ).toFixed(2)
        ),
        bundleInfo: {
          id: lineItem.bundleId,
          type: lineItem.bundleType,
          lines: lineItem.bundleLines,
          custom: lineItem.customAttributes,
        },
      };
    });
};
