import { ConfirmationWindow } from "@vendor-app/app/_sharedComponents/ConfirmationWindow";
import { UpcomingOrdersTableAccess } from "../../store";
import { useUpcomingOrderSkipModal } from "./store";
import { useCallback, useContext } from "react";
import { bulkSkipCustomerPurchaseState } from "@vendor-app/app/_state/actionCreators/customerPurchaseState";
import { useSmartrrVendorDispatch } from "@vendor-app/app/_state/typedVendorReduxHooks";
import { UpcomingOrdersBulkActionCompletedContext } from "../Table/UpcomingOrdersBulkActionCompletedContext";

export const UpcomingOrderSkipModal = () => {
  const selectedResources = UpcomingOrdersTableAccess.useSelectedData();
  const { isModalOpen, closeModal } = useUpcomingOrderSkipModal();
  const dispatch = useSmartrrVendorDispatch();
  const bulkActionContext = useContext(UpcomingOrdersBulkActionCompletedContext);

  const confirmationCallback = useCallback(async () => {
    if (selectedResources.length === 0) {
      return;
    }

    const dates = selectedResources.map(order => order.date);

    const purchaseState = selectedResources[0].purchaseState;
    if (purchaseState.id && purchaseState.custRel) {
      await dispatch(
        bulkSkipCustomerPurchaseState({
          customerPurchaseStateId: purchaseState.id,
          customerRelationshipId: purchaseState.custRel.id,
          dates,
        })
      );
    }
    bulkActionContext.onCompleted();
    closeModal();
  }, [selectedResources, dispatch, closeModal]);

  if (selectedResources.length === 0) {
    return null;
  }

  return (
    <ConfirmationWindow
      title={`Are you sure you want to skip ${selectedResources.length} date(s)?`}
      onReject={closeModal}
      onConfirm={confirmationCallback}
      confirmationText="Yes"
      rejectionText="No"
      open={isModalOpen}
    >
      It might take a few minutes to process this request.
    </ConfirmationWindow>
  );
};
