import { Layout } from "@shopify/polaris";
import { IMailingAddressJson } from "@smartrr/shared/entities/MailingAddress";
import { IPurchaseStateWithCustomerRelationship } from "@smartrr/shared/entities/PurchaseState";
import { IPurchaseStateDiscount } from "@smartrr/shared/interfaces/Discount";
import { CountryCode } from "@smartrr/shared/shopifyGraphQL/api";
import { captureException } from "@smartrr/shared/utils/captureException";
import { getCountryByCode, getProvinceByCode } from "@smartrr/shared/utils/country";
import { parseSubscriptionShippingErrorForMerchant } from "@smartrr/shared/utils/parseSubscriptionShippingError";
import {
  getNumberWithCountryCode,
  phoneCountryCode,
  validatePhoneNumber,
} from "@smartrr/shared/utils/phoneValidation";
import React, { useCallback, useState } from "react";
import styled from "styled-components";

import { useToast } from "@vendor-app/app/_sharedComponents/Toast/ToastProvider";
import {
  addCustomerPurchaseStateDiscount,
  addNotePurchaseState,
  deleteCustomerPurchaseStateDiscount,
  editCPSShippingAddress,
} from "@vendor-app/app/_state/actionCreators/customerPurchaseState";
import { useActiveOrganizationSelector } from "@vendor-app/app/_state/reducers/organizations";
import { useSmartrrVendorDispatch } from "@vendor-app/app/_state/typedVendorReduxHooks";
import { QuickJumpWrapper } from "@vendor-app/app/AdminRoute/AdminSubscriberEngagementRoute/EmailSettings/components/libs/styles";

import { CustomerInfoAndDiscounts } from "../CustomerInfoAndDiscounts";
import { useEditAddressModalErrorStore } from "@vendor-app/app/_sharedComponents/CustomerInformationCard/hooks/useEditAddressModalErrorStore";

const QuickJumpContainer = styled(QuickJumpWrapper)`
  .Polaris-LegacyCard {
    max-height: 800px;
    max-width: 310px;
  }
`;

interface IShowCustomerInfo {
  customerPurchaseState: IPurchaseStateWithCustomerRelationship | undefined;
}

export const ShowCustomerInfo = ({ customerPurchaseState }: IShowCustomerInfo) => {
  const dispatch = useSmartrrVendorDispatch();
  const organization = useActiveOrganizationSelector();
  const { addToast } = useToast();
  const [isApplyingDiscountCode, setIsApplyingDiscountCode] = useState(false);
  const { openModal } = useEditAddressModalErrorStore();

  const onApplyDiscountCode = useCallback(
    async (code: string) => {
      if (!(customerPurchaseState && customerPurchaseState.custRel)) {
        addToast(
          `Error applying discount code: ${customerPurchaseState ? "customer null" : "subscription null"}`
        );
        return;
      }

      setIsApplyingDiscountCode(true);
      await dispatch(
        addCustomerPurchaseStateDiscount({
          customerPurchaseStateId: customerPurchaseState.id,
          customerRelationshipId: customerPurchaseState.custRel.id,
          discountCode: code,
        })
      )
        .then(res => {
          if (res.type === "ADDED_CPS_DISCOUNT") {
            addToast(`Discount code ${code} applied`);
          }
          if (res.type === "ERROR_ADDING_CPS_DISCOUNT") {
            addToast("Enter a valid discount code", true);
          }
        })
        .catch(error => {
          addToast("Enter a valid discount code");
          captureException("Enter a valid discount code", error);
        });
      setIsApplyingDiscountCode(false);
    },
    [customerPurchaseState]
  );

  const onRemoveDiscount = useCallback(
    async (discount: IPurchaseStateDiscount) => {
      if (!(customerPurchaseState && customerPurchaseState.custRel && discount.vendorId)) {
        return;
      }
      await dispatch(
        deleteCustomerPurchaseStateDiscount({
          customerRelationshipId: customerPurchaseState.custRel.id,
          customerPurchaseStateId: customerPurchaseState.id,
          discountVendorId: discount.vendorId,
        })
      )
        .then(res => {
          if (res.type === "DELETED_CPS_DISCOUNT") {
            addToast(`Discount ${discount.code ?? ""} removed`);
          }
          if (res.type === "ERROR_DELETING_CPS_DISCOUNT") {
            addToast("Error removing discount");
          }
        })
        .catch(error => {
          addToast("Error removing discount");
          captureException("Error removing discount", error);
        });
    },
    [customerPurchaseState]
  );

  const onEditNote = useCallback(
    async (noteText?: string) => {
      if (!customerPurchaseState) {
        addToast("Error updating note: subscription null");
        return;
      }

      await dispatch(
        addNotePurchaseState({
          customerPurchaseStateId: customerPurchaseState.id,
          noteText,
        })
      )
        .then(res => {
          if (res.type === "ADDED_NOTE_PURCHASE_STATE") {
            addToast("Note updated");
            return;
          }
          if (res.type === "ERROR_ADDING_NOTE_PURCHASE_STATE") {
            addToast("Error updating note");
          }
        })
        .catch(error => {
          addToast("Error updating note");
          captureException("Error updating note", error);
        });
    },
    [customerPurchaseState]
  );

  const onUpdateShippingAddress = useCallback(
    async (values: IMailingAddressJson | undefined) => {
      if (!organization?.id || !customerPurchaseState?.custRel || !values) {
        return;
      }

      if (values.phone && values.countryCode) {
        const isPhoneNumberValid = validatePhoneNumber(
          values.phone.replaceAll("+", ""),
          values.phoneCountryCode as phoneCountryCode
        );
        if (!isPhoneNumberValid) {
          addToast("Invalid Phone number");
          return;
        }
      }

      const updatedPhoneNumber = getNumberWithCountryCode(
        values.phone!,
        values.phoneCountryCode as phoneCountryCode
      );

      const formattedValues = {
        ...values,
        country: values.country ? getCountryByCode(values.countryCode as CountryCode)?.country : undefined,
        countryCode: values.countryCode as CountryCode | undefined,
        province: values.provinceCode
          ? getProvinceByCode(values.countryCode as CountryCode, values.provinceCode)?.province
          : undefined,
        provinceCode: values.provinceCode,
        phone: updatedPhoneNumber || "",
      };

      dispatch(
        editCPSShippingAddress({
          customerRelationshipId: customerPurchaseState.custRel.id,
          customerPurchaseStateId: customerPurchaseState.id,
          formattedValues,
        })
      )
        .then(res => {
          if (res.type === "ERROR_EDITING_CPS_SHIPPING_ADDRESS") {
            if (res.payload.errorMessage.includes("Shipping address phone Enter a phone number")) {
              addToast("Customer phone number required", true);
              openModal(state => state.isModalOpen);
              return;
            }
            if (res.payload.errorMessage.includes("Delivery method shipping address phone is invalid")) {
              addToast("Phone number is invalid", true);
              return;
            }
            addToast(parseSubscriptionShippingErrorForMerchant(res.payload.errorMessage), true);
            return;
          }
          addToast("Subscription address updated");
        })
        .catch(error => {
          captureException("Error updating shipping address", error);
        });
    },
    [customerPurchaseState, organization]
  );

  return (
    <React.Fragment>
      {customerPurchaseState?.custRel ? (
        <Layout.Section secondary>
          <QuickJumpContainer style={{ position: "relative" }} id="subscription-details__quick-jump-section">
            <CustomerInfoAndDiscounts
              customerPurchaseState={customerPurchaseState}
              onApplyDiscountCode={onApplyDiscountCode}
              isApplyingDiscountCode={isApplyingDiscountCode}
              onRemoveDiscount={onRemoveDiscount}
              onUpdateShippingAddress={onUpdateShippingAddress}
              onEditNote={onEditNote}
            />
          </QuickJumpContainer>
        </Layout.Section>
      ) : null}
    </React.Fragment>
  );
};
