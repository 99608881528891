import { SHIPPING_RECALCULATION_ERROR_CODES } from "@smartrr/shared/types";
import React from "react";

export const ShippingWarningMessage: React.FC<{ type: string; shopifyDomain?: string }> = ({
  type,
  shopifyDomain,
}) => {
  switch (type) {
    case SHIPPING_RECALCULATION_ERROR_CODES.NO_DELIVERY_OPTIONS: {
      const deliveryOptionsUrl = `https://${shopifyDomain}/admin/settings/shipping`;
      return (
        <p>
          No applicable shipping options were found for the selected subscription program.{" "}
          <a href={deliveryOptionsUrl}>Please add a delivery option in Shopify</a>, or this subscription risks
          failing during the next billing cycle. (Error code: no_delivery_options)
        </p>
      );
    }
    case SHIPPING_RECALCULATION_ERROR_CODES.INVALID_PHONE_NUMBER: {
      return (
        <p>
          The customer&apos;s phone number for this shipping option is invalid. Please update their phone number,
          or this subscription risks failing during the next billing cycle. (Error code: invalid_phone_number)
        </p>
      );
    }
    default: {
      return <p>Oops, something went wrong calculating shipping price. Please contact support.</p>;
    }
  }
};
