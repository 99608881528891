import {
  ActionList,
  Button,
  ContextualSaveBar,
  LegacyCard,
  Popover,
  Text,
  Thumbnail,
  useIndexResourceState,
} from "@shopify/polaris";
import { IOrganization } from "@smartrr/shared/entities/Organization";
import { IPurchaseStateWithCustomerRelationship } from "@smartrr/shared/entities/PurchaseState";
import { IPurchaseStateLineItemPricingPolicy } from "@smartrr/shared/entities/PurchaseState/CustomerPurchaseLineItem";
import {
  ISmartrrAddOnsConfigSellingPlan,
  ISmartrrSellingPlan,
  ISmartrrSellingPlanGroup,
} from "@smartrr/shared/entities/SellingPlanGroup";
import { CurrencyCode } from "@smartrr/shared/shopifyGraphQL/api";
import { shopifyGidToNumber } from "@smartrr/shared/utils/ensureShopifyGid";
import { convertNumberForFormatMoney, getSymbolFromCurrency } from "@smartrr/shared/utils/formatMoney";
import {
  getUpcomingPricingCycle,
  selectPricingPolicyComputedPriceForCycle,
} from "@smartrr/shared/utils/getDiscounts";
import { isCPSPrepaid } from "@smartrr/shared/utils/isPrepaid";
import { pluralizeForPrefix } from "@smartrr/shared/utils/pluralize";
import {
  getVariantImageFromPurchasableMap,
  useVariantToPurchasableMap,
} from "@smartrr/shared/utils/useVariantToPurchasableMap";
import { isVariantInStock } from "@smartrr/shared/utils/variants";
import { cloneDeep, difference, flatten, isEqual, uniqBy } from "lodash";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { ModalVariantTitle } from "@vendor-app/app/_sharedComponents/BrowseProductsModal/components";
import { useToast } from "@vendor-app/app/_sharedComponents/Toast/ToastProvider";
import {
  SelectionType,
  usePolarisTypedTable,
} from "@vendor-app/app/_sharedComponents/TypedTable/usePolarisTypedTable";
import {
  updateAddItemModal,
  updateCancelSubscriptionModal,
  updateRemoveAllProductsWarningModal,
  updateShowBundleItems,
  updateSwapItemModal,
} from "@vendor-app/app/_state/actionCreators/subscriptionDetails";
import { useVendorPortalVariantToPurchasableMap } from "@vendor-app/app/_state/reducers/purchasables";
import { useSmartrrVendorDispatch, useSmartrrVendorSelector } from "@vendor-app/app/_state/typedVendorReduxHooks";
import { isRowSelected } from "@vendor-app/utils/isIndexTableRowSelected";

import {
  CPSLineItemsTableColumnKeyType,
  IPurchasableOption,
  TableContainer,
  getComputedPriceBasedOnPolicy,
  getFormattedItems,
  getNewLineItemWithPricingPolicyPrice,
  lineItemsColumns,
  parseLineItemsForIndexTable,
  useNextDelivery,
  useSellingPlans,
} from "../../../libs";
import { AddItemModal, RemoveAllProductsModal, ShowBundleContentModal, SwapItemModal } from "../../modals";
import { useAddItemsInBulk } from "../SubscriptionTabs/hooks/useAddItemsInBulk";
import { useDeleteItemsInBulk } from "../SubscriptionTabs/hooks/useDeleteItemsInBulk";
import { useSwapItems } from "../SubscriptionTabs/hooks/useSwapItems";
import { useUpdateItemsInBulk } from "../SubscriptionTabs/hooks/useUpdateItemsInBulk";
import { formatProductAndVariantNameForDisplay } from "@smartrr/shared/utils/displayUtils";
import type { SubscriptionDetailsLineItem } from "@smartrr/shared/interfaces/lineitem/api";
import { LineItemValidation } from "@smartrr/shared/interfaces/lineitem/validation";

export const SubscriptionItems = ({
  customerPurchaseState,
  organization,
  setUpdatedLineItems,
  updatedLineItems,
  areActionsDisabled,
  sellingPlan,
  addonsSellingPlan,
}: {
  sellingPlanGroup: ISmartrrSellingPlanGroup | undefined;
  areActionsDisabled: boolean;
  sellingPlan: ISmartrrSellingPlan | undefined;
  addonsSellingPlan: ISmartrrAddOnsConfigSellingPlan | undefined;
  customerPurchaseState: IPurchaseStateWithCustomerRelationship;
  updatedLineItems: SubscriptionDetailsLineItem[];
  setUpdatedLineItems: React.Dispatch<React.SetStateAction<SubscriptionDetailsLineItem[]>>;
  organization: IOrganization | null;
}) => {
  const { schedule, stLineItems, currency, sellingPlanId } = customerPurchaseState;
  const dispatch = useSmartrrVendorDispatch();
  const { addToast } = useToast();

  const { nextDelivery } = useNextDelivery(customerPurchaseState);
  const skdIdx =
    nextDelivery?.indexFromScheduleStart ??
    getUpcomingPricingCycle({ ...customerPurchaseState, organization: organization! });
  const orderNumber = schedule.totalOrdersCount || skdIdx;

  const { Table } = usePolarisTypedTable<CPSLineItemsTableColumnKeyType>();
  const { selectedResources, allResourcesSelected, handleSelectionChange } = useIndexResourceState(
    updatedLineItems as { [key: string]: any }[]
  );

  const { addOnsConfig, getSellingPlanGroup } = useSellingPlans();
  const sellingPlanGroup = getSellingPlanGroup(sellingPlanId);

  const purchasables = useSmartrrVendorSelector(state => state.purchasables.purchasables);
  const variantToPurchasableMap = useVendorPortalVariantToPurchasableMap();
  const variantToPurchasableMapForModal = useVariantToPurchasableMap(purchasables);

  const openSwapItemConfirmation = () => dispatch(updateSwapItemModal(true));
  const openRemoveAllProductsConfirmation = () => dispatch(updateRemoveAllProductsWarningModal(true));
  const showAddItem = useSmartrrVendorSelector(state => state.subscriptionDetails.showAddItem);
  const openAddItemModal = () => dispatch(updateAddItemModal(true));

  const [isOpenAddItemPopover, setOpenAddItemPopover] = useState(false);
  const [typeOfAddItem, setTypeOfAddItem] = useState<"addon" | "lineItem" | undefined>();
  const [isSaving, setIsSaving] = useState(false);
  const [lineItemIdsForModal, setLineItemIdsForModal] = useState<string[]>([]);

  const [variantIdToReplace, setVariantIdToReplace] = useState<string | undefined>();
  const [hasItemsChanges, setHasItemsChanges] = useState(false);

  const initialLineItemsRef = useRef<SubscriptionDetailsLineItem[]>(
    cloneDeep(
      getFormattedItems(
        stLineItems,
        orderNumber,
        variantToPurchasableMap,
        schedule.paymentFrequencyMultiple,
        skdIdx
      )
    )
  );

  const onDiscard = () => {
    const items = cloneDeep(initialLineItemsRef.current);
    setUpdatedLineItems(items);
  };

  const onUpdateItemsInBulk = useUpdateItemsInBulk(customerPurchaseState, onDiscard);
  const onDeleteItemsInBulk = useDeleteItemsInBulk(customerPurchaseState, onDiscard);
  const onAddItemsInBulk = useAddItemsInBulk(customerPurchaseState, onDiscard);
  const onSwapItems = useSwapItems(customerPurchaseState);

  const allItemsQuantity = updatedLineItems.length;

  const purchasablesOptions = useMemo(() => {
    const variantOptions: IPurchasableOption[] = [];
    for (const prev of flatten(purchasables.map(p => (p.vnts || []).map(v => ({ ...v, purchasable: p }))))
      .sort()
      .filter(v => {
        if (!v.shopifyId || !v.isActiveInShopify || v.isDraftOrArchived || !isVariantInStock(v)) {
          return false;
        }

        return typeOfAddItem === "addon"
          ? addOnsConfig?.variantIds.includes(+v.shopifyId!.replace("gid://shopify/ProductVariant/", ""))
          : sellingPlanGroup?.variantIds.includes(+v.shopifyId!.replace("gid://shopify/ProductVariant/", ""));
      })) {
      const imageUrl = getVariantImageFromPurchasableMap(variantToPurchasableMapForModal, prev);
      const purchasableAndVariantName = formatProductAndVariantNameForDisplay({
        purchasableName: prev.purchasable.purchasableName,
        purchasableVariantName: prev.purchasableVariantName,
        isActive: prev.isActiveInShopify,
      });
      const altText = `${purchasableAndVariantName} ${prev.sku ?? ""}`;

      const label = (
        <ModalVariantTitle title={altText}>
          {purchasableAndVariantName}
          {prev.sku ? (
            <Text variant="bodyMd" as="span" color="subdued">
              {prev.sku}
            </Text>
          ) : null}
        </ModalVariantTitle>
      );

      const result: IPurchasableOption = {
        title: altText,
        label,
        value: prev.shopifyId!,
        id: `subscription-details__item-with-id-${prev.shopifyId}`,
      };

      if (imageUrl) {
        result.media = <Thumbnail size="small" source={imageUrl} alt={altText} />;
      }

      variantOptions.push(result);
    }
    return variantOptions;
  }, [purchasables, typeOfAddItem, addOnsConfig, sellingPlanGroup]);

  const promotedBulkActions = useMemo(
    () =>
      [
        selectedResources.length === 1 &&
        !customerPurchaseState.stLineItems.find(item => item.id === selectedResources[0].replace("-addon", ""))
          ?.isAddOn
          ? {
              content: pluralizeForPrefix(selectedResources.length, "Swap item"),
              onAction() {
                setTypeOfAddItem("lineItem");
                setVariantIdToReplace(selectedResources[0]);
                openSwapItemConfirmation();
              },
            }
          : null,
        {
          content: pluralizeForPrefix(selectedResources.length, "Remove item"),
          onAction: () => softRemoveLineItems(selectedResources),
        },
      ].filter((x): x is any => x != null),
    [selectedResources, customerPurchaseState.stLineItems]
  );

  const softRemoveLineItems = useCallback(
    (selected: string[]) => {
      if (selected.length === updatedLineItems.length || updatedLineItems.length === 1) {
        openRemoveAllProductsConfirmation();
        return;
      }
      const lineItems = [...updatedLineItems];

      selected.map(id => {
        const lineItemIndex = lineItems.findIndex(item => item.id === id);
        if (lineItemIndex > -1) {
          if (lineItems[lineItemIndex].action === "add") {
            lineItems.splice(lineItemIndex, 1);
          } else {
            lineItems[lineItemIndex].action = "delete";
          }
        }
      });

      handleSelectionChange(SelectionType.All, false);
      setUpdatedLineItems(lineItems);
      setHasItemsChanges(true);
    },
    [updatedLineItems]
  );

  const handleSwapItems = useCallback(
    (replacedId: string | undefined, replacementId: string | undefined) => {
      const chosenVariant = flatten(purchasables.map(p => p.vnts)).find(
        vnt => vnt?.shopifyId && vnt.shopifyId === replacementId
      );
      onSwapItems(replacedId, chosenVariant?.id);
    },
    [purchasables]
  );

  const onEditProductsOrVariantsCb = useCallback(
    (selectedVntIds: string[]) => {
      const addedLineItems = [...updatedLineItems];
      //get all initial items where isAddon === typeOfAddItem
      //to filter addons out from recurring items
      const items = cloneDeep(initialLineItemsRef.current);
      const initialLineItemsVntIds = [...items]
        .filter(i => (typeOfAddItem === "addon" ? i.isAddOn : !i.isAddOn))
        .map(i => i.vnt?.shopifyId!);

      //check which line items changed
      const changedVariants = [
        ...difference(selectedVntIds, initialLineItemsVntIds),
        ...difference(initialLineItemsVntIds, selectedVntIds),
      ];

      //goes through all the changed items and adds/removes/updates them in the state
      changedVariants.map(vntShopifyId => {
        const isAddOn = typeOfAddItem === "addon";

        //selectedVntIds === modalSelectedVariantIds
        //check if variant is selected (selectedVntIds) and if it exists in initial line items
        //if not add line item to the state
        if (selectedVntIds.includes(vntShopifyId) && !initialLineItemsVntIds.includes(vntShopifyId)) {
          const variant = cloneDeep(
            flatten(purchasables.map(p => (p.vnts || []).map(vnt => ({ ...vnt, purchasable: p })))).find(
              vnt => vnt.shopifyId === vntShopifyId
            )
          );
          if (variant) {
            let newItem: SubscriptionDetailsLineItem = {
              id: `${variant.id}${isAddOn ? "-addon" : ""}`,
              shopifyId: variant?.shopifyId,
              quantity: 1,
              isAddOn,
              isRedeemed: false,
              skdIdx: isAddOn ? (skdIdx <= 0 ? 0 : skdIdx) : undefined,
              vnt: variant,
              action: "add",
              productName: variant?.purchasable?.purchasableName || "",
              variantName: variant.purchasableVariantName ?? "",
              finalPrice: getComputedPriceBasedOnPolicy(currency as CurrencyCode, variant, sellingPlan),
              bundleInfo: {},
            };

            if (sellingPlan && !newItem.isAddOn) {
              newItem = getNewLineItemWithPricingPolicyPrice(newItem, sellingPlan, currency as CurrencyCode);
            } else if (addonsSellingPlan) {
              newItem = getNewLineItemWithPricingPolicyPrice(
                newItem,
                addonsSellingPlan,
                currency as CurrencyCode
              );
            }

            addedLineItems.push(newItem);
          }
          //check if variant was deselected from variants modal, but exists in the initial items
        } else if (!selectedVntIds.includes(vntShopifyId) && initialLineItemsVntIds.includes(vntShopifyId)) {
          const currentLineItemIndex = addedLineItems.findIndex(
            item => item.vnt?.shopifyId === vntShopifyId && item.isAddOn === isAddOn
          );
          if (currentLineItemIndex > -1) {
            //if item was added to state, but wasn't saved (inst line item yet) remove it from the state
            if (addedLineItems[currentLineItemIndex].action === "add") {
              addedLineItems.splice(currentLineItemIndex, 1);
              //if item is a line item mark it as delete to soft remove it
              //will be deleted onSave
            } else {
              addedLineItems[currentLineItemIndex].action = "delete";
            }
          } else {
            addToast(`Cant find variant with id: ${vntShopifyId}`);
          }
        }
      });

      setUpdatedLineItems(uniqBy(addedLineItems, "id"));
    },
    [
      skdIdx,
      updatedLineItems,
      typeOfAddItem,
      initialLineItemsRef.current,
      customerPurchaseState,
      sellingPlan,
      addonsSellingPlan,
    ]
  );

  const onSave = async () => {
    const hasAllDeletedItems = updatedLineItems.filter(item => item.action !== "delete").length === 0;
    const hasAllRemovedQuantities =
      updatedLineItems.filter(item => item.action === "delete" || item.quantity === 0).length ===
      updatedLineItems.length;

    if (hasAllDeletedItems || hasAllRemovedQuantities) {
      openRemoveAllProductsConfirmation();
      return;
    }

    const res = LineItemValidation.SDLineItem.validateLineItemUpdatesWithZod(updatedLineItems);
    if (!res.success && res.error.message) {
      const errorStr = res.error.errors.map(err => err.message).join(", ");
      addToast(errorStr, true);
      return;
    }

    if (updatedLineItems.some(item => (!item.finalPrice || +item.finalPrice < 0) && item.action !== "delete")) {
      addToast("Can't use negative values", true);
      onDiscard();
      return;
    }

    setIsSaving(true);
    //TODO: add check for existing item before adding it to subscription
    //that way update can be put instead if post
    if (updatedLineItems.filter(i => i.action === "add").length) {
      const itemsToAdd = [...updatedLineItems]
        .filter(i => i.action === "add" && i.vnt != null)
        .map(item => {
          const { quantity, finalPrice, vnt, isAddOn, skdIdx } = item;
          const variantId = vnt?.shopifyId ? shopifyGidToNumber(vnt.shopifyId) : "";

          const pseudoSellingPlanId = "pseudo-recharge-sellingplan";

          const shouldAddItem = quantity && quantity > 0 && variantId && finalPrice != null;

          if (!shouldAddItem) {
            return;
          }

          const newLineItem = {
            quantity,
            variantId: vnt?.id,
            sellingPlanId:
              addOnsConfig && isAddOn
                ? (addonsSellingPlan?.shopifyId ?? pseudoSellingPlanId)
                : (sellingPlanId ?? pseudoSellingPlanId),
            skdIdx,
          };

          const isPriceDifferent =
            getComputedPriceBasedOnPolicy(
              currency as CurrencyCode,
              vnt,
              isAddOn ? addonsSellingPlan : sellingPlan
            ) !== (+finalPrice).toFixed(2);

          if (isPriceDifferent) {
            if (isCPSPrepaid(customerPurchaseState.schedule)) {
              return {
                ...newLineItem,
                price: Number(finalPrice) * schedule.paymentFrequencyMultiple,
              };
            }
            return {
              ...newLineItem,
              price: Number(finalPrice),
            };
          }

          return newLineItem;
        })
        .filter((x): x is any => x != null);

      await onAddItemsInBulk(itemsToAdd);
    }

    if (updatedLineItems.filter(i => i.action === "delete").length) {
      const itemsToUpdate = [...updatedLineItems]
        .filter(i => i.action === "delete")
        .map(lineItem => lineItem.id.replace("-addon", ""));

      await onDeleteItemsInBulk(itemsToUpdate);
    }

    if (updatedLineItems.filter(i => i.action === "update").length) {
      const itemsToUpdate = [...updatedLineItems]
        .filter(i => i.action === "update" && i.vnt != null)
        .map(lineItem => {
          const lineItemToUpdate = {
            id: lineItem.id.replace("-addon", ""),
            quantity: lineItem.quantity,
            variantId: lineItem.vnt?.id,
            sellingPlanId: customerPurchaseState.sellingPlanId ?? "",
          };

          const pricingPolicy: IPurchaseStateLineItemPricingPolicy | undefined = stLineItems.find(
            item => item.id === lineItem.id.replace("-addon", "")
          )?.pricingPolicy;
          const { finalPrice } = lineItem;

          //Condition for PREPAID
          //price gets multiplied by the number of prepaid orders on the backend
          //to keep this condition with a custom price, the entered price should be multiplied
          if (isCPSPrepaid(customerPurchaseState.schedule)) {
            return { ...lineItemToUpdate, price: +lineItem.finalPrice * schedule.paymentFrequencyMultiple };
          } else if (
            pricingPolicy &&
            (convertNumberForFormatMoney(
              selectPricingPolicyComputedPriceForCycle(pricingPolicy, orderNumber),
              currency
            ).toFixed(2) !== (+finalPrice).toFixed(2) ||
              //if custom price inst passed along, the price gets set back to original value
              //so in case the price was ever changed by hand we want to keep that value
              pricingPolicy.cycleDiscounts[0].adjustmentType === "PRICE")
          ) {
            return { ...lineItemToUpdate, price: +lineItem.finalPrice };
          }

          return lineItemToUpdate;
        });

      await onUpdateItemsInBulk(itemsToUpdate);
    }
    setIsSaving(false);
  };

  const onAddProductsAndVariantsClick = useCallback(
    async (type: "addon" | "lineItem") => {
      if (type === "addon") {
        setLineItemIdsForModal(
          updatedLineItems.filter(i => i.isAddOn && i.vnt != null).map(i => i.vnt?.shopifyId!)
        );
      } else {
        setLineItemIdsForModal(
          updatedLineItems.filter(i => !i.isAddOn && i.vnt != null).map(i => i.vnt?.shopifyId!)
        );
      }

      setTypeOfAddItem(type);
      openAddItemModal();
    },
    [updatedLineItems]
  );

  useEffect(() => {
    setHasItemsChanges(!isEqual(initialLineItemsRef.current, updatedLineItems));
  }, [initialLineItemsRef.current, updatedLineItems]);

  return (
    <React.Fragment>
      {!!hasItemsChanges && (
        <ContextualSaveBar
          message="Unsaved changes"
          discardAction={{
            content: "Discard",
            onAction: onDiscard,
          }}
          saveAction={{
            loading: isSaving,
            content: "Save",
            onAction: onSave,
          }}
        />
      )}
      <LegacyCard.Section flush>
        <TableContainer>
          <Table
            selectable={!areActionsDisabled}
            data={parseLineItemsForIndexTable(
              updatedLineItems,
              setUpdatedLineItems,
              variantToPurchasableMap,
              areActionsDisabled,
              getSymbolFromCurrency(customerPurchaseState.currency as CurrencyCode),
              addToast,
              lineItem => {
                if (lineItem?.bundleInfo?.id) {
                  dispatch(updateShowBundleItems(true, lineItem));
                }
              }
            )}
            columns={lineItemsColumns}
            resourceName={{
              singular: "subscription",
              plural: "subscriptions",
            }}
            loading={false}
            itemCount={allItemsQuantity}
            selectedItemsCount={allResourcesSelected ? "All" : selectedResources.length}
            onSelectionChange={(selectionType, toggleType, selection) => {
              handleSelectionChange(selectionType, toggleType, selection);
            }}
            promotedBulkActions={promotedBulkActions}
            emptyStateText={"No items"}
            isRowSelected={id => isRowSelected(id, selectedResources)}
          />
        </TableContainer>
      </LegacyCard.Section>

      <LegacyCard.Section>
        <Popover
          active={isOpenAddItemPopover}
          onClose={() => setOpenAddItemPopover(false)}
          activator={
            <Button
              id="subscription-details__add-items-button"
              primary
              disabled={areActionsDisabled}
              onClick={() => {
                setOpenAddItemPopover(a => !a);
              }}
            >
              Add item
            </Button>
          }
        >
          <ActionList
            items={[
              {
                id: "subscription-details__add-addon-item",
                content: "One-time item",
                disabled: !addOnsConfig?.variantIds?.length,
                onAction: () => onAddProductsAndVariantsClick("addon"),
              },
              {
                id: "subscription-details__add-recurring-item",
                content: "Recurring item",
                disabled: !sellingPlanId,
                onAction: () => onAddProductsAndVariantsClick("lineItem"),
              },
            ]}
          />
        </Popover>
      </LegacyCard.Section>
      {/* Add add-ons/recurring items Modal */}
      {!!showAddItem && (
        <AddItemModal
          selected={lineItemIdsForModal}
          options={purchasablesOptions}
          typeOfAddItem={typeOfAddItem}
          onEditProductsOrVariantsCb={onEditProductsOrVariantsCb}
        />
      )}
      {/* Swap items modal */}
      <SwapItemModal
        options={purchasablesOptions}
        variantIdToReplace={variantIdToReplace}
        onSwapItems={handleSwapItems}
      />

      {/*Remove all products warning modal*/}
      <RemoveAllProductsModal
        onCancel={() => {
          handleSelectionChange(SelectionType.All, false);
        }}
        onConfirm={() => {
          dispatch(updateCancelSubscriptionModal(true));
        }}
      />
      <ShowBundleContentModal />
    </React.Fragment>
  );
};
