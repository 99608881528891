import { LegacyCard, Text } from "@shopify/polaris";
import { EditMajor } from "@shopify/polaris-icons";
import { IPurchaseStateWithCustomerRelationship } from "@smartrr/shared/entities/PurchaseState";
import { ScheduledDeliveryGroupWithPurchSt } from "@smartrr/shared/entities/PurchaseState/scheduleUtils";
import { RRuleManager } from "@smartrr/shared/entities/Schedule/RRuleManager";
import { ISmartrrAddOnsConfigSellingPlan, ISmartrrSellingPlan } from "@smartrr/shared/entities/SellingPlanGroup";
import { getShippingDisplay } from "@smartrr/shared/utils/displayUtils";
import { pluralize } from "@smartrr/shared/utils/pluralize";
import type { SubscriptionDetailsLineItem } from "@smartrr/shared/interfaces/lineitem/api";
import React, { useMemo } from "react";

import { updateShippingPriceModal } from "@vendor-app/app/_state/actionCreators/subscriptionDetails";
import { useSmartrrVendorDispatch, useSmartrrVendorSelector } from "@vendor-app/app/_state/typedVendorReduxHooks";

import {
  GridContainer,
  GridWithCustomWidth,
  getDeliveryMethodTitle,
  getSubscriptionSubtotal,
  getSubscriptionTotal,
} from "../../../libs";
import { EditShippingPriceModal } from "../../modals";
import { useUpdateShippingPrice } from "../SubscriptionTabs/hooks/useUpdateShippingPrice";
import { isInteger } from "lodash";
import { isUpcomingOrderCPSPrepaid } from "@smartrr/shared/utils/isPrepaid";

interface ISubscriptionTotalProps {
  customerPurchaseState: IPurchaseStateWithCustomerRelationship;
  nextDelivery: ScheduledDeliveryGroupWithPurchSt<IPurchaseStateWithCustomerRelationship> | undefined;
  skdIdx: number | undefined;
  sellingPlan?: ISmartrrSellingPlan;
  addonsSellingPlan?: ISmartrrAddOnsConfigSellingPlan;
  updatedLineItems: SubscriptionDetailsLineItem[];
  areActionsDisabled: boolean;
  rruleManager: RRuleManager | undefined;
  shippingCalculationWarning: string | undefined;
}

export const SubscriptionTotal = ({
  customerPurchaseState,
  nextDelivery,
  skdIdx,
  sellingPlan,
  addonsSellingPlan,
  updatedLineItems,
  areActionsDisabled,
  rruleManager,
  shippingCalculationWarning,
}: ISubscriptionTotalProps) => {
  const { deliveryMethod } = customerPurchaseState;
  const dispatch = useSmartrrVendorDispatch();
  const openShippingPriceModal = () => dispatch(updateShippingPriceModal(true));
  const showEditPrice = useSmartrrVendorSelector(state => state.subscriptionDetails.showShippingPrice);

  const onUpdateShippingPrice = useUpdateShippingPrice(customerPurchaseState);

  const allItemsQuantity = [...updatedLineItems].reduce((acc, prev) => {
    if (!prev.quantity) {
      return acc;
    }
    acc += prev.quantity;
    return acc;
  }, 0);

  const hasAddons = useMemo(() => {
    return nextDelivery?.purchaseState.stLineItems.some(
      line => isInteger(line.skdIdx) && line.skdIdx === nextDelivery.indexFromScheduleStart
    );
  }, [nextDelivery]);

  const shippingDisplay = useMemo(() => {
    if (nextDelivery && isUpcomingOrderCPSPrepaid(nextDelivery.paymentMultipleDueOnDate) && !hasAddons) {
      return "Prepaid";
    }
    return nextDelivery?.purchaseState ? getShippingDisplay(nextDelivery.purchaseState) : "--";
  }, [nextDelivery, hasAddons]);

  const showEditShipping = useMemo(() => {
    return !areActionsDisabled && shippingDisplay !== "Prepaid" && shippingDisplay !== "--";
  }, [areActionsDisabled, shippingDisplay]);

  return (
    <LegacyCard sectioned>
      <GridContainer>
        <Text variant="headingMd" as="h2">
          Estimated total
        </Text>
        <GridWithCustomWidth>
          <Text variant="bodyMd" as="p">
            Subtotal
          </Text>
          <Text variant="bodyMd" as="p" id="subscription-details__items-quantity">
            {pluralize(allItemsQuantity, "item")}
          </Text>
          <Text variant="bodyMd" as="p" id="subscription-details__subtotal">
            {getSubscriptionSubtotal({
              customerPurchaseState,
              nextDelivery,
              skdIdx,
              updatedLineItems,
              sellingPlan,
              addonsSellingPlan,
            })}
          </Text>
        </GridWithCustomWidth>
        <GridWithCustomWidth>
          <Text variant="bodyMd" as="p">
            Shipping
          </Text>
          <Text variant="bodyMd" as="p" truncate id="subscription-details__delivery-method">
            {shippingCalculationWarning ? "--" : getDeliveryMethodTitle(deliveryMethod)}
          </Text>
          <Text variant="bodyMd" as="p" id="subscription-details__shipping">
            {shippingCalculationWarning ? "--" : shippingDisplay}
          </Text>
          {showEditShipping ? (
            <div
              style={{ cursor: "pointer" }}
              onClick={() => openShippingPriceModal()}
              id="subscription-details__change-shipping-price"
            >
              <EditMajor height="16px" width="16px" />
            </div>
          ) : null}
        </GridWithCustomWidth>
        <GridWithCustomWidth>
          <Text variant="bodyMd" as="p" fontWeight="semibold">
            Estimated total
          </Text>
          <div />
          <Text variant="bodyMd" as="p" fontWeight="semibold" id="subscription-details__total">
            {getSubscriptionTotal({
              customerPurchaseState,
              nextDelivery,
              updatedLineItems,
              sellingPlan,
              addonsSellingPlan,
              nextActualDelivery: rruleManager?.nextActualDelivery,
            })}
          </Text>
        </GridWithCustomWidth>
        <Text variant="bodyMd" as="p" color="subdued">
          Excludes taxes
        </Text>
      </GridContainer>
      {/* Edit shipping price for cps*/}
      {showEditPrice ? (
        <EditShippingPriceModal
          customerPurchaseState={customerPurchaseState}
          onUpdate={price => onUpdateShippingPrice(price)}
        />
      ) : null}
    </LegacyCard>
  );
};
