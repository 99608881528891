import { Navigate } from "@tanstack/react-router";
import { useSmartrrVendorSelector } from "@vendor-app/app/_state/typedVendorReduxHooks";
import { SellingPlansProvider } from "./libs/providers/SellingPlansProvider";
import { SubscriptionDetails } from "./SubscriptionDetails";
import useSubscriptionEligibility from "../AdminSetupRoute/Tabs/SubscriptionSetup/libs/hooks/useSubscriptionEligibility";
import { IS_SANDBOX_FRONTEND, IS_STAGING_FRONTEND } from "@smartrr/shared/constants";

export function AdminSubscriptionDetailsRoute({ subscriptionId }: { subscriptionId: string }): JSX.Element {
  const { eligibleForSubscriptions } = useSubscriptionEligibility();
  const isSuperUser = useSmartrrVendorSelector(state => state.auth.user?.isSuperUser);

  if (!eligibleForSubscriptions && !isSuperUser && !(IS_STAGING_FRONTEND || IS_SANDBOX_FRONTEND)) {
    return <Navigate to="/admin/setup" />;
  }

  return (
    <SellingPlansProvider>
      <SubscriptionDetails subscriptionId={subscriptionId} />
    </SellingPlansProvider>
  );
}
