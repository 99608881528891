import { TextField } from "@shopify/polaris";
import { IPurchasable } from "@smartrr/shared/entities/Purchasable";
import { isVariantInStock } from "@smartrr/shared/utils/variants";
import React from "react";

import { CPSLineItemsParsedDataType, CPSLineItemsTableColumnType, NumberFieldNoArrows } from "..";
import { LineItemDisplay } from "../../SubscriptionDetails/components/LineItemDisplay";
import type { SubscriptionDetailsLineItem } from "@smartrr/shared/interfaces/lineitem/api";

export const lineItemsColumns: CPSLineItemsTableColumnType = {
  itemName: {
    name: "Product",
    paginatedValue: "itemName",
    filtering: false,
    sorting: false,
    disabled: false,
  },
  quantity: {
    name: "Quantity",
    paginatedValue: "quantity",
    filtering: false,
    sorting: false,
    disabled: false,
  },
  price: {
    name: "Price",
    paginatedValue: "price",
    filtering: false,
    sorting: false,
    disabled: false,
  },
};

export const parseLineItemsForIndexTable = (
  updatedLineItems: SubscriptionDetailsLineItem[],
  setUpdatedLineItems: React.Dispatch<React.SetStateAction<SubscriptionDetailsLineItem[]>>,
  variantToPurchasableMap: Record<string, IPurchasable>,
  areActionsDisabled: boolean,
  currencySymbol: string,
  addToast: (text: string) => void,
  onItemNameClicked: (item: SubscriptionDetailsLineItem) => void
): CPSLineItemsParsedDataType[] => {
  const changeLineItemPrice = (
    items: SubscriptionDetailsLineItem[],
    price: number | string,
    lineItem: SubscriptionDetailsLineItem
  ) => {
    const newItems = [...items];
    const index = newItems.findIndex(item => item.id === lineItem.id);

    if (index > -1) {
      newItems[index].finalPrice = String(price);
      //making sure that new items are saved before editing
      newItems[index].action = newItems[index].action === "add" ? "add" : "update";
    }
    return newItems;
  };

  const changeLineItemQuantity = (
    items: SubscriptionDetailsLineItem[],
    quantity: number | string,
    lineItem: SubscriptionDetailsLineItem
  ) => {
    const newItems = [...items];
    const index = newItems.findIndex(item => item.id === lineItem.id);

    if (quantity === "") {
      newItems[index].quantity = undefined;
      newItems[index].action = newItems[index].action === "add" ? "add" : "delete";
      return newItems;
    }

    if (index > -1 && isVariantInStock(newItems[index]?.vnt, +quantity)) {
      if (quantity === 0) {
        if (newItems[index].action === "add") {
          newItems.splice(index, 1);
          return newItems;
        }
        newItems[index].quantity = 0;
        newItems[index].action = "delete";
        return newItems;
      }
      newItems[index].quantity = +quantity;
      //making sure that new items are saved before editing
      newItems[index].action = newItems[index].action === "add" ? "add" : "update";
    } else {
      addToast("Low Shopify Inventory");
    }
    return newItems;
  };

  return updatedLineItems
    .filter(item => item.action !== "delete" || (item.action === "delete" && !item.quantity))
    .map(lineItem => {
      const { id, vnt, quantity, isAddOn, isRedeemed, productName, variantName } = lineItem;
      const purchasable = vnt?.id ? variantToPurchasableMap[vnt?.id] : undefined;

      return {
        id,
        lineItem,
        itemName: (
          <div onClick={() => onItemNameClicked(lineItem)}>
            <LineItemDisplay
              purchasable={purchasable}
              vnt={vnt}
              productName={productName}
              variantName={variantName}
              isAddOn={isAddOn ?? false}
              isRedeemed={isRedeemed ?? false}
              isBundle={!!lineItem?.bundleInfo?.id}
            />
          </div>
        ),
        quantity: (
          <NumberFieldNoArrows>
            <TextField
              label="Quantity"
              name="Quantity"
              labelHidden
              autoComplete="off"
              min={0}
              type="number"
              disabled={areActionsDisabled}
              value={String(quantity ?? 0)}
              onChange={value => setUpdatedLineItems(items => changeLineItemQuantity(items, value, lineItem))}
            />
          </NumberFieldNoArrows>
        ),
        price: (
          <NumberFieldNoArrows>
            <TextField
              label="Price"
              name="Price"
              labelHidden
              autoComplete="off"
              min={0}
              type="number"
              disabled={areActionsDisabled || isRedeemed || isAddOn}
              prefix={currencySymbol}
              value={String(lineItem.finalPrice)}
              onChange={value => setUpdatedLineItems(items => changeLineItemPrice(items, value, lineItem))}
            />
          </NumberFieldNoArrows>
        ),
      };
    });
};
