import { Form, FormLayout, Modal, TextField } from "@shopify/polaris";
import { BigIntString } from "@smartrr/shared/entities/BigIntString";
import { IPurchaseState } from "@smartrr/shared/entities/PurchaseState";
import { CurrencyCode } from "@smartrr/shared/shopifyGraphQL/api";
import { convertNumberForFormatMoney, unformatMoney } from "@smartrr/shared/utils/formatMoney";
import React, { useCallback, useState } from "react";

import { updateShippingPriceModal } from "@vendor-app/app/_state/actionCreators/subscriptionDetails";
import { useSmartrrVendorDispatch, useSmartrrVendorSelector } from "@vendor-app/app/_state/typedVendorReduxHooks";

interface IEditShippingPriceModalProps {
  customerPurchaseState: IPurchaseState;
  onUpdate(price: number): Promise<void>;
}

export function EditShippingPriceModal({
  onUpdate,
  customerPurchaseState,
}: IEditShippingPriceModalProps): JSX.Element {
  const dispatch = useSmartrrVendorDispatch();
  const showEditPrice = useSmartrrVendorSelector(state => state.subscriptionDetails.showShippingPrice);
  const closeModal = () => dispatch(updateShippingPriceModal(false));

  const [loading, setLoading] = useState(false);
  const [price, setPrice] = useState(
    String(
      convertNumberForFormatMoney(
        BigIntString.fromBigIntString(customerPurchaseState.totalShipping),
        customerPurchaseState.currency
      )
    )
  );

  const onUpdateClick = useCallback(async () => {
    setLoading(true);
    await onUpdate(unformatMoney(price, customerPurchaseState.currency as CurrencyCode));
    setLoading(false);
    closeModal();
  }, [onUpdate, price, customerPurchaseState]);

  return (
    <Modal
      title={"Edit shipping price"}
      open={showEditPrice}
      onClose={() => {
        closeModal();
      }}
      primaryAction={{
        id: "edit-shipping-price__confirm-btn",
        loading,
        content: "Update",
        disabled: !price || +price < 0,
        onAction: onUpdateClick,
      }}
      secondaryActions={[
        {
          id: "edit-shipping-price__cancel-btn",
          content: "Cancel",
          onAction() {
            closeModal();
          },
        },
      ]}
    >
      <Modal.Section>
        <Form onSubmit={onUpdateClick}>
          <FormLayout>
            <FormLayout.Group>
              <TextField
                id="edit-shipping-price__price-textfield"
                autoComplete="off"
                label="Price"
                type="currency"
                value={price}
                suffix={customerPurchaseState.currency}
                onChange={setPrice}
              />
            </FormLayout.Group>
          </FormLayout>
        </Form>
      </Modal.Section>
    </Modal>
  );
}
