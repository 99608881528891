import { IPurchaseStateWithCustomerRelationship } from "@smartrr/shared/entities/PurchaseState";
import { useCallback } from "react";

import { useToast } from "@vendor-app/app/_sharedComponents/Toast/ToastProvider";
import { bulkRemoveCustomerPurchaseStateLineItem } from "@vendor-app/app/_state/actionCreators/customerPurchaseState";
import { useSmartrrVendorDispatch } from "@vendor-app/app/_state/typedVendorReduxHooks";

export const useDeleteItemsInBulk = (
  customerPurchaseState: IPurchaseStateWithCustomerRelationship,
  onError?: () => void,
  onCommitStart?: () => void,
  onCommitEnd?: () => void
) => {
  const dispatch = useSmartrrVendorDispatch();
  const { addToast } = useToast();

  return useCallback(
    async (lineItemIds: string[]) => {
      if (!(customerPurchaseState && customerPurchaseState.custRel)) {
        return;
      }

      onCommitStart && onCommitStart();
      await dispatch(
        bulkRemoveCustomerPurchaseStateLineItem({
          custRelId: customerPurchaseState.custRel.id,
          cpsId: customerPurchaseState.id,
          lineItemIds,
        })
      )
        .then(res => {
          if (res.type === "BULK_REMOVED_CPS_LINE_ITEM") {
            addToast("Item(s) deleted");
          }
          if (res.type === "ERROR_BULK_REMOVING_CPS_LINE_ITEM") {
            addToast("Error updating subscription", true);
            onError && onError();
          }
        })
        .catch(error => {
          addToast("Error deleting item(s)", true);
          onError && onError();
        });
      onCommitEnd && onCommitEnd();
    },
    [customerPurchaseState]
  );
};
