import { ActionList, ActionListItemDescriptor, Button, LegacyStack, Popover } from "@shopify/polaris";
import { ExternalMinor } from "@shopify/polaris-icons";
import { OrgUtils } from "@smartrr/shared/entities/Organization";
import {
  IPurchaseStateWithCustomerRelationship,
  PurchaseStateStatus,
} from "@smartrr/shared/entities/PurchaseState";
import { getPurchStFutureScheduledDeliveries } from "@smartrr/shared/entities/PurchaseState/scheduleUtils";
import { SubscriptionContractSubscriptionStatus } from "@smartrr/shared/shopifyGraphQL/api";
import React, { useCallback, useState } from "react";

import { useToast } from "@vendor-app/app/_sharedComponents/Toast/ToastProvider";
import { activateCustomerPurchaseState } from "@vendor-app/app/_state/actionCreators/customerPurchaseState";
import {
  updateCancelSubscriptionModal,
  updateRawContractsModal,
  updateShowSetUnpauseDate,
} from "@vendor-app/app/_state/actionCreators/subscriptionDetails";
import { useActiveOrganizationSelector } from "@vendor-app/app/_state/reducers/organizations";
import { useSmartrrVendorDispatch } from "@vendor-app/app/_state/typedVendorReduxHooks";

import { viewSubscriptionsByCustomer } from "../../../libs";

interface SubscriptionDetailsNavBarProps {
  customerPurchaseState: IPurchaseStateWithCustomerRelationship | undefined;
  actualPurchaseStateStatus: `${PurchaseStateStatus}` | PurchaseStateStatus | undefined;
  onCommitBegin: () => void;
  onCommitEnd: () => void;
  isUpdatingPurchaseState: boolean;
}

export const SubscriptionDetailsNavBar = ({
  customerPurchaseState,
  actualPurchaseStateStatus,
  onCommitBegin,
  onCommitEnd,
  isUpdatingPurchaseState,
}: SubscriptionDetailsNavBarProps) => {
  const dispatch = useSmartrrVendorDispatch();
  const organization = useActiveOrganizationSelector();

  const { addToast } = useToast();

  const [isOpenMoreActionsPopover, setOpenMoreActionsPopover] = useState(false);

  const isMigrating =
    customerPurchaseState?.externalSubscriptionStatus === SubscriptionContractSubscriptionStatus.Active;
  const openSetUnpauseDateModal = () => dispatch(updateShowSetUnpauseDate(true));

  const onActivateSubscription = useCallback(async () => {
    if (!(customerPurchaseState && customerPurchaseState.custRel && organization)) {
      return;
    }

    onCommitBegin();
    await dispatch(
      activateCustomerPurchaseState({
        customerPurchaseStateId: customerPurchaseState.id,
        customerRelationshipId: customerPurchaseState.custRel.id,
        nextBillingDate: getPurchStFutureScheduledDeliveries(
          { ...customerPurchaseState, purchaseStateStatus: SubscriptionContractSubscriptionStatus.Active },
          OrgUtils.getBillingSchedule(organization),
          1
        )[0]?.date,
        addToast,
      })
    );
    onCommitEnd();
  }, [customerPurchaseState, organization, addToast]);

  const getPageActions = (customerPurchaseState: IPurchaseStateWithCustomerRelationship | undefined) => {
    if (!customerPurchaseState) {
      return [];
    }

    const actions: ActionListItemDescriptor[] = [];

    actions.push({
      id: "subscription-details__view-raw-data-action",
      content: "View raw subscription contract",
      onAction() {
        dispatch(updateRawContractsModal(true));
      },
      disabled: isMigrating,
    });

    if (
      actualPurchaseStateStatus !== SubscriptionContractSubscriptionStatus.Cancelled &&
      customerPurchaseState.externalSubscriptionStatus !== SubscriptionContractSubscriptionStatus.Active
    ) {
      actions.push({
        id: "subscription-details__change-status-action",
        content: `${
          actualPurchaseStateStatus === SubscriptionContractSubscriptionStatus.Active ? "Pause" : "Activate"
        } subscription`,
        onAction:
          actualPurchaseStateStatus === SubscriptionContractSubscriptionStatus.Active
            ? openSetUnpauseDateModal
            : onActivateSubscription,
        disabled:
          isMigrating ||
          actualPurchaseStateStatus === SubscriptionContractSubscriptionStatus.Expired ||
          !!isUpdatingPurchaseState,
      });
    }

    if (customerPurchaseState.custRel) {
      actions.push({
        id: "subscription-details__cancel-action",
        content: "Cancel subscription",
        onAction: () => dispatch(updateCancelSubscriptionModal(true)),
        destructive: true,
        disabled:
          isMigrating ||
          actualPurchaseStateStatus === SubscriptionContractSubscriptionStatus.Cancelled ||
          actualPurchaseStateStatus === SubscriptionContractSubscriptionStatus.Expired ||
          !!isUpdatingPurchaseState,
      });
    }
    return actions;
  };

  return (
    <React.Fragment>
      <LegacyStack spacing="baseTight">
        <Popover
          active={isOpenMoreActionsPopover}
          onClose={() => setOpenMoreActionsPopover(false)}
          activator={
            <Button
              id="subscription-details__manage-subscriptions-button"
              disclosure={isOpenMoreActionsPopover ? "up" : "down"}
              plain
              monochrome
              removeUnderline
              onClick={() => setOpenMoreActionsPopover(open => !open)}
            >
              Manage subscription
            </Button>
          }
        >
          <ActionList
            items={getPageActions(customerPurchaseState)}
            onActionAnyItem={() => setOpenMoreActionsPopover(false)}
          />
        </Popover>
        <Button
          id="subscription-details__view-in-shopify-button"
          plain
          monochrome
          icon={ExternalMinor}
          onClick={() =>
            viewSubscriptionsByCustomer(customerPurchaseState?.custRel?.shopifyId ?? "", organization)
          }
        >
          View in Shopify
        </Button>
      </LegacyStack>
    </React.Fragment>
  );
};
