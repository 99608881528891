import { DiscountValueType, IPurchaseStateDiscount } from "../../interfaces/Discount";
import { PREPAID_SHIPPING_DISCOUNT_CODE, PREPAID_SUBSCRIPTION_DISCOUNT_CODE } from "../getDiscounts";

export const isDiscountCodeForPrepaid = (discountCode: IPurchaseStateDiscount): boolean => {
  return (
    [PREPAID_SUBSCRIPTION_DISCOUNT_CODE, PREPAID_SHIPPING_DISCOUNT_CODE].includes(discountCode.code ?? "") &&
    discountCode.value === 100 &&
    discountCode.valueType === DiscountValueType.PERCENTAGE
  );
};
