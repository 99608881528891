import { flatten } from "lodash";

import { groupAndSortByDate } from "./groupAndSortByDate";
import { IOrganization, OrgUtils } from "../entities/Organization";
import { IPurchaseState, PurchaseStateAndDelivery } from "../entities/PurchaseState";
import {
  ScheduledDeliveryGroupWithPurchSt,
  getPurchStFutureActualDeliveries,
  getPurchStFutureScheduledDeliveries,
  getPurchStFutureScheduledDeliveriesUntilDate,
  getPurchStFutureScheduledDeliveriesWithNonActive,
} from "../entities/PurchaseState/scheduleUtils";

export function getNextScheduledDeliveryForCpsGroup(
  organization: IOrganization,
  customerPurchaseStates: IPurchaseState[]
): [Date, PurchaseStateAndDelivery[]] | null {
  return getScheduledDeliveriesForCpsGroup(organization, customerPurchaseStates, 1)[0] || null;
}

export function getScheduledDeliveriesForCpsGroup(
  organization: IOrganization,
  customerPurchaseStates: IPurchaseState[],
  numberOfFutureDeliveriesToGenerate = 5,
  shouldIncludeNonActive = false
): [Date, PurchaseStateAndDelivery[]][] {
  return flattenAndSortDeliveries(
    organization,
    customerPurchaseStates,
    numberOfFutureDeliveriesToGenerate,
    shouldIncludeNonActive
  );
}

export function getScheduledDeliveriesForCps(
  organization: IOrganization,
  cps: IPurchaseState,
  numberOfFutureDeliveriesToGenerate = 5,
  shouldIncludeNonActive = false
): PurchaseStateAndDelivery[] {
  let finalNumberOfDeliveriesToGenerate = numberOfFutureDeliveriesToGenerate;
  if (cps.schedule.maxCycles) {
    finalNumberOfDeliveriesToGenerate =
      cps.schedule.maxCycles * cps.schedule.paymentFrequencyMultiple - cps.schedule.totalOrdersCount!;

    finalNumberOfDeliveriesToGenerate = Math.min(
      finalNumberOfDeliveriesToGenerate,
      numberOfFutureDeliveriesToGenerate
    );
  }

  const getDeliveries = shouldIncludeNonActive
    ? getPurchStFutureScheduledDeliveriesWithNonActive
    : getPurchStFutureScheduledDeliveries;

  return getDeliveries(
    { ...cps, organization },
    OrgUtils.getBillingSchedule(organization),
    finalNumberOfDeliveriesToGenerate
  );
}

function flattenAndSortDeliveries(
  organization: IOrganization,
  cpsList: IPurchaseState[],
  numberOfFutureDeliveriesToGenerate = 5,
  shouldIncludeNonActive = false
): [Date, PurchaseStateAndDelivery[]][] {
  return groupAndSortByDate(
    flatten(
      cpsList.map(cps =>
        getScheduledDeliveriesForCps(
          organization,
          cps,
          numberOfFutureDeliveriesToGenerate,
          shouldIncludeNonActive
        )
      )
    ),
    v => v.date
  ).map(([date, dateGroup]) => [
    date,
    // leveraging shopify's numeric IDs to put the most recently created subscription first
    dateGroup.sort((a, b) => (`${a.purchaseState.shopifyId}` > `${b.purchaseState.shopifyId}` ? -1 : 1)),
  ]);
}

// skipped deliveries are excluded
export function getNextActualDeliveryForCpsGroup<T extends IPurchaseState>(
  organization: IOrganization,
  customerPurchaseStates: T[]
): [Date, ScheduledDeliveryGroupWithPurchSt<T>[]] | null {
  return getActualDeliveriesForCpsGroup(organization, customerPurchaseStates, 1)[0] || null;
}
export function getActualDeliveriesForCpsGroup<T extends IPurchaseState>(
  organization: IOrganization,
  customerPurchaseStates: T[],
  numberOfFutureDeliveriesToGenerate = 5
): [Date, ScheduledDeliveryGroupWithPurchSt<T>[]][] {
  return groupAndSortByDate(
    flatten(
      customerPurchaseStates.map(cps =>
        getPurchStFutureActualDeliveries(
          { ...cps, organization },
          OrgUtils.getBillingSchedule(organization),
          numberOfFutureDeliveriesToGenerate
        )
      )
    ),
    v => v.date
  );
}

// user skipped deliveries are included (useful for "unskip" UI)
export function getScheduledDeliveriesForCpsGroupUntilDate<T extends IPurchaseState>(
  organization: IOrganization,
  customerPurchaseStates: T[],
  untilDate: Date
): [Date, ScheduledDeliveryGroupWithPurchSt<T>[]][] {
  return groupAndSortByDate(
    flatten(
      customerPurchaseStates.map(cps =>
        getPurchStFutureScheduledDeliveriesUntilDate(cps, OrgUtils.getBillingSchedule(organization), untilDate)
      )
    ),
    v => v.date
  );
}
