import {
  ButtonGroup,
  DatePicker,
  LegacyCard,
  LegacyStack,
  Text,
  TextField,
  useIndexResourceState,
} from "@shopify/polaris";
import { Box } from "@smartrr/shared/components/primitives";
import { IOrganization } from "@smartrr/shared/entities/Organization";
import { IPurchasable } from "@smartrr/shared/entities/Purchasable";
import { IPurchaseStateWithCustomerRelationship } from "@smartrr/shared/entities/PurchaseState";
import { useBoolean } from "@smartrr/shared/hooks/useBoolean";
import { CurrencyCode, SubscriptionContractSubscriptionStatus } from "@smartrr/shared/shopifyGraphQL/api";
import { getTotalDisplay } from "@smartrr/shared/utils/displayUtils";
import {
  convertNumberForFormatMoney,
  getSymbolFromCurrency,
  unformatMoney,
} from "@smartrr/shared/utils/formatMoney";
import { getCPSRemainingOrders, isCPSPrepaid, isUpcomingOrderCPSPrepaid } from "@smartrr/shared/utils/isPrepaid";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import ReactGA from "react-ga4";

import { ConfirmationWindow } from "@vendor-app/app/_sharedComponents/ConfirmationWindow";
import { DatePickerContainer } from "@vendor-app/app/_sharedComponents/DatePickerModal";
import { useToast } from "@vendor-app/app/_sharedComponents/Toast/ToastProvider";
import {
  SelectionType,
  TypedTableColumnValue,
  usePolarisTypedTable,
} from "@vendor-app/app/_sharedComponents/TypedTable/usePolarisTypedTable";
import { useTableHandlers } from "@vendor-app/app/_sharedComponents/TypedTable/useTableHandlers";
import {
  bulkSkipCustomerPurchaseState,
  bulkUnskipCustomerPurchaseState,
} from "@vendor-app/app/_state/actionCreators/customerPurchaseState";
import { useSmartrrVendorSelector, useSmartrrVendorDispatch } from "@vendor-app/app/_state/typedVendorReduxHooks";
import {
  HeaderContainer,
  IScheduledEventWithProjectedTotal,
  ProductsAutoComplete,
  getDeliveryAddress,
  getDeliveryMethodTitle,
  getPurcStScheduledDeliveriesFromDateWithTotal,
  getSelectedDatesFromFilter,
  getTimeFromDate,
  getVariantOptions,
  parseUpcomingOrdersForIndexTable,
  useSellingPlans,
} from "@vendor-app/app/AdminRoute/AdminSubscriptionDetailsRoute/libs";
import { isRowSelected } from "@vendor-app/utils/isIndexTableRowSelected";

import { sequentialWalker } from "@smartrr/shared/utils/sequentialWalker";
import {
  getLineItemPricingPolicyFromPricingPolicyInput,
  getPricingPolicyInputFromSmartrrSellingPlan,
} from "@smartrr/shared/utils/sharedTranslations/pricingPolicy";
import { compact } from "lodash";
import { useSmartrrFlags } from "@smartrr/shared/LaunchDarkly";
import { UpcomingOrdersRework } from "./rework";

export type CPSUpcomingOrdersTableColumnKeyType =
  | "transactionDate"
  | "totalEstimatedNet"
  | "items"
  | "address"
  | "deliveryMethod";

export type CPSUpcomingOrdersTableColumnType = {
  [key in CPSUpcomingOrdersTableColumnKeyType]: TypedTableColumnValue;
};

export type CPSUpcomingOrdersParsedDataType = {
  [key in CPSUpcomingOrdersTableColumnKeyType]: any;
};

export const upcomingOrdersColumns: CPSUpcomingOrdersTableColumnType = {
  transactionDate: {
    name: "Transaction date",
    paginatedValue: "transactionDate",
    filtering: true,
    sorting: true,
    disabled: false,
  },
  totalEstimatedNet: {
    name: "Total",
    paginatedValue: "totalEstimatedNet",
    filtering: true,
    sorting: true,
    disabled: false,
  },
  items: {
    name: "Items",
    paginatedValue: "items",
    filtering: true,
    sorting: true,
    disabled: false,
  },
  address: {
    name: "Shipping address",
    paginatedValue: "address",
    filtering: true,
    sorting: true,
    disabled: false,
  },
  deliveryMethod: {
    name: "Delivery method",
    paginatedValue: "deliveryMethod",
    filtering: true,
    sorting: true,
    disabled: false,
  },
};

interface UpcomingOrderProps {
  purchaseState: IPurchaseStateWithCustomerRelationship;
  activeOrg: IOrganization | null;
  onUnskip: (date: Date) => void;
}

export const UpcomingOrders = ({ purchaseState, activeOrg, onUnskip }: UpcomingOrderProps) => {
  const { upcomingOrderTableRework } = useSmartrrFlags();

  if (upcomingOrderTableRework) {
    return <UpcomingOrdersRework purchaseState={purchaseState} onUnskip={onUnskip} />;
  }

  return <UpcomingOrdersContent purchaseState={purchaseState} activeOrg={activeOrg} onUnskip={onUnskip} />;
};

const UpcomingOrdersContent = ({ purchaseState, activeOrg, onUnskip }: UpcomingOrderProps) => {
  const { addToast } = useToast();
  const { purchasables } = useSmartrrVendorSelector(state => state.purchasables);
  const dispatch = useSmartrrVendorDispatch();
  const specialReferralsVariantId = useSmartrrFlags().specialReferrals;
  const { Filter, Sorting, Columns, Table } = usePolarisTypedTable<CPSUpcomingOrdersTableColumnKeyType>();

  const [tableProps, tableHandlers] = useTableHandlers("transactionDate", "ASC", undefined, 5, "transactionDate");
  const [upcomingOrdersColumnsState, setUpcomingOrdersColumnsState] = useState(upcomingOrdersColumns);

  const [isSkipConfirmationOpen, openSkipConfirmation, closeSkipConfirmation] = useBoolean(false);
  const [isUnskipConfirmationOpen, openUnskipConfirmation, closeUnskipConfirmation] = useBoolean(false);

  const sellingPlanInfo = useSellingPlans();

  const FilterSelectorForUpcomingOrders = ({
    field,
    value,
    purchasables,
    setValue,
  }: {
    field: string;
    value: string | string[] | undefined;
    purchasables: IPurchasable[];
    setValue: (value: string | string[] | undefined) => void;
  }) => {
    const [{ month, year }, setDate] = useState({ month: new Date().getMonth(), year: new Date().getFullYear() });
    const handleMonthChange = useCallback((month, year) => setDate({ month, year }), []);

    switch (field as CPSUpcomingOrdersTableColumnKeyType) {
      case "transactionDate": {
        return (
          <DatePickerContainer>
            <DatePicker
              allowRange
              multiMonth
              month={month}
              year={year}
              onChange={({ start, end }) => {
                const filter = [getTimeFromDate(start).toString()];
                if (end) {
                  filter.push(getTimeFromDate(end).toString());
                }

                setValue(filter);
              }}
              onMonthChange={handleMonthChange}
              selected={getSelectedDatesFromFilter(value)}
            />
          </DatePickerContainer>
        );
      }
      case "totalEstimatedNet": {
        return (
          <LegacyStack vertical spacing="extraTight">
            <TextField
              autoComplete="off"
              label=""
              labelHidden
              prefix={getSymbolFromCurrency(activeOrg?.shopifyStoreData?.currency || "USD")}
              inputMode="numeric"
              min={0}
              value={Array.isArray(value) ? value.join(";") : value}
              onChange={value => {
                if (value && new RegExp(/^\d*\.?\d*$/).test(value) && +value > 0) {
                  setValue(value);
                  return;
                }
                setValue("");
              }}
            />
          </LegacyStack>
        );
      }
      case "items": {
        return (
          <LegacyStack vertical spacing="extraTight">
            <ProductsAutoComplete
              allowMultiple
              selected={(value as string[]) ?? []}
              options={getVariantOptions(purchasables)}
              textFieldProps={{
                label: Array.isArray(value) ? value.join(";") : value,
                value: Array.isArray(value) ? value.join(";") : value,
              }}
              onChange={value => setValue(value)}
              onSelect={value => setValue(value)}
            />
          </LegacyStack>
        );
      }
      case "address":
      case "deliveryMethod": {
        return (
          <LegacyStack vertical spacing="extraTight">
            <TextField
              autoComplete="off"
              label=""
              labelHidden
              inputMode="text"
              value={Array.isArray(value) ? value.join(";") : value}
              onChange={value => {
                setValue(value);
              }}
            />
          </LegacyStack>
        );
      }
    }
  };

  const sortByDate = (a: IScheduledEventWithProjectedTotal, b: IScheduledEventWithProjectedTotal) =>
    a.date.getTime() - b.date.getTime();
  const sortByTotal = (a: IScheduledEventWithProjectedTotal, b: IScheduledEventWithProjectedTotal) =>
    unformatMoney(a.totalForScheduleIdx, a.purchaseState.currency) -
    unformatMoney(b.totalForScheduleIdx, b.purchaseState.currency);
  const sortByItems = (a: IScheduledEventWithProjectedTotal, b: IScheduledEventWithProjectedTotal) =>
    a.purchaseState.stLineItems.filter(item => item.skdIdx == null || item.skdIdx === a.indexFromScheduleStart)
      .length -
    b.purchaseState.stLineItems.filter(item => item.skdIdx == null || item.skdIdx === b.indexFromScheduleStart)
      .length;
  const sortByDelivery = (a: IScheduledEventWithProjectedTotal, b: IScheduledEventWithProjectedTotal) =>
    (getDeliveryMethodTitle(a.purchaseState.deliveryMethod) || "").localeCompare(
      getDeliveryMethodTitle(b.purchaseState.deliveryMethod) || ""
    );
  const sortByAddress = (a: IScheduledEventWithProjectedTotal, b: IScheduledEventWithProjectedTotal) =>
    (getDeliveryAddress(a.purchaseState.deliveryMethod) || "").localeCompare(
      getDeliveryAddress(b.purchaseState.deliveryMethod) || ""
    );

  const isActive = purchaseState.purchaseStateStatus === SubscriptionContractSubscriptionStatus.Active;
  const isPaused = purchaseState.purchaseStateStatus === SubscriptionContractSubscriptionStatus.Paused;

  const maxEvents = useMemo(() => {
    /**
     * Get upcoming orders for canceled prepaid orders.
     */

    const isPrepaidInActive = isCPSPrepaid(purchaseState.schedule) && !isActive;

    const isPrepaidUnpaused = isCPSPrepaid(purchaseState.schedule) && !isPaused;

    if (isPrepaidInActive && isPrepaidUnpaused) {
      return getCPSRemainingOrders(purchaseState);
    }

    return purchaseState.schedule.maxCycles != null && purchaseState.schedule.paymentFrequencyMultiple != null
      ? purchaseState.schedule.maxCycles * purchaseState.schedule.paymentFrequencyMultiple -
          purchaseState.schedule.totalOrdersCount!
      : null;
  }, [purchaseState, purchaseState.updatedDate]);

  const cpsRemainingOrders = getCPSRemainingOrders(purchaseState);

  const upcomingOrders = useMemo((): IScheduledEventWithProjectedTotal[] => {
    const { orderByField, orderByValue, pageSize, pageNumber, filter } = tableProps;

    if (!isActive && !isPaused && cpsRemainingOrders === 0) {
      return [];
    }

    const preSequentialDeliveries: IScheduledEventWithProjectedTotal[] =
      getPurcStScheduledDeliveriesFromDateWithTotal(
        purchaseState,
        [purchaseState],
        activeOrg,
        maxEvents == null || maxEvents >= pageSize * pageNumber ? pageSize : maxEvents % pageSize,
        true
      ).map(d => ({ ...d, id: d.indexFromScheduleStart }));

    let deliveries: IScheduledEventWithProjectedTotal[] = compact(
      sequentialWalker(preSequentialDeliveries).map(delivery => {
        const currency: CurrencyCode = delivery.purchaseState.currency as CurrencyCode;

        const hasSubscriptionLineItems = delivery.purchaseState.stLineItems.some(st => {
          return !(st.isAddOn || st.isRedeemed || st.deletedAt);
        });

        if (!hasSubscriptionLineItems) {
          return null;
        }

        delivery.purchaseState.stLineItems = delivery.purchaseState.stLineItems.map(line => {
          const sellingPlan = sellingPlanInfo
            .getSellingPlanGroup(delivery.purchaseState.sellingPlanId)
            ?.sellingPlans.find(sp => sp.shopifyId === line.sellingPlanId);

          if (sellingPlan && line.needsPricingUpdate) {
            line.pricingPolicy = getLineItemPricingPolicyFromPricingPolicyInput(
              getPricingPolicyInputFromSmartrrSellingPlan(line.vnt, currency, sellingPlan),
              currency
            );
          }
          return line;
        });

        const totalForScheduleIdx = getTotalDisplay(
          delivery.paymentMultipleDueOnDate,
          delivery.purchaseState,
          delivery.indexFromScheduleStart,
          true,
          delivery.indexFromNext,
          { variantIdsToOmit: [specialReferralsVariantId] }
        );
        return {
          ...delivery,
          totalForScheduleIdx,
        };
      })
    );

    if (orderByField) {
      /**
       * Ignore default sort
       */
      if (!(orderByField === "transactionDate" && orderByValue === "ASC")) {
        ReactGA.event("upcoming_order_sort", {
          cps: purchaseState.id,
          field: orderByField,
        });
      }

      switch (orderByField) {
        case "transactionDate": {
          deliveries = deliveries.sort((a, b) => (orderByValue === "ASC" ? sortByDate(a, b) : sortByDate(b, a)));
          break;
        }
        case "totalEstimatedNet": {
          deliveries = deliveries.sort((a, b) =>
            orderByValue === "ASC" ? sortByTotal(a, b) : sortByTotal(b, a)
          );
          break;
        }
        case "items": {
          deliveries = deliveries.sort((a, b) =>
            orderByValue === "ASC" ? sortByItems(a, b) : sortByItems(b, a)
          );
          break;
        }
        case "deliveryMethod": {
          deliveries = deliveries.sort((a, b) =>
            orderByValue === "ASC" ? sortByDelivery(a, b) : sortByDelivery(b, a)
          );
          break;
        }
        case "address": {
          deliveries = deliveries.sort((a, b) =>
            orderByValue === "ASC" ? sortByAddress(a, b) : sortByAddress(b, a)
          );
          break;
        }
      }
    }

    if (filter) {
      const filterKeys = Object.keys(filter);

      if (filterKeys.length) {
        ReactGA.event("upcoming_order_filter", {
          cps: purchaseState.id,
          filterKeys: filterKeys.join(","),
        });
      }

      for (let index = 0; index < filterKeys.length; index++) {
        const filterKey = filterKeys[index];

        const filterValue = filter[filterKey];

        switch (filterKey) {
          case "transactionDate": {
            if (filterValue != null) {
              const { end: endDate, start: startDate } = getSelectedDatesFromFilter(filterValue)!;

              deliveries = deliveries.filter(event => {
                const eventDate = new Date(event.date.setHours(0, 0, 0, 0));

                if (!endDate) {
                  return eventDate >= startDate;
                }
                if (startDate === endDate) {
                  return eventDate === startDate;
                }
                return eventDate >= startDate && eventDate <= endDate;
              });
            }
            break;
          }
          case "totalEstimatedNet": {
            if (filterValue != null) {
              deliveries = deliveries.filter(
                event =>
                  convertNumberForFormatMoney(
                    unformatMoney(event.totalForScheduleIdx, event.purchaseState.currency),
                    event.purchaseState.currency
                  ) === +filterValue
              );
            }
            break;
          }
          case "items": {
            deliveries = deliveries.filter(event => {
              const lineItems = event.purchaseState.stLineItems
                .filter(item => item.skdIdx == null || item.skdIdx === event.indexFromScheduleStart)
                .map(item => item.vnt.shopifyId);

              return ((filterValue ?? []) as string[]).every(item => lineItems.includes(item));
            });
            break;
          }
          case "deliveryMethod": {
            if (filterValue != null && !Array.isArray(filterValue)) {
              deliveries = deliveries.filter(event =>
                getDeliveryMethodTitle(event.purchaseState.deliveryMethod)
                  ?.toLocaleLowerCase()
                  .includes(filterValue.toLocaleLowerCase())
              );
            }
            break;
          }

          case "address": {
            if (filterValue != null && !Array.isArray(filterValue)) {
              deliveries = deliveries.filter(event =>
                getDeliveryAddress(event.purchaseState.deliveryMethod)
                  ?.toLocaleLowerCase()
                  .includes(filterValue.toLocaleLowerCase())
              );
            }
            break;
          }
        }
      }
    }
    return deliveries;
  }, [purchaseState, activeOrg, tableProps]);

  const { selectedResources, allResourcesSelected, handleSelectionChange } = useIndexResourceState(
    upcomingOrders.map(order => ({ id: String(order.indexFromNext) }))
  );

  const onBulkSkip = async (dates: Date[]) => {
    if (purchaseState.id && purchaseState.custRel?.id) {
      await dispatch(
        bulkSkipCustomerPurchaseState({
          customerPurchaseStateId: purchaseState.id,
          customerRelationshipId: purchaseState.custRel.id,
          dates,
        })
      );
    }
  };

  const onBulkUnskip = async (dates: Date[]) => {
    if (purchaseState.id && purchaseState.custRel?.id) {
      await dispatch(
        bulkUnskipCustomerPurchaseState({
          customerPurchaseStateId: purchaseState.id,
          customerRelationshipId: purchaseState.custRel.id,
          dates,
        })
      );
    }
  };

  const getUpcomingOrderStatusLabel = (
    selectedResources: string[],
    upcomingOrders: IScheduledEventWithProjectedTotal[]
  ) => {
    if (
      upcomingOrders.findIndex(
        order => selectedResources.includes(String(order.indexFromNext)) && !order.isSkipped
      ) == -1
    ) {
      return "Unskip";
    }
    return "Skip";
  };
  const getUpcomingOrderStatusAction = (
    selectedResources: string[],
    upcomingOrders: IScheduledEventWithProjectedTotal[]
  ) => {
    if (isPaused) {
      addToast("Paused orders cannot be skipped");
    } else {
      if (
        upcomingOrders.findIndex(
          order => selectedResources.includes(String(order.indexFromNext)) && !order.isSkipped
        ) == -1
      ) {
        return openUnskipConfirmation();
      }
      return openSkipConfirmation();
    }
  };

  const getUpcomingOrderActionDisabled = (
    selectedResources: string[],
    upcomingOrders: IScheduledEventWithProjectedTotal[]
  ) => {
    return (
      upcomingOrders.findIndex(
        order =>
          selectedResources.includes(String(order.indexFromNext)) &&
          isUpcomingOrderCPSPrepaid(order.paymentMultipleDueOnDate) &&
          order.purchaseState.schedule.totalBillingCycles === 0
      ) !== -1
    );
  };

  const promotedBulkActions = [
    {
      content: getUpcomingOrderStatusLabel(selectedResources, upcomingOrders),
      onAction: () => getUpcomingOrderStatusAction(selectedResources, upcomingOrders),
      disabled: getUpcomingOrderActionDisabled(selectedResources, upcomingOrders),
    },
  ];

  useEffect(() => {
    if (maxEvents != null) {
      const numberOfEvents = Math.min(maxEvents, tableProps.pageSize);
      tableHandlers.setPageSize(numberOfEvents);
    }
  }, [maxEvents, tableProps.pageSize]);

  return (
    <React.Fragment>
      <LegacyCard.Section flush>
        <HeaderContainer>
          <div style={{ flex: 0.7 }}>
            <TextField
              id="subscription-details__upcoming-orders__show-next-textfield"
              autoComplete="off"
              label=""
              labelHidden
              min={1}
              type="number"
              inputMode="numeric"
              connectedLeft={
                <Box style={{ height: "100%" }} alignItems="center" pr={1}>
                  Show next
                </Box>
              }
              value={"" + tableProps.pageSize}
              onChange={value => {
                if (maxEvents != null && maxEvents < tableProps.pageSize * tableProps.pageNumber) {
                  return;
                }
                tableHandlers.setPageSize(Number(value));
              }}
            />
          </div>
          <ButtonGroup segmented>
            <Filter
              columns={upcomingOrdersColumnsState}
              filter={tableProps.filter}
              setFilter={tableHandlers.setFilter}
              filterComponent={(field, value, handleSetFilter) => (
                <FilterSelectorForUpcomingOrders
                  field={field}
                  value={value}
                  setValue={newValue => handleSetFilter("update", field, newValue)}
                  purchasables={purchasables}
                />
              )}
            />
            <Columns
              columns={upcomingOrdersColumnsState}
              addToast={addToast}
              setDisabledColumns={setUpcomingOrdersColumnsState}
            />

            <Sorting
              columns={upcomingOrdersColumnsState}
              orderByField={tableProps.orderByField}
              orderByValue={tableProps.orderByValue}
              setOrderByField={tableHandlers.setOrderByField}
              setOrderByValue={tableHandlers.setOrderByValue}
            />
          </ButtonGroup>
        </HeaderContainer>
        {isActive || (isPaused && purchaseState.unpauseDate) || getCPSRemainingOrders(purchaseState) ? (
          <Table
            selectable
            columns={upcomingOrdersColumnsState}
            data={parseUpcomingOrdersForIndexTable(upcomingOrders, onUnskip, activeOrg)}
            emptyStateText="No upcoming orders"
            resourceName={{
              singular: "order",
              plural: "orders",
            }}
            itemCount={tableProps.pageSize}
            promotedBulkActions={promotedBulkActions}
            selectedItemsCount={allResourcesSelected ? "All" : selectedResources.length}
            sortDirection={tableProps.orderByValue === "ASC" ? "ascending" : "descending"}
            sortable={Object.values(upcomingOrdersColumnsState)
              .filter(v => !v.disabled)
              .map(v => v.sorting)}
            sortColumnIndex={Object.values(upcomingOrdersColumnsState)
              .filter(value => !value.disabled)
              .findIndex(value => value.paginatedValue === tableProps.orderByField)}
            onSelectionChange={(selectionType, toggleType, selection) => {
              handleSelectionChange(selectionType, toggleType, selection);
            }}
            onSort={(headingIndex: number, direction: "ascending" | "descending") => {
              tableHandlers.setOrderByField(
                Object.values(upcomingOrdersColumnsState).filter(value => !value.disabled)[headingIndex]
                  .paginatedValue
              );
              tableHandlers.setOrderByValue(direction === "ascending" ? "ASC" : "DESC");
            }}
            isRowSelected={id => isRowSelected(id, selectedResources)}
          />
        ) : (
          <Box alignItems="center" justifyContent="center" p={2}>
            <Text
              variant="bodyMd"
              as="p"
              color="subdued"
              id="subscription-details__upcoming-orders__no-orders-text"
            >
              No upcoming orders.
            </Text>
          </Box>
        )}
      </LegacyCard.Section>
      <ConfirmationWindow
        title={`Are you sure you want to skip ${selectedResources.length}${
          allResourcesSelected ? "+" : ""
        } date(s)?`}
        onReject={closeSkipConfirmation}
        onConfirm={() => {
          onBulkSkip(
            selectedResources
              .map(orderId => upcomingOrders.find(order => String(order.indexFromNext) === orderId)?.date)
              .filter((o): o is any => o != null)
          );
          handleSelectionChange(SelectionType.All, false);
          closeSkipConfirmation();
        }}
        confirmationText="Yes"
        rejectionText="No"
        open={isSkipConfirmationOpen}
      >
        It might take a few minutes to process this request.
      </ConfirmationWindow>

      <ConfirmationWindow
        title={`Are you sure you want to unskip ${selectedResources.length}${
          allResourcesSelected ? "+" : ""
        } date(s)?`}
        onReject={closeUnskipConfirmation}
        onConfirm={() => {
          onBulkUnskip(
            selectedResources
              .map(orderId => upcomingOrders.find(order => String(order.indexFromNext) === orderId)?.date)
              .filter((o): o is any => o != null)
          );
          handleSelectionChange(SelectionType.All, false);
          closeUnskipConfirmation();
        }}
        confirmationText="Yes"
        rejectionText="No"
        open={isUnskipConfirmationOpen}
      >
        It might take a few minutes to process this request.
      </ConfirmationWindow>
    </React.Fragment>
  );
};
