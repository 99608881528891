import { ISchedule } from "@smartrr/shared/entities/Schedule";
import { RRuleAnchor } from "@smartrr/shared/entities/Schedule/utils/getCorrectedRRule";
import { calculateStartDateForNextDelivery } from "@smartrr/shared/entities/Schedule/utils/startDate";
import { isCPSPrepaid } from "@smartrr/shared/utils/isPrepaid";

export function getFuturePausedDeliveries(cps, organization, rruleManager, finalNumberOfDeliveriesToGenerate) {
  const oldOrderCycleIndex = cps.schedule.orderCycleIndex;
  const anchor = (cps.schedule as ISchedule & { anchor: RRuleAnchor }).anchor;
  const isPrepaid = isCPSPrepaid(cps.schedule);
  const hasGoneThroughFirstBilling = cps.schedule.totalBillingCycles !== 0 && cps.schedule.totalOrdersCount !== 0;

  const startDate = calculateStartDateForNextDelivery(
    new Date(cps.unpauseDate),
    organization,
    anchor,
    "ASAP", // These values are hardcoded as they are only based on the first order and not upcoming orders
    0
  );
  const updatedRRuleManager = rruleManager.offsetNextDelivery(startDate, {});

  // if subscription is prepaid, we need to make sure that the new schedule starts on the same order cycle as the original schedule.
  // also have to make sure billedDeliveryCount lines up properly
  // we also check for first billing since vendor portal created subs prior to their first billing won't need to have these fields set.
  if (isPrepaid && hasGoneThroughFirstBilling) {
    // if new schedule is created without orderCycleIndex, we need to set it back to the original value or else count will be off for prepaid orders.
    if (updatedRRuleManager.orderCycleIndex === null || updatedRRuleManager === undefined) {
      updatedRRuleManager.orderCycleIndex = oldOrderCycleIndex;
    }
    // we subtract an extra 1 since orderCycleIndex is 0 based and paymentFrequencyMultiple is 1 based
    updatedRRuleManager.billedDeliveriesCount =
      updatedRRuleManager.paymentFrequencyMultiple - oldOrderCycleIndex! - 1;
  }

  return updatedRRuleManager
    .getFutureScheduledDeliveries(finalNumberOfDeliveriesToGenerate, organization.setup?.billingDays)
    .map(delivery => ({
      ...delivery,
      indexFromScheduleStart: delivery.indexFromScheduleStart + cps.schedule.skdIdx,
      purchaseState: cps,
    }));
}
