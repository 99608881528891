import { IPurchaseState, IPurchaseStateWithCustomerRelationship } from "@smartrr/shared/entities/PurchaseState";
import {
  IPurchaseStateLineItem,
  IPurchaseStateLineItemPricingPolicy,
} from "@smartrr/shared/entities/PurchaseState/CustomerPurchaseLineItem";
import { ScheduledDeliveryGroupWithPurchSt } from "@smartrr/shared/entities/PurchaseState/scheduleUtils";
import { ScheduledEvent } from "@smartrr/shared/entities/Schedule/RRuleManager";
import { ISmartrrAddOnsConfigSellingPlan, ISmartrrSellingPlan } from "@smartrr/shared/entities/SellingPlanGroup";
import { CurrencyCode, SellingPlanPricingPolicyAdjustmentType } from "@smartrr/shared/shopifyGraphQL/api";
import { getSubtotalDisplay, getTotalDisplay } from "@smartrr/shared/utils/displayUtils";
import { isCPSPrepaid } from "@smartrr/shared/utils/isPrepaid";
import {
  getLineItemPricingPolicyFromPricingPolicyInput,
  getPricingPolicyInputFromSmartrrSellingPlan,
} from "@smartrr/shared/utils/sharedTranslations/pricingPolicy";
import { cloneDeep } from "lodash";

import type { SubscriptionDetailsLineItem } from "@smartrr/shared/interfaces/lineitem/api";
import { getComputedPriceBasedOnPolicy } from "../getComputedPriceBasedOnPolicy";

export const createPricingPolicyOnAddedProducts = ({
  customerPurchaseState,
  updatedLineItems,
  addonsSellingPlan,
  sellingPlan,
}: {
  updatedLineItems: SubscriptionDetailsLineItem[];
  customerPurchaseState: IPurchaseState;
  sellingPlan?: ISmartrrSellingPlan | undefined;
  addonsSellingPlan?: ISmartrrAddOnsConfigSellingPlan | undefined;
}) => {
  const currency = customerPurchaseState.currency as CurrencyCode;
  return [...updatedLineItems].map(updatedLineItem => {
    const lineItemInCPS = [...customerPurchaseState.stLineItems].find(
      lineItem => updatedLineItem.id === lineItem.id
    );
    let pricingPolicy: IPurchaseStateLineItemPricingPolicy | undefined = cloneDeep(lineItemInCPS?.pricingPolicy);

    if (!pricingPolicy) {
      if (!updatedLineItem.isAddOn && sellingPlan && updatedLineItem.vnt != null) {
        pricingPolicy = getLineItemPricingPolicyFromPricingPolicyInput(
          getPricingPolicyInputFromSmartrrSellingPlan(updatedLineItem?.vnt, currency, sellingPlan),
          currency
        );
      } else if (updatedLineItem.isAddOn && addonsSellingPlan && updatedLineItem.vnt != null) {
        pricingPolicy = getLineItemPricingPolicyFromPricingPolicyInput(
          getPricingPolicyInputFromSmartrrSellingPlan(updatedLineItem?.vnt, currency, addonsSellingPlan),
          currency
        );
      } else {
        pricingPolicy = {
          basePrice: {
            amount: updatedLineItem.finalPrice,
            currencyCode: currency,
          },
          cycleDiscounts: [
            {
              adjustmentType: SellingPlanPricingPolicyAdjustmentType.Percentage,
              adjustmentValue: {
                amount: "0",
                currencyCode: currency,
                percentage: 0,
              },
              afterCycle: 0,
              computedPrice: {
                amount: getComputedPriceBasedOnPolicy(
                  currency,
                  updatedLineItem.vnt,
                  updatedLineItem.isAddOn ? addonsSellingPlan : sellingPlan
                ),
                currencyCode: currency,
              },
            },
          ],
        };
      }
    }

    //if price was changed manually the pricing policy adjustment type should change to PRICE
    //to update the subtotal and total amounts correctly
    if (
      getComputedPriceBasedOnPolicy(
        currency,
        updatedLineItem.vnt,
        updatedLineItem.isAddOn ? addonsSellingPlan : sellingPlan
      ) !== (+updatedLineItem.finalPrice).toFixed(2)
    ) {
      pricingPolicy.cycleDiscounts[0].computedPrice.amount = updatedLineItem.finalPrice
        ? updatedLineItem.finalPrice
        : 0;
      pricingPolicy.cycleDiscounts[0].adjustmentType = SellingPlanPricingPolicyAdjustmentType.Price;
    }

    return {
      ...lineItemInCPS,
      pricingPolicy,
      id: updatedLineItem.id ?? lineItemInCPS?.id,
      discounts: lineItemInCPS?.discounts ?? [],
      basePrice: updatedLineItem.finalPrice,
      priceAfterDiscounts: updatedLineItem.finalPrice,
      priceCurrency: lineItemInCPS?.priceCurrency ?? customerPurchaseState.currency,
      quantity: updatedLineItem.quantity ?? lineItemInCPS?.quantity,
      isAddOn: updatedLineItem.isAddOn ?? lineItemInCPS?.isAddOn ?? false,
      vnt: updatedLineItem.vnt ?? lineItemInCPS?.vnt,
      shopifyUpdateDate: lineItemInCPS?.shopifyUpdateDate,
      createdDate: lineItemInCPS?.createdDate,
      updatedDate: lineItemInCPS?.updatedDate,
    };
  });
};

export const getNewLineItemWithPricingPolicyPrice = (
  newLineItem: SubscriptionDetailsLineItem,
  sellingPlan: ISmartrrSellingPlan | ISmartrrAddOnsConfigSellingPlan,
  currency: CurrencyCode
): SubscriptionDetailsLineItem => {
  return {
    ...newLineItem,
    finalPrice: getComputedPriceBasedOnPolicy(currency, newLineItem.vnt, sellingPlan),
  };
};

export const getSubscriptionSubtotal = ({
  customerPurchaseState,
  nextDelivery,
  skdIdx,
  updatedLineItems,
  addonsSellingPlan,
  sellingPlan,
}: {
  customerPurchaseState: IPurchaseStateWithCustomerRelationship;
  nextDelivery: ScheduledDeliveryGroupWithPurchSt<IPurchaseStateWithCustomerRelationship> | undefined;
  skdIdx: number | undefined;
  updatedLineItems: SubscriptionDetailsLineItem[];
  sellingPlan?: ISmartrrSellingPlan;
  addonsSellingPlan?: ISmartrrAddOnsConfigSellingPlan;
}) => {
  const isPrepaid = isCPSPrepaid(customerPurchaseState.schedule);
  const isPrepaidInBillingCycle =
    isPrepaid && nextDelivery?.paymentMultipleDueOnDate != null
      ? nextDelivery.paymentMultipleDueOnDate > 0
      : false;

  if (isPrepaid && !isPrepaidInBillingCycle) {
    return "Prepaid";
  }

  if (customerPurchaseState.purchaseStateStatus !== "ACTIVE") {
    return getSubtotalDisplay(
      customerPurchaseState.schedule.paymentFrequencyMultiple,
      {
        ...customerPurchaseState,
        stLineItems: createPricingPolicyOnAddedProducts({
          updatedLineItems: updatedLineItems.filter(i => i.action !== "delete"),
          customerPurchaseState,
          sellingPlan,
          addonsSellingPlan,
        }) as IPurchaseStateLineItem[],
      },
      skdIdx ?? 0
    );
  }

  if (customerPurchaseState.purchaseStateStatus === "ACTIVE" && nextDelivery) {
    return getSubtotalDisplay(
      Number.isFinite(nextDelivery.paymentMultipleDueOnDate) ? nextDelivery.paymentMultipleDueOnDate : 1,
      {
        ...customerPurchaseState,
        stLineItems: createPricingPolicyOnAddedProducts({
          updatedLineItems: updatedLineItems.filter(i => i.action !== "delete"),
          customerPurchaseState,
          sellingPlan,
          addonsSellingPlan,
        }) as IPurchaseStateLineItem[],
      },
      nextDelivery.indexFromScheduleStart || 0
    );
  }
};

export const getSubscriptionTotal = ({
  customerPurchaseState,
  nextDelivery,
  updatedLineItems,
  addonsSellingPlan,
  sellingPlan,
  nextActualDelivery,
}: {
  customerPurchaseState: IPurchaseStateWithCustomerRelationship;
  nextDelivery: ScheduledDeliveryGroupWithPurchSt<IPurchaseStateWithCustomerRelationship> | undefined;
  nextActualDelivery: ScheduledEvent | undefined | null;
  updatedLineItems: SubscriptionDetailsLineItem[];
  sellingPlan?: ISmartrrSellingPlan;
  addonsSellingPlan?: ISmartrrAddOnsConfigSellingPlan;
}) => {
  const isPrepaid = isCPSPrepaid(customerPurchaseState.schedule);
  const isPrepaidInBillingCycle =
    isPrepaid && nextDelivery?.paymentMultipleDueOnDate != null
      ? nextDelivery.paymentMultipleDueOnDate > 0
      : false;

  if (isPrepaid && !isPrepaidInBillingCycle) {
    return "Prepaid";
  }

  return getTotalDisplay(
    nextDelivery?.paymentMultipleDueOnDate ?? 1,
    {
      ...customerPurchaseState,
      stLineItems: createPricingPolicyOnAddedProducts({
        updatedLineItems: updatedLineItems.filter(i => i.action !== "delete"),
        customerPurchaseState,
        sellingPlan,
        addonsSellingPlan,
      }) as IPurchaseStateLineItem[],
    },
    nextDelivery?.isSkipped === true
      ? nextActualDelivery?.indexFromScheduleStart || 0
      : nextDelivery?.indexFromScheduleStart || 0,
    true,
    0,
    { evaluatePriceAdjustmentType: true }
  );
};
