import { Modal, OptionList, TextField } from "@shopify/polaris";
import React, { useMemo, useState } from "react";

import { updateSwapItemModal } from "@vendor-app/app/_state/actionCreators/subscriptionDetails";
import { useSmartrrVendorDispatch, useSmartrrVendorSelector } from "@vendor-app/app/_state/typedVendorReduxHooks";
import { FixedSearch, IPurchasableOption } from "@vendor-app/app/AdminRoute/AdminSubscriptionDetailsRoute/libs";

export const SwapItemModal = ({
  variantIdToReplace,
  onSwapItems,
  options,
}: {
  options: IPurchasableOption[];
  variantIdToReplace: string | undefined;
  onSwapItems: (replacedItemId: string | undefined, replacementItemId: string | undefined) => void;
}) => {
  const dispatch = useSmartrrVendorDispatch();
  const closeModal = () => dispatch(updateSwapItemModal(false));
  const showSwapItem = useSmartrrVendorSelector(state => state.subscriptionDetails.showSwapItem);

  const [modalReplacementVariantShopifyId, setModalReplacementVariantShopifyId] = useState<string | undefined>();
  const [filteredPurchasablesSearchText, setFilteredPurchasablesSearchText] = useState<string>("");

  const filteredOptions = useMemo(() => {
    return options.filter(v => {
      return v.title
        ?.toLocaleString()
        .toLocaleLowerCase()
        .includes(filteredPurchasablesSearchText.toLocaleLowerCase());
    });
  }, [filteredPurchasablesSearchText, options]);

  return (
    <Modal
      title="Swap item"
      open={showSwapItem}
      onClose={closeModal}
      primaryAction={{
        id: "subscription-details__confirm-swap-btn",
        content: "Confirm",
        disabled: !modalReplacementVariantShopifyId,
        onAction() {
          onSwapItems(variantIdToReplace, modalReplacementVariantShopifyId);
          closeModal();
        },
      }}
      secondaryActions={[
        {
          id: "subscription-details__cancel-swap-btn",
          content: "Cancel",
          onAction: closeModal,
        },
      ]}
    >
      <Modal.Section>
        <FixedSearch>
          <TextField
            id="subscription-details__search-products-for-swap"
            autoComplete="off"
            label=""
            placeholder="Search products"
            value={filteredPurchasablesSearchText}
            onChange={text => {
              setFilteredPurchasablesSearchText(text);
            }}
          />
        </FixedSearch>
      </Modal.Section>
      <Modal.Section flush>
        <OptionList
          id="subscription-details__products-for-swap-list"
          selected={modalReplacementVariantShopifyId ? [modalReplacementVariantShopifyId] : []}
          verticalAlign="center"
          options={filteredOptions}
          onChange={value => {
            setModalReplacementVariantShopifyId(value[0]);
          }}
        />
      </Modal.Section>
    </Modal>
  );
};
