import { IPurchaseStateWithCustomerRelationship } from "@smartrr/shared/entities/PurchaseState";
import { useCallback } from "react";

import { useToast } from "@vendor-app/app/_sharedComponents/Toast/ToastProvider";
import { bulkAddCustomerPurchaseStateLineItem } from "@vendor-app/app/_state/actionCreators/customerPurchaseState";
import { useSmartrrVendorDispatch } from "@vendor-app/app/_state/typedVendorReduxHooks";
import { ILineItemToAdd } from "@vendor-app/app/AdminRoute/AdminSubscriptionDetailsRoute/libs";

export const useAddItemsInBulk = (
  customerPurchaseState: IPurchaseStateWithCustomerRelationship,
  onError?: () => void,
  onCommitStart?: () => void,
  onCommitEnd?: () => void
) => {
  const dispatch = useSmartrrVendorDispatch();
  const { addToast } = useToast();

  return useCallback(
    async (lineItems: ILineItemToAdd[]) => {
      if (!(customerPurchaseState && customerPurchaseState.custRel)) {
        return;
      }

      onCommitStart && onCommitStart();
      await dispatch(
        bulkAddCustomerPurchaseStateLineItem({
          custRelId: customerPurchaseState.custRel.id,
          cpsId: customerPurchaseState.id,
          lineItems,
        })
      )
        .then(res => {
          if (res.type === "BULK_ADDED_CPS_LINE_ITEM") {
            addToast("Item(s) added");
          }
          if (res.type === "ERROR_BULK_ADDING_CPS_LINE_ITEM") {
            addToast("Error adding item(s)", true);
            onError && onError();
          }
        })
        .catch(() => {
          addToast("Error adding item(s)", true);
          onError && onError();
        });
      onCommitEnd && onCommitEnd();
    },
    [customerPurchaseState]
  );
};
