import { isArray } from "lodash";
import { DateTime } from "luxon";
import { ByWeekday, Frequency, RRule, RRuleSet } from "rrule";

import { RRuleAnchor } from "./getCorrectedRRule";
import { isSameDay, toUTCDate, toUTCDateLuxon } from "../../../utils/dateUtils";

export default class AnchorsRRule {
  private readonly weekDays: ByWeekday[];
  private readonly monthDay: number | undefined;
  private readonly months: number[];
  private readonly yearDays: number[];

  constructor(anchor: RRuleAnchor) {
    this.weekDays = getAnchorAsArray(anchor.byweekday);

    // getCorrectedRRule supports only single monthday anchor, so here we replicate this behavior
    const monthDays = getAnchorAsArray(anchor.bymonthday);
    this.monthDay = monthDays.length > 0 ? monthDays[0] : undefined;

    this.months = getAnchorAsArray(anchor.bymonth);
    this.yearDays = getAnchorAsArray(anchor.byyearday);
  }

  // Finds the next target date/anchor date and can then be used to generate schedule
  public getNextAnchorDate(initialDate: Date): Date {
    const anchorRRules = this.buildRRules(initialDate);

    // convert to proper date format
    const nextAnchor = anchorRRules.after(initialDate, false)!;
    const nextAnchorDate = toUTCDate(nextAnchor);

    // if the initial date is the same as the anchor, go to next anchor
    if (isSameDay(initialDate, nextAnchor)) {
      return anchorRRules.after(nextAnchorDate, false)!;
    }

    return nextAnchorDate;
  }

  public getPreviousAnchorDate(initialDate: Date): Date {
    const anchorRRules = this.buildRRules(initialDate);

    const prevAnchor = anchorRRules.before(initialDate, true)!;
    return toUTCDate(prevAnchor);
  }

  private buildRRules(initialDate: Date): RRuleSet {
    // Start date is a year back to allow previous anchors to show up
    const rruleStartDate = toUTCDateLuxon(initialDate).minus({ year: 1 }).set({ month: 1, day: 1 }).toJSDate();
    const rruleEndDate = toUTCDateLuxon(initialDate).plus({ year: 1 }).set({ month: 12, day: 31 }).toJSDate();

    if (this.weekDays.length > 0) {
      return this.buildWeekDayRRules(rruleStartDate, rruleEndDate);
    }

    if (this.monthDay !== undefined) {
      return this.buildMonthDayRRules(rruleStartDate, rruleEndDate);
    }

    if (this.yearDays.length > 0) {
      return this.buildYearDayRRules(rruleStartDate, rruleEndDate);
    }

    throw new Error(`No anchors were specified`);
  }

  private buildWeekDayRRules(startDate: Date, endDate: Date): RRuleSet {
    const rruleSet = new RRuleSet();

    rruleSet.rrule(
      new RRule({
        dtstart: startDate,
        until: endDate,
        freq: Frequency.DAILY,
        interval: 1,
        byweekday: this.weekDays,
      })
    );

    return rruleSet;
  }

  private buildMonthDayRRules(startDate: Date, endDate: Date): RRuleSet {
    const hasAllMonths = this.months.length === 0;

    const rruleSet = new RRuleSet();

    rruleSet.rrule(
      new RRule({
        dtstart: startDate,
        until: endDate,
        freq: Frequency.DAILY,
        interval: 1,
        bymonthday: this.monthDay,
        bymonth: hasAllMonths ? null : this.months,
      })
    );

    const hasFebruaryAnchor = hasAllMonths || this.months.includes(2);
    if (hasFebruaryAnchor && this.monthDay! > 28) {
      // Anchor that is larger than 28 should become Feb 29 on a leap year and Feb 28 on a regular year
      const endYear = endDate.getUTCFullYear();
      let yearStartDate = DateTime.fromJSDate(startDate);
      while (yearStartDate.year <= endYear) {
        if (yearStartDate.isInLeapYear) {
          const february29 = yearStartDate.set({ month: 2, day: 29 }).toJSDate();
          rruleSet.rdate(february29);
        } else {
          const february28 = yearStartDate.set({ month: 2, day: 28 }).toJSDate();
          rruleSet.rdate(february28);
        }

        yearStartDate = yearStartDate.plus({ year: 1 });
      }
    }

    if (this.monthDay! >= 31) {
      const allShortMonths = [4, 6, 9, 11]; // Months with 30 days (Apr, Jun, Sep, Nov)
      const shortMonthsInAnchors = hasAllMonths
        ? allShortMonths
        : allShortMonths.filter(month => this.months.includes(month));

      rruleSet.rrule(
        new RRule({
          dtstart: startDate,
          until: endDate,
          freq: Frequency.DAILY,
          interval: 1,
          bymonthday: 30,
          bymonth: shortMonthsInAnchors,
        })
      );
    }

    return rruleSet;
  }

  private buildYearDayRRules(startDate: Date, endDate: Date): RRuleSet {
    // On a leap year day numbers on Mar 1 (which has number 60) and after it should be increased by 1
    // in order to account for extra day Feb29 (which takes number 60 on a leap year)
    const leapYearDays = this.yearDays.map(yearDay => (yearDay < 60 ? yearDay : yearDay + 1));

    const rruleSet = new RRuleSet();

    // For yearday anchors we create a separate rrule for each year
    const endYear = endDate.getUTCFullYear();
    let yearStartDate = DateTime.fromJSDate(startDate);
    while (yearStartDate.year <= endYear) {
      const yearEndDate = yearStartDate.set({ month: 12, day: 31 });
      rruleSet.rrule(
        new RRule({
          dtstart: yearStartDate.toJSDate(),
          until: yearEndDate.toJSDate(),
          freq: Frequency.DAILY,
          interval: 1,
          byyearday: yearStartDate.isInLeapYear ? leapYearDays : this.yearDays,
        })
      );

      yearStartDate = yearStartDate.plus({ year: 1 });
    }

    return rruleSet;
  }
}

function getAnchorAsArray<T>(anchorProperty: T | T[] | undefined | null): T[] {
  if (anchorProperty === null || anchorProperty === undefined) {
    return [];
  }
  if (isArray(anchorProperty)) {
    return anchorProperty;
  }
  return [anchorProperty];
}
