import { useContext, useMemo } from "react";
import { UpcomingOrdersTableAccess } from "../../store";
import { parseUpcomingOrdersForIndexTable } from "@vendor-app/app/AdminRoute/AdminSubscriptionDetailsRoute/libs";
import { useActiveOrganizationSelector } from "@vendor-app/app/_state/reducers/organizations";
import { UpcomingOrderTableActionsContext } from "../../UpcomingOrderTableActionsContext";

export const useUpcomingOrderParsedData = (): ReturnType<typeof parseUpcomingOrdersForIndexTable> => {
  const upcomingOrders = UpcomingOrdersTableAccess.useData();
  const activeOrg = useActiveOrganizationSelector();
  const tableActions = useContext(UpcomingOrderTableActionsContext);

  return useMemo(() => {
    return parseUpcomingOrdersForIndexTable(upcomingOrders, tableActions.unskip, activeOrg);
  }, [upcomingOrders, activeOrg, tableActions]);
};
