import {
  parseUpcomingOrdersForIndexTable,
} from "@vendor-app/app/AdminRoute/AdminSubscriptionDetailsRoute/libs";
import React from "react";
import { IndexTable } from "@shopify/polaris";
import { UpcomingOrdersTableColumns } from "../../store/columns";

export namespace UpcomingOrdersTableCells {
  export type RowDataType = ReturnType<typeof parseUpcomingOrdersForIndexTable>[number];
  interface ColumnCellProps {
    row: RowDataType;
  }

  export const Cells: Record<UpcomingOrdersTableColumns.Column, (info: ColumnCellProps) => JSX.Element> = {
    id({ row }) {
      return <IndexTable.Cell>{row.id}</IndexTable.Cell>;
    },
    address({ row }) {
      return <IndexTable.Cell>{row.address}</IndexTable.Cell>;
    },
    deliveryMethod({ row }) {
      return <IndexTable.Cell>{row.deliveryMethod}</IndexTable.Cell>;
    },
    items({ row }) {
      return <IndexTable.Cell>{row.items}</IndexTable.Cell>;
    },
    totalEstimatedNet({ row }) {
      return <IndexTable.Cell>{row.totalEstimatedNet}</IndexTable.Cell>;
    },
    transactionDate({ row }) {
      return <IndexTable.Cell>{row.transactionDate}</IndexTable.Cell>;
    },
  };
}
