export enum ERROR_TYPE {
  ACCOUNT_LOCKED = "account_locked",
  CODE_USED = "code_used",
  CODE_EXPIRED = "code_expired",
  CANNOT_SEND_INVITE = "cannot_send_invite",
  NO_EMAIL = "no_email",
  NOT_ENABLED = "not_enabled",
  NOT_FOUND_USER = "not_found_user",
  NOT_FOUND_ORG = "not_found_org",
  NOT_FOUND_STORE = "not_found_store",
  SERVER_ERROR = "server_error",
  TOO_MANY_EMAIL = "too_many_email",
  TOO_MANY_OTP = "too_many_otp",
  TOO_MANY_ML = "too_many_ml",
  WRONG_EMAIL = "wrong_email",
  WRONG_CODE = "wrong_code",
  WRONG_OTP = "wrong_otp",
  WRONG_PARAMS = "wrong_params",
  FAILED_EMAIL = "failed_email",
}

export type RequestBodyParams = {} | undefined;
export declare type RequestQueryParams =
  | string
  | number
  | string[]
  | number[]
  | Date
  | {
      [Key: string]: RequestQueryParams | any;
    };

export interface WithSuccess<Type> {
  result: "success";
  data: Type;
}

export interface WithFailure {
  result: "failure";
  message: string;
}

export type WithResult<Type> = WithSuccess<Type> | WithFailure;

export type Nullable<T> = T | null;

export type OmitMethods<T> = { [K in keyof T as T[K] extends (...args: any[]) => any ? never : K]: T[K] };

export type CamelCase<S extends string> = S extends `${infer P1}-${infer P2}${infer P3}`
  ? `${Lowercase<P1>}${Uppercase<P2>}${CamelCase<P3>}`
  : Lowercase<S>;

export type KeysToCamelCase<T extends Record<string, boolean | string>> = {
  [K in keyof T as CamelCase<string & K>]: T[K];
};

export const SHIPPING_RECALCULATION_ERROR_CODES = {
  NO_DELIVERY_OPTIONS: "no_delivery_options",
  INVALID_PHONE_NUMBER: "invalid_phone_number",
  GENERIC_ERROR: "generic_error",
};
