import { RRule } from "rrule";

import { SubscriptionContractSubscriptionStatus } from "../../shopifyGraphQL/api";
import { OrgUtils } from "../Organization";
import { RRuleManager, ScheduledEvent } from "../Schedule/RRuleManager";

import { IPurchaseState } from "./index";

export interface ScheduledDeliveryGroupWithPurchSt<T extends IPurchaseState = IPurchaseState>
  extends ScheduledEvent {
  purchaseState: T;
}

export function processDeliveries<T extends IPurchaseState>(
  purchaseState: T,
  futureDeliveries: ScheduledDeliveryGroupWithPurchSt<T>[]
) {
  const futureDeliveriesLength = futureDeliveries.length;
  for (let i = 0; i < futureDeliveriesLength; i++) {
    futureDeliveries[i].purchaseState = purchaseState;
  }

  return futureDeliveries;
}

function getFutureDeliveries<T extends IPurchaseState>(
  purchaseState: T,
  vendorSchedule: RRule,
  numberOfFutureDeliveriesToGenerate: number,
  shouldIncludeNonActive: boolean
) {
  const billingDays = OrgUtils.getOrgBillingDays(purchaseState.organization);
  const considerPurchaseState =
    shouldIncludeNonActive || purchaseState.purchaseStateStatus === SubscriptionContractSubscriptionStatus.Active;

  const futureDeliveries: ScheduledDeliveryGroupWithPurchSt<T>[] =
    // make consistent for single purchase vs. not
    considerPurchaseState && purchaseState.schedule
      ? RRuleManager.fromJson(purchaseState.schedule, vendorSchedule)
          .getFutureScheduledDeliveries(numberOfFutureDeliveriesToGenerate, billingDays)
          .map(delivery => ({ ...delivery, purchaseState }))
      : [];

  return processDeliveries(purchaseState, futureDeliveries);
}

export function getPurchStFutureScheduledDeliveriesWithNonActive<T extends IPurchaseState>(
  purchaseState: T,
  vendorSchedule: RRule,
  numberOfFutureDeliveriesToGenerate: number
) {
  return getFutureDeliveries(purchaseState, vendorSchedule, numberOfFutureDeliveriesToGenerate, true);
}

export function getPurchStFutureScheduledDeliveries<T extends IPurchaseState>(
  purchaseState: T,
  vendorSchedule: RRule,
  numberOfFutureDeliveriesToGenerate: number
) {
  return getFutureDeliveries(purchaseState, vendorSchedule, numberOfFutureDeliveriesToGenerate, false);
}

export function getPurchStFutureActualDeliveries<T extends IPurchaseState>(
  purchaseState: T,
  vendorSchedule: RRule,
  numberOfFutureDeliveriesToGenerate: number
) {
  const billingDays = OrgUtils.getOrgBillingDays(purchaseState.organization);
  const futureDeliveries: ScheduledDeliveryGroupWithPurchSt<T>[] =
    // make consistent for single purchase vs. not
    purchaseState.purchaseStateStatus === SubscriptionContractSubscriptionStatus.Active && purchaseState.schedule
      ? RRuleManager.fromJson(purchaseState.schedule, vendorSchedule)
          .getFutureActualDeliveries(numberOfFutureDeliveriesToGenerate, billingDays)
          .map(delivery => ({ ...delivery, purchaseState }))
      : [];

  return processDeliveries(purchaseState, futureDeliveries);
}

export function getPurchStFutureScheduledDeliveriesUntilDate<T extends IPurchaseState>(
  purchaseState: T,
  vendorSchedule: RRule,
  untilDate: Date
) {
  const billingDays = OrgUtils.getOrgBillingDays(purchaseState.organization);
  const futureDeliveries: ScheduledDeliveryGroupWithPurchSt<T>[] =
    // make consistent for single purchase vs. not
    purchaseState.purchaseStateStatus === SubscriptionContractSubscriptionStatus.Active && purchaseState.schedule
      ? RRuleManager.fromJson(purchaseState.schedule, vendorSchedule)
          .getFutureScheduledDeliveriesUntilDate(untilDate, billingDays)
          .map(delivery => ({ ...delivery, purchaseState }))
      : [];

  return processDeliveries(purchaseState, futureDeliveries);
}
