import { Banner, Modal } from "@shopify/polaris";
import { Box, prefixClassName } from "@smartrr/shared/components/primitives";
import React from "react";

import { ConfirmationWindow } from "@vendor-app/app/_sharedComponents/ConfirmationWindow";
import { useSmartrrVendorSelector } from "@vendor-app/app/_state/typedVendorReduxHooks";

export type SubscriptionCancellationType = "Regular" | "Prepaid" | "PrepaidAll";

interface ICancelRecurringSubscriptionProps {
  onCloseModal: () => void;
  isOpen: boolean;
  onCancelSubscription: (type: SubscriptionCancellationType) => void;
}

const CancelRecurringSubscription = ({
  onCloseModal,
  isOpen,
  onCancelSubscription,
}: ICancelRecurringSubscriptionProps) => {
  return (
    <ConfirmationWindow
      title="Are you sure you want to cancel this subscription?"
      onReject={onCloseModal}
      onConfirm={() => onCancelSubscription("Regular")}
      confirmationText="Yes"
      rejectionText="No"
      open={isOpen}
    />
  );
};

interface ICancelPrepaidSubscriptionProps {
  onCloseModal: () => void;
  isOpen: boolean;
  onCancelSubscription: (type: SubscriptionCancellationType) => void;
}

const CancelPrepaidSubscription = ({
  onCloseModal,
  isOpen,
  onCancelSubscription,
}: ICancelPrepaidSubscriptionProps) => {
  return (
    <Modal
      title={"Cancel Subscription"}
      open={isOpen}
      onClose={onCloseModal}
      primaryAction={{
        content: "Continue",
        onAction: () => onCancelSubscription("Prepaid"),
      }}
      secondaryActions={[
        {
          content: "Cancel all",
          onAction: () => onCancelSubscription("PrepaidAll"),
          destructive: true,
          outline: true,
        },
      ]}
    >
      <Box className={prefixClassName("cancel-prepaid-subscription") + " removal-confirmation"} p={2}>
        <Banner status="info">
          <p>This subscription has been prepaid by the customer.</p>
          <br />
          <p>
            Pressing <b>Continue</b> will mark this subscription as canceled, but all orders that have already
            been prepaid will continue to go out as scheduled.
          </p>
          <br />
          <p>
            Pressing <b>Cancel all</b> will force cancel all orders that have been prepaid immediately, and the
            customer will not receive any scheduled orders.
          </p>
        </Banner>
      </Box>
    </Modal>
  );
};

export interface IHandleCancelSubscriptionProps {
  isPrepaid: boolean;
  onCloseModal: () => void;
  onCancelSubscription: (type: SubscriptionCancellationType) => void;
}

export const HandleCancelSubscription = ({
  onCloseModal,
  isPrepaid,
  onCancelSubscription,
}: IHandleCancelSubscriptionProps) => {
  const isModalOpen = useSmartrrVendorSelector(
    state => state.subscriptionDetails.showCancelSubscriptionConfirmation
  );

  if (isPrepaid) {
    return (
      <CancelPrepaidSubscription
        onCloseModal={onCloseModal}
        isOpen={isModalOpen}
        onCancelSubscription={onCancelSubscription}
      />
    );
  }
  return (
    <CancelRecurringSubscription
      onCloseModal={onCloseModal}
      isOpen={isModalOpen}
      onCancelSubscription={onCancelSubscription}
    />
  );
};
