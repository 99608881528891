import { IPurchaseStateWithCustomerRelationship } from "@smartrr/shared/entities/PurchaseState";
import { toViewLongDate } from "@smartrr/shared/utils/renderViewDate";
import { useCallback } from "react";

import { useToast } from "@vendor-app/app/_sharedComponents/Toast/ToastProvider";
import { putCustomerPurchaseStateSkip } from "@vendor-app/app/_state/actionCreators/customerPurchaseState";
import { useSmartrrVendorDispatch } from "@vendor-app/app/_state/typedVendorReduxHooks";

import { useNextDelivery } from "../../../../../libs/hooks/useNextDelivery";

export const useSkip = (
  customerPurchaseState: IPurchaseStateWithCustomerRelationship,

  onCommitStart?: () => void,
  onCommitEnd?: () => void
) => {
  const dispatch = useSmartrrVendorDispatch();
  const { addToast } = useToast();

  const { nextDelivery } = useNextDelivery(customerPurchaseState);

  return useCallback(
    async (date: Date) => {
      if (!(customerPurchaseState && customerPurchaseState.custRel && nextDelivery)) {
        return;
      }

      onCommitStart && onCommitStart();
      await dispatch(
        putCustomerPurchaseStateSkip({
          custRelId: customerPurchaseState.custRel.id,
          cpsId: customerPurchaseState.id,
          billingDate: date,
        })
      )
        .then(res => {
          if (res.type === "SKIPPED_CPS_NEXT_ORDER") {
            addToast(`Delivery ${toViewLongDate(date)} skipped`);
          }
          if (res.type === "ERROR_SKIPPING_CPS_NEXT_ORDER") {
            addToast("Error modifying subscription schedule", true);
          }
        })
        .catch(error => {
          addToast("Error modifying subscription schedule", true);
        });
      onCommitEnd && onCommitEnd();
    },
    [customerPurchaseState]
  );
};
