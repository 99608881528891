import { InteractiveTable } from "@vendor-app/app/_sharedComponents/InteractiveDataTable";
import { z } from "zod";

export namespace UpcomingOrdersTableSorts {
  export const types = ["transactionDate", "totalEstimatedNet", "items", "address", "deliveryMethod"] as const;
  export const typeSchema = z.enum(types);
  export type Sort = z.infer<typeof typeSchema>;

  interface SortInformation {
    name: Sort;
    title: string;
  }

  export const sorts: Record<Sort, SortInformation> = {
    address: {
      name: "address",
      title: "Shipping address",
    },
    deliveryMethod: {
      name: "deliveryMethod",
      title: "Delivery method",
    },
    items: {
      name: "items",
      title: "Items",
    },
    totalEstimatedNet: {
      name: "totalEstimatedNet",
      title: "Total",
    },
    transactionDate: {
      name: "transactionDate",
      title: "Transaction date",
    },
  };

  export type SelectedSort = InteractiveTable.SelectedSort & {
    sortType: Sort;
  };
}
