import { Icon, LegacyStack, Link, Popover, Select, Spinner, Text, Tooltip } from "@shopify/polaris";
import { InfoMinor, SelectMinor } from "@shopify/polaris-icons";
import { adminRoutePrefix } from "@smartrr/shared/constants";
import { SubscriptionContractSubscriptionStatus } from "@smartrr/shared/shopifyGraphQL/api";
import { shopifyGidToNumber } from "@smartrr/shared/utils/ensureShopifyGid";
import { isCPSInPrepaidBillingCycle, isCPSPrepaid } from "@smartrr/shared/utils/isPrepaid";
import { capitalize, groupBy } from "lodash";
import React, { useMemo, useState } from "react";

import { ISellingPlanSelect, NoSellingPlanDisclaimer, NoSellingPlanTooltip } from "../../../libs";
import { useSellingPlans } from "../../../libs/providers/SellingPlansProvider";
import { useUpdateSellingPlan } from "../SubscriptionTabs/hooks/useUpdateSellingPlan";

export const SellingPlanSelect = ({
  customerPurchaseState,
  rruleManager,
  sellingPlanOptions,
  sellingPlanGroup,
  areActionsDisabled,
  nextDelivery,
}: ISellingPlanSelect) => {
  const { externalSubscriptionStatus, schedule } = customerPurchaseState;
  const { sellingPlanGroupsLoading } = useSellingPlans();

  const [noSellingPlanOptionsIndicator, setNoSellingPlanOptionsIndicator] = useState<boolean>(false);
  const [sellingPlanUpdating, setSellingPlanUpdating] = useState(false);

  const onUpdateSellingPlan = useUpdateSellingPlan(
    customerPurchaseState,
    () => setSellingPlanUpdating(true),
    () => setSellingPlanUpdating(false)
  );

  const isPrepaid = useMemo(() => isCPSPrepaid(schedule), [schedule]);

  const isPrepaidInBillingCycle = useMemo(() => {
    return isCPSInPrepaidBillingCycle(isPrepaid, nextDelivery?.paymentMultipleDueOnDate);
  }, [isPrepaid, nextDelivery?.paymentMultipleDueOnDate]);

  const isMigrating = useMemo(() => {
    return externalSubscriptionStatus === SubscriptionContractSubscriptionStatus.Active;
  }, [externalSubscriptionStatus]);

  const sellingPlansByGroupId = useMemo(
    () => Object.entries(groupBy(sellingPlanOptions, "group_id")),
    [sellingPlanOptions]
  );

  const sellingPlanSelectorLabel = useMemo(
    () => (
      <Text variant="bodyMd" as="span" color="subdued">
        Current subscription program
      </Text>
    ),
    []
  );

  const frequencyString = useMemo(
    () =>
      rruleManager
        ? `${rruleManager.getScheduleConfig().deliveryFrequencyValue} ${
            rruleManager.getScheduleConfig().frequencyUnit
          }`
        : "",
    [rruleManager]
  );

  if (sellingPlanGroupsLoading) {
    return <Spinner />;
  }

  const cpsSellingPlan = sellingPlanGroup?.sellingPlans.find(
    plan => customerPurchaseState.sellingPlanId && plan.shopifyId === customerPurchaseState.sellingPlanId
  );

  const cpsSellingPlanOption = sellingPlansByGroupId.find(([, plans]) =>
    plans.find(
      plan =>
        customerPurchaseState.sellingPlanId && plan.id === shopifyGidToNumber(customerPurchaseState.sellingPlanId)
    )
  );

  const sellingPlanSelectOptions = sellingPlansByGroupId.map(([, plans]) => ({
    title: plans[0].group_name,
    options: plans.map(sellingPlanGroup => ({
      label: sellingPlanGroup.name,
      value: "" + shopifyGidToNumber(sellingPlanGroup.id),
      disabled: false,
    })),
  }));

  if (sellingPlanOptions.length === 0 && !cpsSellingPlanOption) {
    return (
      <NoSellingPlanDisclaimer id="subscription-details__no-products-selling-plan-select">
        <LegacyStack>
          <Popover
            active={noSellingPlanOptionsIndicator}
            activator={
              <div
                id="subscription-details__no-products-selling-plan__popover-activator"
                onMouseEnter={() => setNoSellingPlanOptionsIndicator(true)}
                onMouseLeave={() => setNoSellingPlanOptionsIndicator(false)}
              >
                <Icon source={InfoMinor} color="warning" />
              </div>
            }
            autofocusTarget="first-node"
            onClose={() => setNoSellingPlanOptionsIndicator(false)}
          >
            <NoSellingPlanTooltip
              onMouseLeave={() => setNoSellingPlanOptionsIndicator(false)}
              onMouseEnter={() => setNoSellingPlanOptionsIndicator(true)}
            >
              <span>The products in this subscription aren&apos;t part of the selected</span>{" "}
              <Link
                id="subscription-details__no-products-selling-plan__change-program-link"
                external
                url={`${adminRoutePrefix}/configure/plans/${shopifyGidToNumber(
                  sellingPlanGroup?.shopifyNumericId ?? ""
                )}`}
              >
                subscription program.
              </Link>{" "}
              <span>To make this change please add these products to the subscription program.</span>
            </NoSellingPlanTooltip>
          </Popover>
          <Text variant="bodyMd" as="span">
            {capitalize(frequencyString)}(s)
          </Text>
        </LegacyStack>
        <Icon source={SelectMinor} color="subdued" />
      </NoSellingPlanDisclaimer>
    );
  }

  if (areActionsDisabled) {
    return (
      <LegacyStack vertical distribution="trailing">
        {sellingPlanSelectorLabel}
        <span id="subscription-details__selling-plan-frequency-string">{capitalize(frequencyString)}(s)</span>
      </LegacyStack>
    );
  }

  if (!cpsSellingPlanOption) {
    return (
      <Tooltip
        dismissOnMouseOut
        content="The subscription plan associated with subscription has been deleted. Select a different one."
      >
        <Select
          id="subscription-details__deleted-selling-plan-select"
          labelInline
          label={<Icon source={InfoMinor} color="critical" />}
          disabled={areActionsDisabled || isMigrating || sellingPlanUpdating}
          placeholder="Select"
          onChange={value => onUpdateSellingPlan(Number(value))}
          options={[
            ...sellingPlanSelectOptions,
            {
              title: sellingPlanGroup?.name ?? "Unavailable",
              options: [
                {
                  label: cpsSellingPlan?.name ?? "Plan deleted",
                  value: customerPurchaseState.sellingPlanId
                    ? "" + shopifyGidToNumber(customerPurchaseState.sellingPlanId)
                    : "unavailable",
                  disabled: true,
                },
              ],
            },
          ]}
          value={
            customerPurchaseState.sellingPlanId
              ? "" + shopifyGidToNumber(customerPurchaseState.sellingPlanId)
              : "unavailable"
          }
        />
      </Tooltip>
    );
  }

  return (
    <Select
      id="subscription-details__selling-plan-select"
      labelInline
      label={cpsSellingPlanOption ? "" : <Icon source={InfoMinor} color="warning" />}
      disabled={
        areActionsDisabled || isMigrating || sellingPlanUpdating || (isPrepaid && !isPrepaidInBillingCycle)
      }
      placeholder="Select"
      onChange={value => onUpdateSellingPlan(Number(value))}
      options={sellingPlanSelectOptions}
      value={
        customerPurchaseState.sellingPlanId ? "" + shopifyGidToNumber(customerPurchaseState.sellingPlanId) : ""
      }
    />
  );
};
