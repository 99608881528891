import { IPurchaseStateWithCustomerRelationship } from "@smartrr/shared/entities/PurchaseState";
import { useMemo } from "react";

import { useActiveOrganizationSelector } from "@vendor-app/app/_state/reducers/organizations";
import {
  ScheduledDeliveryGroupWithPurchSt,
  getPurchStFutureActualDeliveries,
} from "@smartrr/shared/entities/PurchaseState/scheduleUtils";
import { OrgUtils } from "@smartrr/shared/entities/Organization";

export const useNextDelivery = (customerPurchaseState: IPurchaseStateWithCustomerRelationship) => {
  const organization = useActiveOrganizationSelector();

  const nextDelivery = useMemo(() => {
    if (!organization || !customerPurchaseState) {
      return undefined;
    }

    const firstDelivery: ScheduledDeliveryGroupWithPurchSt<IPurchaseStateWithCustomerRelationship> | undefined =
      getPurchStFutureActualDeliveries(
        { ...customerPurchaseState, organization },
        OrgUtils.getBillingSchedule(organization),
        1
      )?.[0];

    return firstDelivery;
  }, [organization, customerPurchaseState]);

  return { nextDelivery };
};
