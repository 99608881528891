export function parseSubscriptionShippingError(
  error: string,
  fallbackError = "Invalid shipping address",
  orgName?: string
) {
  if (isNoAvailableShippingError(error)) {
    if (!orgName) {
      return "No delivery options available for this address. Please enter a different address.";
    }
    return `${orgName} can't ship to this address. Please enter a different address.`;
  }

  return fallbackError ?? error;
}

export function parseSubscriptionShippingErrorForMerchant(
  error: string,
  fallbackError = "Invalid shipping address"
) {
  if (isNoAvailableShippingError(error)) {
    return "No shipping profiles available for this address. Please enter a different address.";
  }

  return fallbackError ?? error;
}

function isNoAvailableShippingError(error: string): boolean {
  return (
    error.includes("doesn't ship to this country") ||
    error.includes("No delivery option found for the given order") ||
    error.includes("No applicable delivery options")
  );
}
