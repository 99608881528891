import { InteractiveTable } from "@vendor-app/app/_sharedComponents/InteractiveDataTable";
import { z } from "zod";

export namespace UpcomingOrdersTableColumns {
  export const types = [
    "id",
    "transactionDate",
    "totalEstimatedNet",
    "items",
    "address",
    "deliveryMethod",
  ] as const;
  export const typeSchema = z.enum(types);
  export type Column = z.infer<typeof typeSchema>;

  interface ColumnInformation {
    name: Column;
    title: string;
  }

  export const columns: Record<Column, ColumnInformation> = {
    address: {
      name: "address",
      title: "Shipping address",
    },
    deliveryMethod: {
      name: "deliveryMethod",
      title: "Delivery method",
    },
    id: {
      name: "id",
      title: "Id",
    },
    items: {
      name: "items",
      title: "Items",
    },
    totalEstimatedNet: {
      name: "totalEstimatedNet",
      title: "Total",
    },
    transactionDate: {
      name: "transactionDate",
      title: "Transaction date",
    },
  };

  export type SelectedColumn = InteractiveTable.SelectedColumn & {
    columnType: Column;
  };

  export const shownTableColumns: Column[] = [
    "transactionDate",
    "totalEstimatedNet",
    "items",
    "address",
    "deliveryMethod",
  ];
}
