import { Dispatch, SetStateAction, useCallback, useState } from "react";

export const useAsyncContentLoader = <ContentType>(initial: ContentType) => {
  const [isLoading, setIsLoading] = useState(false);
  const [content, setContent] = useState(initial);

  const loadContent = useCallback(
    async (loadfn: (content: ContentType, setContent: Dispatch<SetStateAction<ContentType>>) => void) => {
      setIsLoading(true);
      await loadfn(content, setContent);
      setIsLoading(false);
    },
    [content]
  );

  return {
    isLoading,
    content,
    loadContent,
  };
};
