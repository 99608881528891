import { Button, Modal, OptionList, TextField } from "@shopify/polaris";
import React, { useCallback, useEffect, useMemo, useState } from "react";

import { updateAddItemModal } from "@vendor-app/app/_state/actionCreators/subscriptionDetails";
import { useSmartrrVendorDispatch, useSmartrrVendorSelector } from "@vendor-app/app/_state/typedVendorReduxHooks";
import { FixedSearch, IPurchasableOption } from "@vendor-app/app/AdminRoute/AdminSubscriptionDetailsRoute/libs";

export const AddItemModal = ({
  onEditProductsOrVariantsCb,
  typeOfAddItem,
  options,
  selected,
}: {
  selected: string[];
  options: IPurchasableOption[];
  typeOfAddItem: "addon" | "lineItem" | undefined;
  onEditProductsOrVariantsCb: (items: string[]) => void;
}) => {
  const dispatch = useSmartrrVendorDispatch();
  const showAddItem = useSmartrrVendorSelector(state => state.subscriptionDetails.showAddItem);
  const closeModal = () => dispatch(updateAddItemModal(false));

  const [modalSelectedVariantIds, setModalSelectedProductOrVariantIds] = useState<string[]>(selected);
  const [filteredPurchasablesSearchText, setFilteredPurchasablesSearchText] = useState<string>("");

  const filteredOptions = useMemo(() => {
    return options.filter(v => {
      return v.title
        .toLocaleString()
        .toLocaleLowerCase()
        .includes(filteredPurchasablesSearchText.toLocaleLowerCase());
    });
  }, [filteredPurchasablesSearchText, options]);

  const selectAllProducts = useCallback(() => {
    if (modalSelectedVariantIds.length === options.length) {
      setModalSelectedProductOrVariantIds([]);
    } else {
      setModalSelectedProductOrVariantIds(options.map(p => p.value));
    }
  }, [options, modalSelectedVariantIds]);

  useEffect(() => {
    setModalSelectedProductOrVariantIds(selected);
  }, []);

  return (
    <Modal
      limitHeight
      open={showAddItem}
      onClose={closeModal}
      title={typeOfAddItem === "addon" ? "Adding one-time item" : "Adding recurring item"}
      primaryAction={{
        id: "subscription-details__confirm-edit-products-modal-btn",
        content: "Confirm",
        onAction() {
          onEditProductsOrVariantsCb(modalSelectedVariantIds);
          closeModal();
        },
      }}
      secondaryActions={[
        {
          id: "subscription-details__cancel-edit-products-modal-btn",
          content: "Cancel",
          onAction: closeModal,
        },
      ]}
    >
      <Modal.Section>
        <FixedSearch>
          <TextField
            id="subscription-details__search-products-modal"
            autoComplete="off"
            label=""
            placeholder="Search products"
            value={filteredPurchasablesSearchText}
            onChange={text => {
              setFilteredPurchasablesSearchText(text);
            }}
            suffix={
              !filteredPurchasablesSearchText && (
                <Button onClick={selectAllProducts} plain>
                  {modalSelectedVariantIds.length === filteredOptions.length ? "deselect all" : "or select all"}
                </Button>
              )
            }
          />
        </FixedSearch>
      </Modal.Section>
      <Modal.Section flush>
        <OptionList
          allowMultiple
          verticalAlign="center"
          selected={modalSelectedVariantIds}
          options={filteredOptions}
          onChange={setModalSelectedProductOrVariantIds}
        />
      </Modal.Section>
    </Modal>
  );
};
