import { DateTime } from "luxon";
import { Frequency } from "rrule";

import { IOrganizationBillingTime, OrgUtils } from "@smartrr/shared/entities/Organization";
import { getDaysBetweenDates, isSameDay, nowUTC } from "@smartrr/shared/utils/dateUtils";

import AnchorsRRule from "./anchorRRule";
import { RRuleAnchor, getCorrectedRRule } from "./getCorrectedRRule";

function hasAnchors(anchor: RRuleAnchor) {
  return Object.keys(anchor).length > 0;
}

/**
 * Calculates schedule start date for a new contract.
 *
 * @param initialDate - date&time (UTC) when the origin order was processed. In case of imported subscription
 * it may be a future date, but for imported subscription we are also doing extra adjustments after schedule
 * calculation, so we don't add special logic to handle it here.
 * @param organization - billing settings of merchant's organization.
 * @param anchor - anchor from selling plan converted into RRule format. If no anchor, should be empty object {}.
 * @param preAnchorBehavior - pre-anchor behavior from selling plan. If no selling plan, can be null or undefined.
 * @param cutoff - pre-anchor cutoff from selling plan. If no selling plan, can be null or undefined.
 * @returns date&time on which subscription schedule should start.
 */
export function calculateStartDateForNewContract(
  initialDate: Date,
  organization: IOrganizationBillingTime,
  anchor: RRuleAnchor,
  preAnchorBehavior: "ASAP" | "NEXT" | undefined | null,
  cutoff: number | undefined | null,
  isUpdatingSellingPlan = false
): Date {
  const initialBillingDateTime = OrgUtils.getVendorBillingDateTimeOnDay(initialDate, organization);

  // If the delivery policy has not any anchors, then schedule starts from the date of
  // the initial order and goes from there in a regular intervals
  if (!hasAnchors(anchor)) {
    return initialBillingDateTime;
  }

  // If the delivery policy has anchors, then schedule should start either on previous anchor (in respect to
  // the initial order) or on the next anchor, depending on cutoff settings
  const anchorRRule = new AnchorsRRule(anchor);
  let initialBillingDate = initialBillingDateTime;

  // if it is a recently created contract we need to first calculate the fulfillment date for the first other
  // and from there we need to calculate the start date for the next order
  if (!isUpdatingSellingPlan && preAnchorBehavior === "NEXT") {
    initialBillingDate = findNextAnchorDateAfterCutoff(initialBillingDateTime, anchorRRule, cutoff ?? 0);
  }
  return calculateAnchoredStartDate(initialBillingDate, anchorRRule, preAnchorBehavior, cutoff);
}

/**
 * Calculates schedule start date when we change next delivery date for subscription.
 * Calculation logic makes sure that the start date is in the future, so that the next delivery doesn't get
 * lost in the past time. If selling plan has anchors, then makes sure that start date is on anchor.
 *
 * @param deliveryDate - date&time (UTC) that was selected as next delivery date.
 * @param organization - billing settings of merchant's organization.
 * @param anchor - anchor from selling plan converted into RRule format. If no anchor, should be empty object {}.
 * @param preAnchorBehavior - pre-anchor behavior from selling plan. If no selling plan, can be null or undefined.
 * @param cutoff - pre-anchor cutoff from selling plan. If no selling plan, can be null or undefined.
 * @returns date&time on which subscription schedule should start.
 */
export function calculateStartDateForNextDelivery(
  deliveryDate: Date,
  organization: IOrganizationBillingTime,
  anchor: RRuleAnchor,
  preAnchorBehavior: "ASAP" | "NEXT" | undefined | null,
  cutoff: number | undefined | null
): Date {
  const now = nowUTC();
  const todayBillingTime = OrgUtils.getVendorBillingDateTimeOnDay(now, organization);
  const deliveryDateWithTime = OrgUtils.getVendorBillingDateTimeOnDay(deliveryDate, organization);

  // Without anchors schedule starts at billing time on the delivery date, unless it is in the past
  if (!hasAnchors(anchor)) {
    // If billing time on delivery date is in the future, then use it
    if (deliveryDateWithTime > now) {
      return deliveryDateWithTime;
    }

    // If billing time for today didn't occur yet, then use it instead of delivery date
    if (todayBillingTime > now) {
      return todayBillingTime;
    }

    // If billing time for today already occurred, then use tomorrow billing time
    // NOTE: we do not just add 1 day to todayBillingTime, because it won't account for daylight saving time
    const tomorrow = DateTime.fromJSDate(now).plus({ day: 1 }).toJSDate();
    return OrgUtils.getVendorBillingDateTimeOnDay(tomorrow, organization);
  }

  // If delivery date is before first anchor from today, then we enforce cutoff rules, however:
  // 1. Cutoff should be accounted for only if today (and not delivery date!) is within cutoff period.
  // 2. If resulting start date is in the past anchor we push it to the future anchor, so that delivery
  //    does not disappear in the past history.
  const anchorRRule = new AnchorsRRule(anchor);
  const nextAnchorFromToday = anchorRRule.getNextAnchorDate(todayBillingTime);
  if (deliveryDateWithTime <= nextAnchorFromToday) {
    let startDate = calculateAnchoredStartDate(todayBillingTime, anchorRRule, preAnchorBehavior, cutoff);
    if (startDate < now) {
      startDate = nextAnchorFromToday;
    }
    return startDate;
  }

  // If delivery date is exactly on anchor, then return anchor
  // Here we use that getPreviousAnchorDate returns same day anchor for specified input
  const prevAnchor = anchorRRule.getPreviousAnchorDate(deliveryDateWithTime);
  if (isSameDay(deliveryDateWithTime, prevAnchor)) {
    return prevAnchor;
  }

  // Otherwise we return next anchor from the delivery date
  return anchorRRule.getNextAnchorDate(deliveryDateWithTime);
}

/**
 * Calculates schedule start date when we switch subscription from one selling plan to another.
 * Calculation logic makes sure that the start date is in the future. Either on the future anchor or
 * interval * frequency away from today.
 *
 * @param now - current time (UTC).
 * @param organization - billing settings of merchant's organization.
 * @param frequency - delivery frequency in RRule format.
 * @param interval - delivery interval.
 * @param anchor - anchor from selling plan converted into RRule format. If no anchor, should be empty object {}.
 * @param preAnchorBehavior - pre-anchor behavior from selling plan. If no selling plan, can be null or undefined.
 * @param cutoff - pre-anchor cutoff from selling plan. If no selling plan, can be null or undefined.
 * @returns date&time on which subscription schedule should start.
 */
export function calculateStartDateForNewSellingPlan(
  now: Date,
  organization: IOrganizationBillingTime,
  frequency: Frequency,
  interval: number,
  anchor: RRuleAnchor,
  preAnchorBehavior: "ASAP" | "NEXT" | undefined | null,
  cutoff: number | undefined | null
): Date {
  // We start as if we had a new contract with the origin order placed today
  let startDate = calculateStartDateForNewContract(now, organization, anchor, preAnchorBehavior, cutoff, true);

  // And then we push start date to the future so that customer does not get immediately billed
  const todayBillingTime = OrgUtils.getVendorBillingDateTimeOnDay(now, organization);
  if (startDate <= todayBillingTime) {
    if (hasAnchors(anchor)) {
      // If start date precedes today time because of anchors, then push start date to next anchor
      const anchorRRule = new AnchorsRRule(anchor);
      startDate = anchorRRule.getNextAnchorDate(todayBillingTime);
    } else {
      const rrule = getCorrectedRRule(
        {
          dtstart: todayBillingTime,
          freq: frequency,
          interval,
        },
        anchor
      );
      const nextOccurrence = rrule.after(todayBillingTime)!;

      // If start date is either January 30th or 31st and next occurrence is February 29th, start it at first rrule occurrence
      if (
        (startDate.getDate() === 30 || startDate.getDate() === 31) &&
        startDate.getMonth() === 0 &&
        nextOccurrence.getDate() === 29 &&
        nextOccurrence.getMonth() === 1
      ) {
        startDate = rrule.all()[0];
      } else {
        startDate = nextOccurrence;
      }
    }
  }

  return startDate;
}

function calculateAnchoredStartDate(
  initialDate: Date,
  anchorsRRule: AnchorsRRule,
  preAnchorBehavior: "ASAP" | "NEXT" | undefined | null,
  cutoff: number | undefined | null
): Date {
  preAnchorBehavior = preAnchorBehavior ?? "ASAP"; // By default behavior is ASAP
  cutoff = cutoff ?? 0; // By default cutoff is 0

  const nextAnchorDate = anchorsRRule.getNextAnchorDate(initialDate);
  const prevAnchorDate = anchorsRRule.getPreviousAnchorDate(initialDate);

  // Determine whether we are within cutoff period before the next anchor
  const daysTillNextAnchor = getDaysBetweenDates(initialDate, nextAnchorDate);
  const withinCutoff = cutoff && daysTillNextAnchor < cutoff;

  switch (preAnchorBehavior) {
    case "ASAP": {
      return withinCutoff ? anchorsRRule.getNextAnchorDate(nextAnchorDate) : prevAnchorDate;
    }

    case "NEXT": {
      if (!withinCutoff) {
        return nextAnchorDate;
      }

      return findNextAnchorDateAfterCutoff(initialDate, anchorsRRule, cutoff);
    }

    default: {
      const exhaustiveCheck: never = preAnchorBehavior;
      return exhaustiveCheck;
    }
  }
}

function findNextAnchorDateAfterCutoff(date: Date, anchorsRRule: AnchorsRRule, cutoff: number): Date {
  let nextAnchor = anchorsRRule.getNextAnchorDate(date);
  while (getDaysBetweenDates(date, nextAnchor) < cutoff) {
    nextAnchor = anchorsRRule.getNextAnchorDate(nextAnchor);
  }
  return nextAnchor;
}
