import {
  ActionList,
  ActionListItemDescriptor,
  Button,
  LegacyCard,
  LegacyStack,
  Popover,
  Text,
} from "@shopify/polaris";
import { CaretDownMinor } from "@shopify/polaris-icons";
import { IOrganization } from "@smartrr/shared/entities/Organization";
import { IPurchaseStateWithCustomerRelationship } from "@smartrr/shared/entities/PurchaseState";
import { RRuleManager } from "@smartrr/shared/entities/Schedule/RRuleManager";
import { ISmartrrSellingPlanGroup } from "@smartrr/shared/entities/SellingPlanGroup";
import { SubscriptionContractSubscriptionStatus } from "@smartrr/shared/shopifyGraphQL/api";
import { areDatesOnSameDay } from "@smartrr/shared/utils/dateUtils";
import {
  isCPSPrepaid,
  isCPSRecentlyMigratedPrepaid,
  isLastBillingCycleOfCPS,
} from "@smartrr/shared/utils/isPrepaid";
import { getSellingPlansForVariants } from "@smartrr/shared/utils/sellingPlans";
import {
  DateConfirmationModal,
  useDateConfirmationModalStore,
} from "@vendor-app/app/_sharedComponents/DateConfirmationModal";
import { useActiveOrganizationSelector } from "@vendor-app/app/_state/reducers/organizations";
import { useVendorPortalVariantToPurchasableMap } from "@vendor-app/app/_state/reducers/purchasables";
import { useDisabledDatesByBillingDays } from "@vendor-app/app/AdminRoute/AdminSubscriptionDetailsRoute/hooks/useDisabledDatesByBillingDays";
import { useSendOrderNowAvailability } from "@vendor-app/utils/useSendOrderNowAvailability";
import React, { useCallback, useMemo, useState } from "react";

import {
  SellingPlanSelectorContainer,
  getFormattedUpcomingDateForSubDetails,
  getUpcomingTransactionDateAsString,
} from "../../../libs";
import { useNextDelivery } from "../../../libs/hooks/useNextDelivery";
import { useSellingPlans } from "../../../libs/providers/SellingPlansProvider";
import { SetNextOrderDateModal, ShowBundleContentModal } from "../../modals";
import { useSetNextOrderModalStore } from "../../modals/SetNextOrderDateModal/useSetNextOrderModalStore";
import { SellingPlanSelect } from "../SellingPlanSelect";
import { useSetNextDate } from "../SubscriptionTabs/hooks/useSetNextDate";
import { useSkip } from "../SubscriptionTabs/hooks/useSkip";
import { purchaseStateContainsAnchor } from "@smartrr/shared/utils/anchors";

interface INextOrderProps {
  organization: IOrganization | null;
  sellingPlanGroup: ISmartrrSellingPlanGroup | undefined;
  areActionsDisabled: boolean;
  rruleManager: RRuleManager | undefined;
  customerPurchaseState: IPurchaseStateWithCustomerRelationship;
}

export const NextOrderInfo = ({
  customerPurchaseState,
  areActionsDisabled,
  rruleManager,
  sellingPlanGroup,
}: INextOrderProps) => {
  const { openModal: openSetNextOrderDateModal } = useSetNextOrderModalStore();
  const { openModal: openDateConfirmationModal } = useDateConfirmationModalStore();
  const { externalSubscriptionStatus, schedule, purchaseStateStatus } = customerPurchaseState;
  const variantToPurchasableMap = useVendorPortalVariantToPurchasableMap();
  const organization = useActiveOrganizationSelector();
  const { sellingPlanGroupsByProductId, sellingPlanGroupsByVariantId } = useSellingPlans();
  const { nextDelivery } = useNextDelivery(customerPurchaseState);

  const openSetNextOrderModal = () => openSetNextOrderDateModal({});

  const [isOpenNextOrderOptions, setOpenNextOrderOptions] = useState(false);

  const sendNowAvailable = useSendOrderNowAvailability(nextDelivery ?? undefined);

  const omittedDates = useMemo(() => {
    if (customerPurchaseState.subProperties?.sendNow) {
      return [customerPurchaseState.nextBillingDate, customerPurchaseState.nextOrderDate];
    }
    return [];
  }, [customerPurchaseState.nextBillingDate, customerPurchaseState.nextOrderDate]);

  const disabledDatesFromBilling = useDisabledDatesByBillingDays(
    customerPurchaseState.nextBillingDate,
    omittedDates
  );

  const disabledDatesFromDelivery = useDisabledDatesByBillingDays(
    customerPurchaseState.unpauseDate ?? customerPurchaseState.nextOrderDate,
    omittedDates
  );

  const onSkip = useSkip(customerPurchaseState);
  const onSetNextDate = useSetNextDate(customerPurchaseState);

  const isPrepaid = useMemo(() => isCPSPrepaid(schedule), [schedule]);
  const isMigrating = useMemo(
    () => externalSubscriptionStatus === SubscriptionContractSubscriptionStatus.Active,
    []
  );
  const isRecentlyMigratedPrepaid = useMemo(
    () => isCPSRecentlyMigratedPrepaid(customerPurchaseState),
    [customerPurchaseState]
  );

  const onNextDateSelect = useCallback(async (date: Date) => {
    await onSetNextDate(date).then(() => {
      const currentDate = new Date();

      if (areDatesOnSameDay(date, currentDate)) {
        openDateConfirmationModal({ sendNowDate: date });
      }
    });
  }, []);

  const isCPSOnLastBillingCycle = useMemo(() => {
    return isLastBillingCycleOfCPS(schedule);
  }, [schedule]);

  const sellingPlanOptions = useMemo(
    () =>
      getSellingPlansForVariants(
        customerPurchaseState.stLineItems
          .filter(lineItem => !!lineItem.vnt && !lineItem.isAddOn)
          .map(lineItem => lineItem.vnt!)
          .filter(Boolean),
        sellingPlanGroupsByVariantId,
        sellingPlanGroupsByProductId,
        variantToPurchasableMap
      ),
    [customerPurchaseState, sellingPlanGroupsByVariantId, sellingPlanGroupsByProductId, variantToPurchasableMap]
  );

  const hasAnchors = purchaseStateContainsAnchor(customerPurchaseState);

  const nextBillingDateFormatted = useMemo(() => {
    return getUpcomingTransactionDateAsString(customerPurchaseState, organization, disabledDatesFromBilling);
  }, [organization, customerPurchaseState, disabledDatesFromBilling]);

  const nextOrderDateFormatted = useMemo(() => {
    return getFormattedUpcomingDateForSubDetails(customerPurchaseState, organization, disabledDatesFromDelivery);
  }, [organization, customerPurchaseState, disabledDatesFromDelivery]);

  const optionsList = useMemo(() => {
    const optionsList: ActionListItemDescriptor[] = [];

    if (nextDelivery && !isRecentlyMigratedPrepaid && !areActionsDisabled) {
      optionsList.push({
        id: "subscription-details__skip-action",
        content: "Skip",
        onAction: () => onSkip(nextDelivery.date),
      });
    }

    if (nextDelivery && !areActionsDisabled) {
      optionsList.push({
        id: "subscription-details__set-next-order-date-action",
        content: "Set next order date",
        onAction: openSetNextOrderModal,
        disabled: isMigrating || purchaseStateStatus !== SubscriptionContractSubscriptionStatus.Active,
      });
    }
    return optionsList;
  }, [
    nextDelivery,
    sendNowAvailable,
    purchaseStateStatus,
    isMigrating,
    isRecentlyMigratedPrepaid,
    areActionsDisabled,
  ]);

  return (
    <LegacyCard.Section>
      <LegacyStack distribution="equalSpacing">
        <LegacyStack vertical spacing="extraTight">
          <Text variant="headingMd" as="p" id="subscription-details__next-order-date">
            Next order: {nextOrderDateFormatted}
          </Text>
          {!isPrepaid || !isCPSOnLastBillingCycle ? (
            <Text variant="bodyMd" as="p" color="subdued" id="subscription-details__next-billing-date">
              Next bill: <span>{nextBillingDateFormatted}</span>
            </Text>
          ) : null}
        </LegacyStack>
        {optionsList.length ? (
          <Popover
            active={isOpenNextOrderOptions}
            onClose={() => setOpenNextOrderOptions(false)}
            activator={
              <Button
                id="subscription-details__next-order-options"
                disabled={areActionsDisabled}
                icon={CaretDownMinor}
                size="slim"
                onClick={() => setOpenNextOrderOptions(a => !a)}
              >
                Options
              </Button>
            }
          >
            <ActionList items={optionsList} onActionAnyItem={() => setOpenNextOrderOptions(false)} />
          </Popover>
        ) : null}
      </LegacyStack>

      <LegacyStack>
        <SellingPlanSelectorContainer>
          <SellingPlanSelect
            nextDelivery={nextDelivery}
            areActionsDisabled={areActionsDisabled}
            customerPurchaseState={customerPurchaseState}
            rruleManager={rruleManager}
            sellingPlanOptions={sellingPlanOptions}
            sellingPlanGroup={sellingPlanGroup}
          />
        </SellingPlanSelectorContainer>
      </LegacyStack>

      {/* Set next billing date for cps */}
      {customerPurchaseState.nextBillingDate ? (
        <SetNextOrderDateModal
          initialISODate={customerPurchaseState.nextBillingDate}
          onConfirm={onNextDateSelect}
          allowSendNow={!hasAnchors}
        />
      ) : (
        <React.Fragment />
      )}

      {/* Confirm if upcoming delivery will be skipped */}
      {nextDelivery ? (
        <DateConfirmationModal customerPurchaseState={customerPurchaseState} onConfirm={onSkip} />
      ) : (
        <React.Fragment />
      )}

      <ShowBundleContentModal />
    </LegacyCard.Section>
  );
};
