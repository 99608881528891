import { CurrencyCode } from "@smartrr/shared/currencyCode";
import {
  IPurchasableVariant,
  getSmartrrVariantDiscountPrice,
  getVariantPriceForCurrencyWithAFallbackValue,
} from "@smartrr/shared/entities/PurchasableVariant";
import { ISmartrrAddOnsConfigSellingPlan, ISmartrrSellingPlan } from "@smartrr/shared/entities/SellingPlanGroup";
import { convertNumberForFormatMoney } from "@smartrr/shared/utils/formatMoney";

export const getComputedPriceBasedOnPolicy = (
  currency: CurrencyCode,
  vnt: IPurchasableVariant | undefined | null,
  sellingPlan?: ISmartrrSellingPlan | ISmartrrAddOnsConfigSellingPlan
) => {
  if (sellingPlan && vnt != null) {
    return String(
      convertNumberForFormatMoney(getSmartrrVariantDiscountPrice(vnt, currency, sellingPlan), currency).toFixed(2)
    );
  }

  if (vnt != null) {
    return String(
      convertNumberForFormatMoney(getVariantPriceForCurrencyWithAFallbackValue(vnt, currency), currency).toFixed(
        2
      )
    );
  }

  return "0.00";
};
