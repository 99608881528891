import { OrgUtils } from "@smartrr/shared/entities/Organization";
import { IPurchaseStateWithCustomerRelationship } from "@smartrr/shared/entities/PurchaseState";
import { RRuleManager } from "@smartrr/shared/entities/Schedule/RRuleManager";
import { isCPSPrepaid } from "@smartrr/shared/utils/isPrepaid";
import { useEffect, useState } from "react";

import { useActiveOrganizationSelector } from "@vendor-app/app/_state/reducers/organizations";

export const useActualPurchaseStateStatus = (
  customerPurchaseState: IPurchaseStateWithCustomerRelationship | undefined
) => {
  const [actualPurchaseStateStatus, setActualPurchaseStateStatus] = useState(
    customerPurchaseState?.purchaseStateStatus
  );
  const organization = useActiveOrganizationSelector();

  const rruleManager =
    customerPurchaseState?.schedule && organization
      ? RRuleManager.fromJson(customerPurchaseState.schedule, OrgUtils.getBillingSchedule(organization))
      : undefined;
  const isPrepaid = isCPSPrepaid(customerPurchaseState?.schedule);

  useEffect(() => {
    if (customerPurchaseState?.purchaseStateStatus === "EXPIRED" && isPrepaid) {
      const isInPrepaidCycle = rruleManager?.isCurrentlyInPrepaidCycle();

      if (isInPrepaidCycle) {
        setActualPurchaseStateStatus("ACTIVE");
      } else {
        setActualPurchaseStateStatus(customerPurchaseState.purchaseStateStatus);
      }
    } else if (
      customerPurchaseState?.subProperties?.billingFailedMessage &&
      customerPurchaseState.purchaseStateStatus === "PAUSED"
    ) {
      setActualPurchaseStateStatus("FAILED");
    } else {
      setActualPurchaseStateStatus(customerPurchaseState?.purchaseStateStatus);
    }
  }, [customerPurchaseState?.purchaseStateStatus, isPrepaid, organization]);

  return {
    actualPurchaseStateStatus,
  };
};
