import { BulkAction } from "@shopify/polaris/build/ts/src/components/BulkActions";
import { UpcomingOrdersTableAccess } from "../../store";
import { useMemo } from "react";
import { useUpcomingOrderSkipModal } from "../../components/SkipModal/store";
import { compact } from "lodash";
import { useUpcomingOrderUnskipModal } from "../../components/UnskipModal/store";
import { NO_OP_CALLBACK } from "@smartrr/shared/constants";

const useSkipAction = (): BulkAction | null => {
  const selectedResources = UpcomingOrdersTableAccess.useSelectedData();
  const { openModal } = useUpcomingOrderSkipModal();

  return useMemo(() => {
    const areAnyActionsSkippable = selectedResources.some(order => !order.isSkipped);

    if (!areAnyActionsSkippable) {
      return null;
    }

    return {
      content: "Skip",
      onAction: () => openModal({}),
    };
  }, [selectedResources]);
};

const useUnskipAction = (): BulkAction | null => {
  const selectedResources = UpcomingOrdersTableAccess.useSelectedData();
  const { openModal } = useUpcomingOrderUnskipModal();

  return useMemo(() => {
    const areAllSelectedOrdersUnskippable =
      selectedResources.length && selectedResources.every(order => order.isSkipped);

    if (!areAllSelectedOrdersUnskippable) {
      return null;
    }

    return {
      content: "Unskip",
      onAction: () => openModal({}),
    };
  }, [selectedResources]);
};

/**
 * Going from zero promoted+bulk actions to non-zero causes
 * our version of Polaris to break when showing sticky actions
 * so we are making there's always one action present in the list.
 * Polaris will not show the action list if nothing is selected.
 */
const usePolarisWorkaroundAction = (): BulkAction | null => {
  const selectedResources = UpcomingOrdersTableAccess.useSelectedData();

  return useMemo(() => {
    if (selectedResources.length) {
      return null;
    }
    return {
      content: "Workaround",
      onAction: NO_OP_CALLBACK,
    };
  }, [selectedResources]);
};

export const useUpcomingOrdersPromotedBulkActions = (): BulkAction[] => {
  const skipAction = useSkipAction();
  const unskipAction = useUnskipAction();
  const workaroundAction = usePolarisWorkaroundAction();

  return useMemo(() => {
    return compact([skipAction, unskipAction, workaroundAction]);
  }, [skipAction, unskipAction, workaroundAction]);
};
