import { FormLayout, Modal, TextField } from "@shopify/polaris";
import React, { useState } from "react";

import { updateEditNoteModal } from "@vendor-app/app/_state/actionCreators/subscriptionDetails";
import { useSmartrrVendorDispatch, useSmartrrVendorSelector } from "@vendor-app/app/_state/typedVendorReduxHooks";

interface Props {
  currentNoteText?: string;
  onSave(text?: string): void;
}

export function EditNoteModal({ onSave, currentNoteText }: Props): JSX.Element {
  const dispatch = useSmartrrVendorDispatch();
  const showEditNote = useSmartrrVendorSelector(state => state.subscriptionDetails.showEditNote);
  const closeModal = () => dispatch(updateEditNoteModal(false));

  const [orderNote, setOrderNote] = useState(currentNoteText);

  return (
    <Modal
      open={showEditNote}
      onClose={closeModal}
      title="Edit notes"
      primaryAction={{
        id: "edit-order-note__confirm-btn",
        content: "Save",
        onAction() {
          onSave(orderNote);
          closeModal();
        },
      }}
      secondaryActions={[
        {
          id: "edit-order-note__cancel-btn",
          content: "Cancel",
          onAction: closeModal,
        },
      ]}
    >
      <Modal.Section>
        <FormLayout>
          <FormLayout.Group>
            <TextField
              id="edit-order-note__note-textfield"
              autoComplete="off"
              label="Order note"
              placeholder="Write your order note here"
              multiline={4}
              value={orderNote}
              onChange={setOrderNote}
            />
          </FormLayout.Group>
        </FormLayout>
      </Modal.Section>
    </Modal>
  );
}
