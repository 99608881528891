import { createInteractiveTable } from "@vendor-app/app/_sharedComponents/InteractiveDataTable";
import { UpcomingOrderWithTotal } from "../types";
import { UpcomingOrdersTableFilters } from "./filters";
import { UpcomingOrdersTableColumns } from "./columns";
import { UpcomingOrdersTableSorts } from "./sorts";

const initialData: UpcomingOrderWithTotal[] = [];

interface UpcomingOrdersTablePersistType {
  selectedColumns: UpcomingOrdersTableColumns.SelectedColumn[];
  selectedSort: UpcomingOrdersTableSorts.SelectedSort;
}

const CURRENT_STORAGE_VERSION = 0;

export const UpcomingOrdersTableAccess = createInteractiveTable<
  UpcomingOrderWithTotal,
  UpcomingOrdersTableFilters.SelectedFilter,
  UpcomingOrdersTableColumns.SelectedColumn,
  UpcomingOrdersTableSorts.SelectedSort,
  UpcomingOrdersTablePersistType
>({
  initialData,
  initialFilters: [...UpcomingOrdersTableFilters.types],
  initialSorts: [...UpcomingOrdersTableSorts.types],
  initialPagination: {
    pageNumber: 1,
    maxPages: 1,
  },
  initialSelectedFilters: [
    {
      filterType: "numberOfFutureOrders",
      value: ["5"],
    },
  ],
  initialSelectedSort: {
    sortType: "transactionDate",
    value: "ASC",
  },
  initialSelectedColumns: UpcomingOrdersTableColumns.shownTableColumns.map(c => ({
    columnType: c,
    value: true,
  })),
  async onUpdateTable(currentData, selectedFilters, selectedSort, pagination) {
    return {
      data: currentData,
      maxPages: 1,
    };
  },
  persistSettings: {
    name: "smartrr-upcoming-order-table-settings",
    version: CURRENT_STORAGE_VERSION,
    partialize: store => ({
      selectedColumns: store.selectedColumns,
      selectedSort: store.selectedSort,
    }),
    migrate(storedState, version) {
      if (version === CURRENT_STORAGE_VERSION) {
        return storedState as UpcomingOrdersTablePersistType;
      }
    },
  },
});
