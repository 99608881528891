import { Button, LegacyCard, LegacyStack, Tag, Text, TextField } from "@shopify/polaris";
import { Status } from "@shopify/polaris/build/ts/src/components/Badge";
import { ERASED_ORDER_NOTE } from "@smartrr/shared/constants";
import { IMailingAddressJson } from "@smartrr/shared/entities/MailingAddress";
import { IPurchaseStateWithCustomerRelationship } from "@smartrr/shared/entities/PurchaseState";
import { IPurchaseStateDiscount } from "@smartrr/shared/interfaces/Discount";
import { SubscriptionContractSubscriptionStatus } from "@smartrr/shared/shopifyGraphQL/api";
import { formatDiscountValue } from "@smartrr/shared/utils/displayUtils";
import { pluralizeForPrefix } from "@smartrr/shared/utils/pluralize";
import {
  CustomerInformationCard,
  IAddressInfoProps,
} from "@vendor-app/app/_sharedComponents/CustomerInformationCard";
import {
  updateEditNoteModal,
  updateRemoveSubscriptionDiscountModal,
} from "@vendor-app/app/_state/actionCreators/subscriptionDetails";
import { useSmartrrVendorDispatch } from "@vendor-app/app/_state/typedVendorReduxHooks";
import React, { useCallback, useMemo, useState } from "react";
import styled from "styled-components";

import { EditNoteModal, RemoveSubscriptionDiscountModal } from "../../modals";
import { isDiscountCodeForPrepaid } from "@smartrr/shared/utils/discountCodes";

interface ICustomerInfoAndDiscountsProps {
  customerPurchaseState: IPurchaseStateWithCustomerRelationship;
  isApplyingDiscountCode: boolean;

  onApplyDiscountCode: (code: string) => Promise<void>;
  onRemoveDiscount: (discount: IPurchaseStateDiscount) => Promise<void>;
  onUpdateShippingAddress: (address: IMailingAddressJson) => void;
  onEditNote: (noteText?: string) => void;
}

const DiscountCodeContainer = styled.div`
  white-space: break-spaces;
  word-wrap: break-word;
`;

const DiscountTag = styled.div<{ bgcolor: string }>`
  .Polaris-Tag {
    background-color: ${props => props.bgcolor};
  }
`;

const DiscountTagContainer: React.FC<{ status: Status; children: React.ReactNode }> = ({ status, children }) => {
  const statusMap: Record<string, string> = {
    attention: "caution",
    critical: "critical",
    info: "strong",
    new: "strong",
    success: "success",
    warning: "warning",
  };

  const bgcolor =
    status === "new" ? "var(--p-color-bg-strong)" : `var(--p-color-bg-${statusMap[status] ?? "strong"})`;

  return <DiscountTag bgcolor={bgcolor}>{children}</DiscountTag>;
};

export function CustomerInfoAndDiscounts({
  customerPurchaseState,
  isApplyingDiscountCode,
  onApplyDiscountCode,
  onRemoveDiscount,
  onUpdateShippingAddress,
  onEditNote,
}: ICustomerInfoAndDiscountsProps): JSX.Element {
  const areActionsDisabled =
    customerPurchaseState.purchaseStateStatus !== SubscriptionContractSubscriptionStatus.Active &&
    customerPurchaseState.purchaseStateStatus !== SubscriptionContractSubscriptionStatus.Paused;

  const dispatch = useSmartrrVendorDispatch();
  const openEditNoteModal = () => dispatch(updateEditNoteModal(true));
  const openRemoveSubscriptionDiscountModal = () => dispatch(updateRemoveSubscriptionDiscountModal(true));

  const [discountCode, setDiscountCode] = useState("");
  const [discountForRemoval, setDiscountForRemoval] = useState<IPurchaseStateDiscount | null>(null);

  const { custRel } = customerPurchaseState;

  const customerNote = useMemo(() => {
    if (
      !customerPurchaseState.subProperties?.orderNote ||
      customerPurchaseState.subProperties?.orderNote === ERASED_ORDER_NOTE
    ) {
      return "";
    }
    return customerPurchaseState.subProperties?.orderNote;
  }, [customerPurchaseState.subProperties?.orderNote]);

  const onSubmitDiscountCode = async (discountCode: string | undefined) => {
    if (!discountCode) {
      return;
    }
    onApplyDiscountCode(discountCode).finally(() => {
      setDiscountCode("");
    });
  };

  const handleKeyPress = useCallback(
    (e: React.KeyboardEvent) => {
      if (e.key === "Enter" && !areActionsDisabled && !isApplyingDiscountCode) {
        onSubmitDiscountCode(discountCode);
      }
    },
    [isApplyingDiscountCode, areActionsDisabled, discountCode]
  );

  const onRemoveDiscountClick = useCallback((discount: IPurchaseStateDiscount) => {
    if (discount.vendorId) {
      setDiscountForRemoval(discount);
      openRemoveSubscriptionDiscountModal();
    }
  }, []);

  const isMigrating =
    customerPurchaseState.externalSubscriptionStatus === SubscriptionContractSubscriptionStatus.Active;

  const displayDiscounts = (discounts: IPurchaseStateDiscount[], showExpired?: boolean) => {
    return discounts
      .filter(d => (showExpired ? isDiscountExpired(d) : !isDiscountExpired(d)))
      .map(d => (
        <DiscountTagContainer key={d.vendorId} status={isDiscountExpired(d) ? "critical" : "new"}>
          <Tag onRemove={d.vendorId && !isDiscountCodeForPrepaid(d) ? () => onRemoveDiscountClick(d) : undefined}>
            <DiscountCodeContainer id={`customer-info__formatted-discount-code-${d.vendorId}`}>
              {d.code} {formatDiscountValue(d)}
            </DiscountCodeContainer>
          </Tag>
        </DiscountTagContainer>
      ));
  };

  const isDiscountExpired = (discount: IPurchaseStateDiscount) => {
    return (
      (discount.hasOwnProperty("rejectionReason") ? !!discount.rejectionReason : false) ||
      (discount.hasOwnProperty("recurringCycleLimit")
        ? discount.recurringCycleLimit && discount.usageCount > discount.recurringCycleLimit
        : false)
    );
  };

  const addressInfo: IAddressInfoProps | undefined = {
    address: {
      ...customerPurchaseState.shippingAddress,
      phoneCountryCode: customerPurchaseState.subProperties?.phoneCountryCode,
    },
    showPhoneNumber: true,
    areActionsDisabled: false,
    onUpdateAddress: onUpdateShippingAddress,
  };

  return (
    <React.Fragment>
      {custRel ? <CustomerInformationCard customerInfo={custRel} addressInfo={addressInfo} /> : null}
      <LegacyCard>
        <LegacyCard.Section title="Notes">
          <LegacyStack vertical spacing="extraTight">
            <Text variant="bodyMd" as="p">
              Order Notes
            </Text>
            {customerPurchaseState.subProperties?.orderNote &&
            customerPurchaseState.subProperties?.orderNote !== ERASED_ORDER_NOTE ? (
              <React.Fragment>
                <Text variant="bodyMd" as="p" color="subdued" breakWord={true} id="customer-info__order-note">
                  {customerPurchaseState.subProperties.orderNote}
                </Text>
                <Button plain onClick={openEditNoteModal} id="customer-info__edit-order-note-btn">
                  Edit
                </Button>
              </React.Fragment>
            ) : (
              <Button plain onClick={openEditNoteModal} id="customer-info__add-order-note-btn">
                + Add
              </Button>
            )}
          </LegacyStack>
        </LegacyCard.Section>
      </LegacyCard>
      <LegacyCard sectioned title="Discount code">
        <LegacyStack vertical>
          {customerPurchaseState.discounts.length ? (
            <LegacyStack vertical spacing="baseTight">
              {customerPurchaseState.discounts.filter(d => !isDiscountExpired(d)).length ? (
                <React.Fragment>
                  <Text variant="bodyMd" as="p" color="subdued" id="customer-info__discounts-title">
                    {`Discount ${pluralizeForPrefix(
                      customerPurchaseState.discounts.filter(d => !isDiscountExpired(d)).length,
                      "code"
                    )} currently applied`}
                  </Text>
                  <LegacyStack spacing="extraTight">
                    {displayDiscounts(customerPurchaseState.discounts)}
                  </LegacyStack>
                </React.Fragment>
              ) : null}
              {customerPurchaseState.discounts.filter(d => isDiscountExpired(d)).length ? (
                <React.Fragment>
                  <Text variant="bodyMd" as="p" color="subdued" id="customer-info__expired-discounts-title">
                    {`The applied ${pluralizeForPrefix(
                      customerPurchaseState.discounts.filter(d => isDiscountExpired(d)).length,
                      "discount"
                    )} is expired`}
                  </Text>
                  <LegacyStack spacing="extraTight">
                    {displayDiscounts(customerPurchaseState.discounts, true)}{" "}
                  </LegacyStack>
                </React.Fragment>
              ) : null}
            </LegacyStack>
          ) : (
            <Text variant="bodyMd" as="p" color="subdued" id="customer-info__no-discount">
              No discount currently applied
            </Text>
          )}
          {/* dumb, but suggested by shopify */}
          <div onKeyDown={handleKeyPress} id="customer-info__apply-discount-section">
            <TextField
              id="customer-info__discount-code-textfield"
              autoComplete="off"
              label="Apply discount"
              labelHidden
              value={discountCode}
              onChange={setDiscountCode}
              disabled={areActionsDisabled || isMigrating || isApplyingDiscountCode}
              connectedRight={
                <Button
                  id="customer-info__apply-discount-btn"
                  disabled={areActionsDisabled || isMigrating}
                  loading={isApplyingDiscountCode}
                  onClick={() => onSubmitDiscountCode(discountCode)}
                >
                  Apply
                </Button>
              }
            />
          </div>
        </LegacyStack>
      </LegacyCard>

      {discountForRemoval ? (
        <RemoveSubscriptionDiscountModal discount={discountForRemoval} onRemove={onRemoveDiscount} />
      ) : null}
      <EditNoteModal currentNoteText={customerNote} onSave={onEditNote} />
    </React.Fragment>
  );
}
