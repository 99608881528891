export function getFinalNumberOfdeliveriesToGenerate(cps, numberOfFutureDeliveriesToGenerate) {
  let finalNumberOfDeliveriesToGenerate = numberOfFutureDeliveriesToGenerate;
  if (cps.schedule.maxCycles) {
    finalNumberOfDeliveriesToGenerate =
      cps.schedule.maxCycles * cps.schedule.paymentFrequencyMultiple - cps.schedule.totalOrdersCount!;

    finalNumberOfDeliveriesToGenerate = Math.min(
      finalNumberOfDeliveriesToGenerate,
      numberOfFutureDeliveriesToGenerate
    );
  }
  return finalNumberOfDeliveriesToGenerate;
}
