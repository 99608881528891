import { ButtonGroup, LegacyCard, TextField, useIndexResourceState } from "@shopify/polaris";
import { Box } from "@smartrr/shared/components/primitives";
import { CurrencyCode } from "@smartrr/shared/currencyCode";
import {
  ISmartrrOrderWithCustomerRelationship,
  ISmartrrOrderWithCustomerRelationshipPaginatedResponse,
} from "@smartrr/shared/entities/Order";
import { IOrganization } from "@smartrr/shared/entities/Organization";
import { captureException } from "@smartrr/shared/utils/captureException";
import { shopifyGidToNumber } from "@smartrr/shared/utils/ensureShopifyGid";
import React, { useEffect, useState } from "react";

import { useFilterUpdate } from "@vendor-app/app/_sharedComponents/TableSearch/libs";
import { useToast } from "@vendor-app/app/_sharedComponents/Toast/ToastProvider";
import {
  SelectionType,
  usePolarisTypedTable,
} from "@vendor-app/app/_sharedComponents/TypedTable/usePolarisTypedTable";
import { useTableHandlers } from "@vendor-app/app/_sharedComponents/TypedTable/useTableHandlers";
import { getSubscriptionOrdersByIdOrShopifyIdAPI } from "@vendor-app/app/_state/actionCreators/order";
import { useSmartrrVendorSelector } from "@vendor-app/app/_state/typedVendorReduxHooks";
import {
  CPSOrderHistoryTableColumnKeyType,
  ValueSelectorForOrdersFilter,
  parseOrdersForIndexTable,
} from "@vendor-app/app/AdminRoute/AdminOrdersRoute/libs";
import {
  HeaderContainer,
  PaginationContainer,
  orderHistoryColumns,
  viewSubscriptionsByCustomer,
} from "@vendor-app/app/AdminRoute/AdminSubscriptionDetailsRoute/libs";
import { isRowSelected } from "@vendor-app/utils/isIndexTableRowSelected";

interface Props {
  subscriptionId: string;
  activeOrg: IOrganization | null;
  customerCurrency: CurrencyCode | undefined;
}

export const OrderHistory = ({ subscriptionId, activeOrg, customerCurrency }: Props) => {
  const { addToast } = useToast();
  const { purchasables } = useSmartrrVendorSelector(state => state.purchasables);
  const { Table, Pagination, Sorting, Filter, Columns } =
    usePolarisTypedTable<CPSOrderHistoryTableColumnKeyType>();

  const [tableProps, tableHandlers] = useTableHandlers("orderProcessedDate", "DESC", undefined, 5, "orderId");
  const [paginatedOrders, setPaginatedOrders] = useState<ISmartrrOrderWithCustomerRelationshipPaginatedResponse>({
    data: [],
    pageNumber: tableProps.pageNumber,
    pageSize: tableProps.pageSize,
    totalCount: 0,
    totalPages: 0,
  });
  const [isLoading, setLoading] = useState(false);
  const [orderHistoryColumnsState, setOrderHistoryColumnsState] = useState(orderHistoryColumns);
  const { selectedResources, allResourcesSelected, handleSelectionChange } = useIndexResourceState(
    paginatedOrders.data.map(order => ({ id: String(order.id) }))
  );
  const [debouncedUpdate] = useFilterUpdate(
    ({ queryParams }) => fetchOrders(queryParams),
    tableHandlers.setPageNumber
  );

  const fetchOrders = async (queryParams: any) => {
    setLoading(true);
    try {
      const res = await getSubscriptionOrdersByIdOrShopifyIdAPI(
        String(shopifyGidToNumber(subscriptionId)),
        queryParams
      );
      if (res.type === "success" && res.body) {
        setPaginatedOrders(res.body);
      }
    } catch (error) {
      captureException("Couldn't load the transaction history", error);
      addToast("Couldn't load the transaction history");
    }
    setLoading(false);
  };

  const { data: orders, totalPages, totalCount, pageSize } = paginatedOrders;

  useEffect(() => {
    const { orderByField, orderByValue, pageNumber, pageSize } = tableProps;
    debouncedUpdate(pageNumber, pageSize, orderByField, orderByValue, false, tableProps.filter);
  }, [
    tableProps.pageNumber,
    tableProps.pageSize,
    tableProps.orderByField,
    tableProps.orderByValue,
    tableHandlers,
  ]);

  useEffect(() => {
    const { orderByField, orderByValue, pageNumber, pageSize } = tableProps;
    debouncedUpdate(pageNumber, pageSize, orderByField, orderByValue, true, tableProps.filter);
  }, [tableProps.filter, tableHandlers]);

  const openOrdersInNewTab = (
    selectedResources: string[],
    orders: ISmartrrOrderWithCustomerRelationship[],
    activeOrg: IOrganization | null
  ) => {
    const order = orders.find(order => order.id === selectedResources[0]);

    if (order && order.custRel?.shopifyId && activeOrg) {
      return viewSubscriptionsByCustomer(order.custRel.shopifyId, activeOrg);
    }
  };

  const promotedBulkActions = [
    {
      content: "View in Shopify",
      onAction() {
        openOrdersInNewTab(selectedResources, orders, activeOrg);
        handleSelectionChange(SelectionType.All, false);
      },
    },
  ];

  return (
    <React.Fragment>
      <LegacyCard.Section flush>
        <HeaderContainer>
          <div style={{ flex: 0.7 }}>
            <TextField
              id="subscription-details__order-history__show-next-textfield"
              autoComplete="off"
              label=""
              labelHidden
              min={1}
              type="number"
              inputMode="numeric"
              connectedLeft={
                <Box style={{ height: "100%" }} alignItems="center" pr={1}>
                  Show past
                </Box>
              }
              value={"" + tableProps.pageSize}
              onChange={value => tableHandlers.setPageSize(Number(value))}
            />
          </div>
          <ButtonGroup segmented>
            <Filter
              columns={orderHistoryColumnsState}
              filter={tableProps.filter}
              setFilter={tableHandlers.setFilter}
              filterComponent={(field, value, handleSetFilter) => (
                <ValueSelectorForOrdersFilter
                  field={field}
                  value={value}
                  setValue={newValue => handleSetFilter("update", field, newValue)}
                  currency={activeOrg?.shopifyStoreData?.currency}
                  customerCurrency={customerCurrency}
                  purchasables={purchasables}
                />
              )}
            />
            <Columns
              columns={orderHistoryColumnsState}
              addToast={addToast}
              setDisabledColumns={setOrderHistoryColumnsState}
            />

            <Sorting
              columns={orderHistoryColumnsState}
              orderByField={tableProps.orderByField}
              orderByValue={tableProps.orderByValue}
              setOrderByField={tableHandlers.setOrderByField}
              setOrderByValue={tableHandlers.setOrderByValue}
            />
          </ButtonGroup>
        </HeaderContainer>
        <Table
          selectable
          columns={orderHistoryColumnsState}
          data={parseOrdersForIndexTable(orders, activeOrg)}
          loading={isLoading}
          emptyStateText="No order history"
          resourceName={{
            singular: "order",
            plural: "orders",
          }}
          itemCount={totalCount < pageSize ? totalCount : pageSize}
          promotedBulkActions={promotedBulkActions}
          selectedItemsCount={allResourcesSelected ? "All" : selectedResources.length}
          sortable={Object.values(orderHistoryColumnsState)
            .filter(v => !v.disabled)
            .map(v => v.sorting)}
          sortColumnIndex={Object.entries(orderHistoryColumnsState).findIndex(
            ([key, value]) => value.paginatedValue === tableProps.orderByField && !value.disabled
          )}
          sortDirection={tableProps.orderByValue === "ASC" ? "ascending" : "descending"}
          onSelectionChange={(selectionType, toggleType, selection) => {
            handleSelectionChange(selectionType, toggleType, selection);
          }}
          onSort={(headingIndex: number, direction: "ascending" | "descending") => {
            tableHandlers.setOrderByField(
              Object.values(orderHistoryColumnsState).filter(value => !value.disabled)[headingIndex]
                .paginatedValue
            );
            tableHandlers.setOrderByValue(direction === "ascending" ? "ASC" : "DESC");
          }}
          isRowSelected={id => isRowSelected(id, selectedResources)}
        />
      </LegacyCard.Section>
      <LegacyCard.Section subdued>
        <PaginationContainer id="subscription-details__order-history__pagination-section">
          <Pagination
            hasNext={totalPages > tableProps.pageNumber}
            hasPrevious={tableProps.pageNumber != 1}
            label={`Showing ${tableProps.pageNumber} of ${totalPages}`}
            onNext={() => tableHandlers.setPageNumber(p => p + 1)}
            onPrevious={() => tableHandlers.setPageNumber(p => p - 1)}
          />
        </PaginationContainer>
      </LegacyCard.Section>
    </React.Fragment>
  );
};
