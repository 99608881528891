import { IPurchaseStateWithCustomerRelationship } from "@smartrr/shared/entities/PurchaseState";
import { captureException } from "@smartrr/shared/utils/captureException";

import { typedFrontendVendorApi } from "@vendor-app/utils/typedFrontendVendorApi";

import { getFormattedWarningText } from "..";
interface IFailedCPSAndBillsWithError {
  id: string;
  error: string;
}

export const getFailedBillsAndCPSSubPropertiesErrors = async (
  customerPurchaseState: IPurchaseStateWithCustomerRelationship | undefined
): Promise<IFailedCPSAndBillsWithError[]> => {
  const formattedBillsErrors: { id: string; error: string }[] = [];

  if (customerPurchaseState?.id) {
    try {
      const failedBillingRes = await typedFrontendVendorApi.getReq(
        "/purchase-state/:customerPurchaseStateId/failed-bill",
        {
          params: {
            customerPurchaseStateId: customerPurchaseState.id,
          },
        }
      );
      if (failedBillingRes.type === "success" && failedBillingRes.body.failedBill) {
        formattedBillsErrors.push({
          id: String(failedBillingRes.body.failedBill.id),
          error: getFormattedWarningText(failedBillingRes.body.failedBill.error),
        });
      }
    } catch (error) {
      captureException(error);
    }
  }

  if (customerPurchaseState?.subProperties?.billingFailedMessage) {
    formattedBillsErrors.push({
      id: "billingFailedMessage",
      error: customerPurchaseState.subProperties.billingFailedMessage,
    });
  }

  return formattedBillsErrors;
};
