import { IPurchaseState } from "@smartrr/shared/entities/PurchaseState";
import { ScheduledDeliveryGroupWithPurchSt } from "@smartrr/shared/entities/PurchaseState/scheduleUtils";
import { sortBy } from "lodash";

import { useSmartrrVendorSelector } from "@vendor-app/app/_state/typedVendorReduxHooks";

export const useSendOrderNowAvailability = (
  delivery: ScheduledDeliveryGroupWithPurchSt<IPurchaseState> | undefined
): boolean => {
  const orders = useSmartrrVendorSelector(state => state.orders.data);

  if (!delivery) {
    return false;
  }
  const { purchaseState, date: deliveryDate, indexFromScheduleStart: skdIdx } = delivery;

  const currentDate = Date.now();

  const isGift = !!(purchaseState.gifts ?? [])
    .map((g: any) => ({ ...g, cpsId: purchaseState.id }))
    .some((_: any) => _.skdIdx === skdIdx);

  const isDeliveryMostRecent = delivery.indexFromNext === 0;

  const isSkipped = delivery.isSkipped;

  const isPrepaid =
    purchaseState.schedule.paymentFrequencyMultiple !== purchaseState.schedule.deliveryFrequencyMultiple;

  const hoursTillDelivery = Math.floor((deliveryDate.getTime() - currentDate) / 1000 / 60 / 60);

  const sortedAndFilteredOrders = sortBy(
    orders.filter(order => order.sts.length && order.sts[0].id === purchaseState.id),
    "orderProcessedDate"
  ).reverse();

  const mostRecentOrder = sortedAndFilteredOrders.length ? sortedAndFilteredOrders[0] : null;

  if (mostRecentOrder) {
    // if an order has processed in the last 24 hours
    const mostRecentOrderHoursAgo = Math.floor(
      (currentDate - new Date(mostRecentOrder?.orderProcessedDate).getTime()) / 1000 / 60 / 60
    );
    if (mostRecentOrderHoursAgo <= 24) {
      return false;
    }
  }

  return !(hoursTillDelivery <= 24 || !isDeliveryMostRecent || isPrepaid || isGift || isSkipped);
};
