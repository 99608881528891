import { IPurchaseStateWithCustomerRelationship } from "@smartrr/shared/entities/PurchaseState";
import { adjustToStoreBillingTime } from "@smartrr/shared/utils/dateUtils";
import { toViewLongDate } from "@smartrr/shared/utils/renderViewDate";
import { useCallback } from "react";

import { useToast } from "@vendor-app/app/_sharedComponents/Toast/ToastProvider";
import { putCustomerPurchaseStateSetNextBillingDate } from "@vendor-app/app/_state/actionCreators/customerPurchaseState";
import { useActiveOrganizationSelector } from "@vendor-app/app/_state/reducers/organizations";
import { useSmartrrVendorDispatch } from "@vendor-app/app/_state/typedVendorReduxHooks";
import { ISODateString } from "@smartrr/shared/entities/ISODateString";

export const useSetNextDate = (
  customerPurchaseState: IPurchaseStateWithCustomerRelationship,
  onCommitStart?: () => void,
  onCommitEnd?: () => void
) => {
  const dispatch = useSmartrrVendorDispatch();
  const { addToast } = useToast();
  const organization = useActiveOrganizationSelector();

  return useCallback(
    async (date: Date) => {
      if (!(customerPurchaseState && customerPurchaseState.custRel)) {
        return;
      }

      onCommitStart && onCommitStart();
      await dispatch(
        putCustomerPurchaseStateSetNextBillingDate({
          custRelId: customerPurchaseState.custRel.id,
          cpsId: customerPurchaseState.id,
          billingDate: adjustToStoreBillingTime(date, organization!.billingTime, organization!.billingTimezone),
        })
      )
        .then(res => {
          if (res.type === "UPDATED_CPS_NEXT_DATE") {
            const nextOrderDate = res.payload.newCustomerPurchaseState.nextOrderDate;
            addToast(`Next order date updated to ${toViewLongDate(ISODateString.fromString(nextOrderDate))}`);
          }
          if (res.type === "ERROR_UPDATING_CPS_NEXT_DATE") {
            addToast("Error modifying subscription schedule", true);
          }
        })
        .catch(error => {
          addToast("Error modifying subscription schedule", true);
        });
      onCommitEnd && onCommitEnd();
    },
    [customerPurchaseState, organization]
  );
};
