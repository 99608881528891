import { IPurchaseStateWithCustomerRelationship } from "@smartrr/shared/entities/PurchaseState";
import { useMemo } from "react";
import { CurrencyCode, SubscriptionContractSubscriptionStatus } from "@smartrr/shared/shopifyGraphQL/api";
import { compact } from "lodash";
import { useActiveOrganizationSelector } from "@vendor-app/app/_state/reducers/organizations";
import {
  IScheduledEventWithProjectedTotal,
  getPurcStScheduledDeliveriesFromDateWithTotal,
  useSellingPlans,
} from "@vendor-app/app/AdminRoute/AdminSubscriptionDetailsRoute/libs";
import { sequentialWalker } from "@smartrr/shared/utils/sequentialWalker";
import {
  getLineItemPricingPolicyFromPricingPolicyInput,
  getPricingPolicyInputFromSmartrrSellingPlan,
} from "@smartrr/shared/utils/sharedTranslations/pricingPolicy";
import { getTotalDisplay } from "@smartrr/shared/utils/displayUtils";
import { useSmartrrFlags } from "@smartrr/shared/LaunchDarkly";
import { useNumberOfUpcomingOrdersToGenerate } from "../useNumberOfUpcomingOrdersToGenerate";
import { getCPSRemainingOrders } from "@smartrr/shared/utils/isPrepaid";

export const useGeneratedUpcomingOrders = (
  cps: IPurchaseStateWithCustomerRelationship
): IScheduledEventWithProjectedTotal[] => {
  const numberOfOrdersToGenerate = useNumberOfUpcomingOrdersToGenerate(cps);
  const activeOrg = useActiveOrganizationSelector();
  const sellingPlanInfo = useSellingPlans();
  const specialReferralsVariantId = useSmartrrFlags().specialReferrals;

  const isActive = useMemo(
    () => cps.purchaseStateStatus === SubscriptionContractSubscriptionStatus.Active,
    [cps]
  );
  const isPaused = useMemo(
    () => cps.purchaseStateStatus === SubscriptionContractSubscriptionStatus.Paused,
    [cps]
  );
  const cpsRemainingOrders = useMemo(() => getCPSRemainingOrders(cps), [cps]);

  return useMemo((): IScheduledEventWithProjectedTotal[] => {
    if (!isActive && !isPaused && cpsRemainingOrders === 0) {
      return [];
    }

    const preSequentialDeliveries: IScheduledEventWithProjectedTotal[] =
      getPurcStScheduledDeliveriesFromDateWithTotal(cps, [cps], activeOrg, numberOfOrdersToGenerate, true).map(
        d => ({ ...d, id: d.indexFromScheduleStart })
      );

    return compact(
      sequentialWalker(preSequentialDeliveries).map(delivery => {
        const currency: CurrencyCode = delivery.purchaseState.currency as CurrencyCode;

        const hasSubscriptionLineItems = delivery.purchaseState.stLineItems.some(st => {
          return !(st.isAddOn || st.isRedeemed || st.deletedAt);
        });

        if (!hasSubscriptionLineItems) {
          return null;
        }

        delivery.purchaseState.stLineItems = delivery.purchaseState.stLineItems.map(line => {
          const sellingPlan = sellingPlanInfo
            .getSellingPlanGroup(delivery.purchaseState.sellingPlanId)
            ?.sellingPlans.find(sp => sp.shopifyId === line.sellingPlanId);

          if (sellingPlan && line.needsPricingUpdate) {
            line.pricingPolicy = getLineItemPricingPolicyFromPricingPolicyInput(
              getPricingPolicyInputFromSmartrrSellingPlan(line.vnt, currency, sellingPlan),
              currency
            );
          }
          return line;
        });

        const totalForScheduleIdx = getTotalDisplay(
          delivery.paymentMultipleDueOnDate,
          delivery.purchaseState,
          delivery.indexFromScheduleStart,
          true,
          delivery.indexFromNext,
          { variantIdsToOmit: [specialReferralsVariantId] }
        );
        return {
          ...delivery,
          totalForScheduleIdx,
        };
      })
    );
  }, [
    cps,
    numberOfOrdersToGenerate,
    activeOrg,
    sellingPlanInfo,
    specialReferralsVariantId,
    isActive,
    isPaused,
    cpsRemainingOrders,
  ]);
};
