import React, { useCallback } from "react";
import { Box, HorizontalStack, Text, TextField } from "@shopify/polaris";
import { UpcomingOrdersTableAccess } from "../../store";
import { UpcomingOrdersTableColumns } from "../../store/columns";
import { GenericInteractiveTableColumn } from "@vendor-app/app/_sharedComponents/InteractiveDataTable/components/Columns";
import { z } from "zod";
import styled from "styled-components";

const NavigationContainer = styled.div`
  #smartrr-upcoming-order-show-next {
    max-width: 100px;
  }
`;

export const UpcomingOrderNavigation = () => {
  const filter = UpcomingOrdersTableAccess.useSelectedFilter("numberOfFutureOrders");

  const actions = UpcomingOrdersTableAccess.useActions();

  const updateNumberOfOrdersCallback = useCallback(
    (value: string) => {
      const parsedValue = z.coerce.number().safeParse(value);
      const parsedAmount = parsedValue.success ? parsedValue.data : 1;
      const orderCount = Math.max(1, Math.min(parsedAmount, 20));

      actions.filter.update([
        {
          filterType: "numberOfFutureOrders",
          value: [String(orderCount)],
        },
      ]);
    },
    [actions]
  );

  const validateSelectedColumns = useCallback((columns: string[]) => {
    const validatedColumns = z.array(UpcomingOrdersTableColumns.typeSchema).safeParse(columns);
    if (validatedColumns.success) {
      return validatedColumns.data;
    }
    return [];
  }, []);

  return (
    <NavigationContainer>
      <Box padding="4">
        <HorizontalStack align="space-between">
          <HorizontalStack blockAlign="center" gap="3">
            <Text as="span" variant="bodyMd">
              Show next
            </Text>
            <TextField
              id="smartrr-upcoming-order-show-next"
              labelHidden
              label="show next order"
              value={filter?.value[0] ?? "1"}
              autoComplete="off"
              type="number"
              onChange={updateNumberOfOrdersCallback}
            ></TextField>
          </HorizontalStack>
          <HorizontalStack blockAlign="center" gap="3">
            <GenericInteractiveTableColumn
              storeAccess={UpcomingOrdersTableAccess}
              columnInfo={UpcomingOrdersTableColumns.columns}
              shownColumns={UpcomingOrdersTableColumns.shownTableColumns}
              validateSelectedColumns={validateSelectedColumns}
            />
          </HorizontalStack>
        </HorizontalStack>
      </Box>
    </NavigationContainer>
  );
};
