import type { IPurchasableVariant } from "@smartrr/shared/entities/PurchasableVariant";
import type { BundleLineItemTypes } from "@smartrr/shared/utils/bundles";
import type { ISmartrrBundleLineItem } from "@smartrr/shared/entities/SellingPlanGroup";
import type { IPurchaseLineItemsCustomAttributes } from "@smartrr/shared/entities/PurchaseState/CustomerPurchaseLineItem";

export const LineItemActionValues = ["read", "update", "add", "delete"] as const;

// creating a union this way so LineItemActionValues can be used within schema validations
export type LineItemAction = (typeof LineItemActionValues)[number];

export interface SubscriptionDetailsLineItem {
  id: string;
  shopifyId: string | undefined;
  skdIdx: number | undefined;
  quantity: number | undefined;
  productName: string;
  variantName: string;
  finalPrice: string;
  isAddOn?: boolean;
  isRedeemed?: boolean;
  vnt?: IPurchasableVariant | null;
  action: LineItemAction | `${LineItemAction}`;
  bundleInfo: LineItemBundleInfo;
}

export interface LineItemBundleInfo {
  id?: string | undefined;
  type?: BundleLineItemTypes;
  lines?: ISmartrrBundleLineItem[];
  custom?: IPurchaseLineItemsCustomAttributes[];
}
