import React, { useContext, useEffect, useMemo } from "react";
import { UpcomingOrdersContext } from "../../contexts/UpcomingOrdersContext";
import { useUpcomingOrderParsedData } from "../../hooks/useUpcomingOrderParsedData";
import { Box } from "@smartrr/shared/components/primitives";
import { IndexTable, Text, useIndexResourceState } from "@shopify/polaris";
import { UpcomingOrdersTableAccess } from "../../store";
import { UpcomingOrdersTableColumns } from "../../store/columns";
import { UpcomingOrdersTableCells } from "../Cells";
import { useUpcomingOrdersPromotedBulkActions } from "../../hooks/useUpcomingOrdersPromotedBulkActions";
import { UpcomingOrdersBulkActionCompletedContext } from "./UpcomingOrdersBulkActionCompletedContext";
import { SelectionType } from "@vendor-app/app/_sharedComponents/TypedTable/usePolarisTypedTable";
import { UpcomingOrderSkipModal } from "../SkipModal";
import { UpcomingOrderNavigation } from "../Navigation";
import { UpcomingOrderUnskipModal } from "../UnskipModal";

const NoUpcomingOrders = () => {
  return (
    <Box alignItems="center" justifyContent="center" p={2}>
      <Text variant="bodyMd" as="p" color="subdued" id="subscription-details__upcoming-orders__no-orders-text">
        No upcoming orders.
      </Text>
    </Box>
  );
};

interface TableRowProps {
  row: UpcomingOrdersTableCells.RowDataType;
  position: number;
}

export const UpcomingOrdersTableRow = ({ row, position }: TableRowProps) => {
  const enabledColumns = UpcomingOrdersTableAccess.useEnabledColumns();
  const isSelected = UpcomingOrdersTableAccess.useIsSelected(row.id);

  const cells = useMemo(() => {
    return enabledColumns.map(c => UpcomingOrdersTableCells.Cells[c]);
  }, [enabledColumns]);

  return (
    <IndexTable.Row id={row.id} position={position} selected={isSelected}>
      {cells.map((Cell, key) => {
        return <Cell key={key} row={row} />;
      })}
    </IndexTable.Row>
  );
};

const UpcomingOrdersTableRows = () => {
  const data = useContext(UpcomingOrdersContext);

  return (
    <React.Fragment>
      {data.map((sub, key) => (
        <UpcomingOrdersTableRow key={key} row={sub} position={key} />
      ))}
    </React.Fragment>
  );
};

const UpcomingOrderModals = () => {
  return (
    <React.Fragment>
      <UpcomingOrderSkipModal />
      <UpcomingOrderUnskipModal />
    </React.Fragment>
  );
};

const UpcomingOrdersIndexTable = () => {
  const upcomingOrders = useContext(UpcomingOrdersContext);
  const actions = UpcomingOrdersTableAccess.useActions();
  const isTableLoading = UpcomingOrdersTableAccess.useIsLoading();
  const tableHeadings = UpcomingOrdersTableAccess.useTableHeading(UpcomingOrdersTableColumns.columns);
  const { selectedResources, allResourcesSelected, handleSelectionChange } =
    useIndexResourceState(upcomingOrders);
  const promotedActions = useUpcomingOrdersPromotedBulkActions();

  useEffect(() => {
    actions.data.updateSelectionById(selectedResources);
  }, [selectedResources]);

  return (
    <UpcomingOrdersBulkActionCompletedContext.Provider
      value={{
        onCompleted() {
          handleSelectionChange(SelectionType.All, false);
        },
      }}
    >
      <IndexTable
        loading={isTableLoading}
        headings={tableHeadings}
        itemCount={upcomingOrders.length}
        resourceName={{
          singular: "upcoming order",
          plural: "upcoming orders",
        }}
        selectable
        selectedItemsCount={allResourcesSelected ? "All" : selectedResources.length}
        onSelectionChange={handleSelectionChange}
        promotedBulkActions={promotedActions}
      >
        <UpcomingOrdersTableRows />
      </IndexTable>
      <UpcomingOrderModals />
    </UpcomingOrdersBulkActionCompletedContext.Provider>
  );
};

const UpcomingOrdersTableContent = () => {
  const upcomingOrders = useContext(UpcomingOrdersContext);

  if (upcomingOrders.length === 0) {
    return <NoUpcomingOrders />;
  }

  return <UpcomingOrdersIndexTable />;
};

export const UpcomingOrdersTable = () => {
  const parsedUpcomingOrders = useUpcomingOrderParsedData();

  return (
    <UpcomingOrdersContext.Provider value={parsedUpcomingOrders}>
      <UpcomingOrderNavigation />
      <UpcomingOrdersTableContent />
    </UpcomingOrdersContext.Provider>
  );
};
