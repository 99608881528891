import { IPurchaseStateWithCustomerRelationship } from "@smartrr/shared/entities/PurchaseState";
import { useCallback } from "react";

import { useToast } from "@vendor-app/app/_sharedComponents/Toast/ToastProvider";
import { putCustomerPurchaseStateShippingPrice } from "@vendor-app/app/_state/actionCreators/customerPurchaseState";
import { useSmartrrVendorDispatch } from "@vendor-app/app/_state/typedVendorReduxHooks";

export const useUpdateShippingPrice = (
  customerPurchaseState: IPurchaseStateWithCustomerRelationship,
  onCommitStart?: () => void,
  onCommitEnd?: () => void
) => {
  const dispatch = useSmartrrVendorDispatch();
  const { addToast } = useToast();

  return useCallback(
    async (price: number) => {
      if (!(customerPurchaseState && customerPurchaseState.custRel)) {
        return;
      }

      onCommitStart && onCommitStart();
      await dispatch(
        putCustomerPurchaseStateShippingPrice({
          custRelId: customerPurchaseState.custRel.id,
          cpsId: customerPurchaseState.id,
          price,
        })
      )
        .then(res => {
          if (res.type === "UPDATED_CPS_SHIPPING_PRICE") {
            addToast("Shipping price updated");
            return;
          }
          if (res.type === "ERROR_UPDATING_CPS_SHIPPING_PRICE") {
            addToast("Error updating shipping price", true);
          }
        })
        .catch(error => {
          addToast("Error updating shipping price", true);
        });
      onCommitEnd && onCommitEnd();
    },
    [customerPurchaseState]
  );
};
