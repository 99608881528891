import { groupBy, values } from "lodash";

import { utcDateToLocalTime } from "./dateUtils";
import { dateWithoutTimeKey } from "./dateWithoutTimeKey";
import { SmartrrSortType } from "./paginatedQuery";
import { sortByDate } from "./sortBy";

export function groupAndSortByDate<T>(
  valuesWithDates: T[],
  accessDate: (valuesWithDate: T) => Date,
  sortOrder: SmartrrSortType = "ASC"
): [Date, T[]][] {
  const deliveriesGroupedByDate: [Date, T[]][] = values(
    groupBy(
      valuesWithDates,
      valueWithDate => dateWithoutTimeKey(accessDate(valueWithDate)) // want to be doubly sure seconds, minutes, etc. don't throw us off
    )
  ).map(groupedByDate => [
    utcDateToLocalTime(accessDate(groupedByDate[0])), // converting to local date here
    groupedByDate,
  ]);

  return sortByDate(deliveriesGroupedByDate, ([date]) => date, sortOrder);
}
