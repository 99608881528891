import { InteractiveTable } from "@vendor-app/app/_sharedComponents/InteractiveDataTable";
import { z } from "zod";

export namespace UpcomingOrdersTableFilters {
  export const types = [
    "numberOfFutureOrders",
    "transactionDate",
    "totalEstimatedNet",
    "items",
    "deliveryMethod",
    "address",
  ] as const;

  const schema = z.enum(types);
  export type Filter = z.infer<typeof schema>;

  interface FilterInformation {
    name: Filter;
    title: string;
  }

  export const filters: Record<Filter, FilterInformation> = {
    numberOfFutureOrders: {
      name: "numberOfFutureOrders",
      title: "Number of future orders",
    },
    address: {
      name: "address",
      title: "Shipping address",
    },
    deliveryMethod: {
      name: "deliveryMethod",
      title: "Delivery method",
    },
    items: {
      name: "items",
      title: "Items",
    },
    totalEstimatedNet: {
      name: "totalEstimatedNet",
      title: "Total",
    },
    transactionDate: {
      name: "transactionDate",
      title: "Transaction date",
    },
  };

  export type SelectedFilter = InteractiveTable.SelectedFilter & {
    filterType: Filter;
  };
}
